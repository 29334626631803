import ApiClient from "api/v2/ApiClient";
import dayjs from "dayjs";
import DataTable from "examples/Tables/DataTable";
import React, { useState } from "react";
import { useQuery } from "react-query";
import MDDatePicker from "components/ui/MDDatePicker";
import { MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import BaseCard from "components/Cards/BaseCard";
import { NumberFormatter } from "utils/helpers";

const tableColumns = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Credits purchased",
    accessor: "credits",
  },
  {
    Header: "Price exc VAT",
    accessor: "priceExVat",
  },
  {
    Header: "VAT",
    accessor: "vat",
  },

  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Invoice number",
    accessor: "invoiceNumber",
  },
];

export default function RefundTable({ company }) {
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: "APPROVED",
  });
  const [transactionRows, setTransactionRows] = React.useState([]);
  const { data: transactionsData, isLoading } = useQuery(
    ["transactions", company],
    {
      queryFn: () =>
        ApiClient.payments.getTransactions({
          paygId: company?.PAYG_ID,
        }),
      onSuccess: (data) => {
        if (data.status !== 200) return setTransactionRows([]);
        setTransactionRows(
          data.result?.map((transaction) => {
            return {
              date: dayjs(transaction.transactionTime).format("DD/MM/YYYY"),
              credits: NumberFormatter(transaction.credits),
              priceExVat: transaction.TotalWithoutVAT,
              total: transaction.TotalWithVAT,
              vat: transaction.TotalVAT,
              invoiceNumber: transaction.ref,
              processRefund: (
                <button className="text-[#00C2FF]">Process refund</button>
              ),
            };
          })
        );
      },
    }
  );

  useEffect(() => {
    if (!transactionsData || !transactionsData.length > 0 || isLoading) return;
    setTransactionRows(() => {
      return transactionsData
        ?.filter((row) => {
          if (!row.transactionStatus) return;
          if (filters.status === "APPROVED")
            return row.transactionStatus.toUpperCase() === "APPROVED";
          if (filters.status === "REJECTED")
            return row.transactionStatus.toUpperCase() === "REJECTED";
          return row;
        })
        .filter((row) => {
          if (!filters.startDate || !filters.endDate) return row;
          return (
            dayjs(row.transactionTime).isBefore(dayjs(filters.endDate)) &&
            dayjs(row.transactionTime).isAfter(dayjs(filters.startDate))
          );
        })
        .sort((a, z) => {
          return dayjs(a.transactionTime).isBefore(dayjs(z.transactionTime));
        })
        .map((transaction) => {
          return {
            date: dayjs(transaction.transactionTime).format("DD/MM/YYYY"),
            credits: transaction.items0Quantity,
            priceIncVat: transaction.priceIncVat,
            priceExVat: transaction.priceExVat,
            invoiceNumber: transaction.ref,
            processRefund: (
              <button className="text-[#00C2FF]">Process refund</button>
            ),
          };
        });
    });
  }, [filters]);

  return (
    <BaseCard title="Transactions">
      <div className="flex gap-2">
        <div className="ml-auto flex items-center justify-end gap-2 w-full">
          <Select
            className="py-3 text-right px-3 w-fit"
            value={filters.status}
            onChange={(e) =>
              setFilters((prev) => {
                return {
                  ...prev,
                  status: e.target.value,
                };
              })
            }
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"APPROVED"}>Approved</MenuItem>
            <MenuItem value={"REJECTED"}>Rejected</MenuItem>
          </Select>
        </div>
        <div className="w-fit self-end">
          <MDDatePicker
            sx={{ width: "100%" }}
            from={{
              label: [],
              input: { placeholder: "From Date" },
              options: {
                mode: "single",
                dateFormat: "Y-m-d",
                defaultDate: [dayjs(filters.startDate).format("YYYY-MM-DD")],
              },
              onChange: (date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    startDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              },
            }}
            to={{
              label: [],
              input: { placeholder: "To Date" },
              options: {
                mode: "single",
                dateFormat: "Y-m-d",
                defaultDate: [dayjs(filters.endDate).format("YYYY-MM-DD")],
              },
              onChange: (date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    endDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              },
            }}
          />
        </div>
      </div>
      <DataTable
        title=""
        entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20] }}
        table={{
          columns: tableColumns,
          rows: transactionRows ?? [],
        }}
      />
    </BaseCard>
  );
}
