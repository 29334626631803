import z from "zod";
import { isValidPassword } from "../Register/schema";

export const ResetPasswordConfirmationSchema = z
  .object({
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters long" }),
    confirmPassword: z
      .string()
      .min(8, { message: "Password must be at least 8 characters long" }),
  })
  .refine((data) => isValidPassword(data.password), {
    message:
      "Password must contain at least one uppercase letter and one special character",
    path: ["password"],
  })

  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });
