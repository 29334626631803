import ApiClient from "api/v2/ApiClient";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useQuery } from "react-query";
import { authState } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { SelectedCompanyAtomType } from "types/AtomTypes";

const useFetchSelectableUsers = () => {
  const { user } = useSelector(authState) as UserAuthSlice;
  const [selectableUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { data: companyDetails } = useQuery(["company-details"], {
    queryFn: () =>
      ApiClient.company.getCompanyDetails({
        paygId: user?.payId ?? "",
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result;
    },
  });

  const { data, isLoading: isCompaniesLoading } = useQuery(["companies"], {
    queryFn: () => ApiClient.taxi.getTaxiList(),
    select: (data) => {
      if (data.status !== 200) return [];
      return data?.result;
    },
    enabled: !!companyDetails && !selectableUser,
  });

  return {
    users: data,
    isLoading: isCompaniesLoading,
  };
};

export default useFetchSelectableUsers;
