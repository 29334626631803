import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import MDInput from "components/ui/MDInput";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { BadErrorResponse } from "types/NetworkTypes";
import Button from "components/Button/Button";

type Props = {
  open: boolean;
  handleClose: () => void;
};

type FormData = {
  password: string;
};

export default function DeleteUserWarningModal({ open, handleClose }: Props) {
  const qc = useQueryClient();
  const [company] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit, reset } = useForm<FormData>();
  const { mutate } = useMutation(
    (data: FormData) =>
      ApiClient.admin.deleteUser({
        adminId: user?.payId ?? "",
        password: data.password,
        paygId: company?.PAYG_ID ?? "",
      }),
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          toast.error("Error deleting");
          return;
        }
        toast.success("Successfully deleted");
        qc.invalidateQueries(["companies"], {
          exact: true,
        });
        handleClose();
        reset();
        window.location.reload();
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const formSubmit = async (data: FormData) => {
    mutate(data);
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={`You are about to delete this user`}
    >
      <form
        action=""
        onSubmit={handleSubmit(formSubmit)}
        className="flex flex-col gap-5"
      >
        <h2 className="text-lg text-center font-semibold text-secondary">
          To delete the user, you must first confirm your password.
        </h2>
        <MDInput
          // @ts-ignore
          type="password"
          className="w-full"
          label="Password"
          {...register("password")}
        />
        <div className="w-full flex items-center gap-5 flex-col lg:flex-row">
          <Button
            variant="primary"
            className="w-full"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="danger" className="w-full" type="submit">
            Delete
          </Button>
        </div>
      </form>
    </BaseModal>
  );
}
