import ApiClient from "api/v2/ApiClient";
import BaseCard from "components/Cards/BaseCard";
import { useQuery } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { UserAuthSlice } from "types/ReduxTypes";
import _ from "lodash";
import { Icon } from "@iconify/react";
import { useState } from "react";
import FurtherInfoModal from "./FurtherInfoModal";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { toast } from "react-toastify";

type Props = {
  userUsageLimit: number;
};

export default function DocumentationCard({ userUsageLimit }: Props) {
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const { user } = useSelector(authState) as UserAuthSlice;
  const { data } = useQuery(["product-documentation"], {
    queryFn: () =>
      ApiClient.stats.getProductApiDocs({
        paygId: user.payId || "",
      }),
    select: (data) => Object.entries(_.groupBy(data.result, (d) => d.Title)),
  });

  return (
    <BaseCard title="Documentation" className="flex flex-col relative">
      <button
        onClick={() => setInfoModalOpen(true)}
        className="absolute -top-14 right-5 text-primary flex items-center gap-1 text-sm italic "
      >
        Usage Policy
        <Icon
          icon="material-symbols:help"
          className="text-xl sm:text-3xl text-primary"
        />
      </button>

      <section className="flex flex-col gap-3">
        {data?.map(([key, value]) => {
          return (
            <Accordion
              key={key}
              style={{
                boxShadow: "none",
              }}
            >
              <AccordionSummary className="text-2xl font-semibold">
                {key}
              </AccordionSummary>
              {value.map((product, idx) => {
                return (
                  <div className="flex flex-col gap-3 p-4" key={product.ID}>
                    <p className="font-semibold text-xl">
                      <span className="text-success">GET</span>{" "}
                      {product.Description} ({product.GTP})
                    </p>
                    <p className="">{product.Explainer}</p>
                    <div className="flex items-center">
                      <pre className="bg-[#eee] text-sm p-2 h-auto whitespace-pre-wrap rounded-md w-full">
                        <code className="w-full">{product.Model}</code>
                      </pre>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(product.Model);
                          toast.success("Copied to clipboard");
                        }}
                      >
                        <Icon
                          icon="material-symbols:file-copy-outline"
                          width="32px"
                          className="mx-2"
                        />
                      </button>
                    </div>

                    <p className="font-bold">Credit Value: {product.Value}</p>
                    {idx !== value.length - 1 && <hr className="mt-2" />}
                  </div>
                );
              })}
            </Accordion>
          );
        })}
      </section>

      {infoModalOpen && (
        <FurtherInfoModal
          userUsageLimit={userUsageLimit}
          open={infoModalOpen}
          handleClose={() => setInfoModalOpen(false)}
        />
      )}
    </BaseCard>
  );
}
