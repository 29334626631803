import { Autocomplete, Grid, Skeleton, TextField } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import DropdownSelect from "components/DropdownSelect/DropdownSelect";
import { atom, useAtom } from "jotai";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { useQuery } from "react-query";
import ApiClient from "api/v2/ApiClient";
import DatePicker from "components/DatePicker/DatePicker";

export const selectedCompanyAtom = atom(null);

const FilterBar = forwardRef(({ from, to, gran, isLoading, data }, ref) => {
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { data: activeStatusData, isLoading: activeStatusLoading } = useQuery(
    ["active-status", company],
    {
      queryFn: () =>
        ApiClient.admin.getApiDetails({
          paygId: company.PAYG_ID,
        }),
      select: (data) => {
        if (data.status !== 200) return null;
        return data.result.Active;
      },
      enabled: !!company,
    }
  );
  const auth = useSelector(authState);

  const [_, setSearchParams] = useSearchParams();
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(to);
  const [granularity, setGranularity] = useState(gran);

  useEffect(() => {
    setSearchParams({
      from: dateFrom,
      to: dateTo,
      granularity: granularity,
    });
  }, [dateFrom, dateTo, granularity]);

  useEffect(() => {
    if (!dateFrom)
      setDateFrom(dayjs().subtract(1, "month").format("YYYY-MM-DD"));
    if (!dateTo) setDateTo(dayjs().add(1, "day").format("YYYY-MM-DD"));
    if (!granularity) setGranularity("monthly");
    if (dayjs(dateFrom).isAfter(dayjs(dateTo))) {
      setDateFrom(dayjs().subtract(1, "month").format("YYYY-MM-DD"));
      setDateTo(dayjs().add(1, "day").format("YYYY-MM-DD"));
    }
  }, []);

  if (isLoading || !dateFrom || !dateTo || !granularity || activeStatusLoading)
    return (
      <div className="flex gap-x-5" id="overview">
        <Skeleton
          variant="rectangular"
          className="rounded-lg"
          height={40}
          width={"100%"}
        />
        <Skeleton
          variant="rectangular"
          className="rounded-lg"
          height={40}
          width={"100%"}
        />
        <Skeleton
          variant="rectangular"
          className="rounded-lg"
          height={40}
          width={"100%"}
        />
        <Skeleton
          variant="rectangular"
          className="rounded-lg"
          height={40}
          width={"100%"}
        />
        <Skeleton
          variant="rectangular"
          className="rounded-lg"
          height={40}
          width={"100%"}
        />
      </div>
    );
  return (
    <Grid
      ref={ref}
      container
      wrap="wrap"
      columns={9}
      spacing={"1rem"}
      sx={{ background: "#F0F2F5", zIndex: 10 }}
    >
      {!!company && auth?.user?.type !== "User" && (
        <Grid item md={3} xs={12}>
          <span className="text-secondary font-light italic text-sm">
            Company
          </span>
          {!!company && (
            <Autocomplete
              disablePortal
              id="autocomplete-custom"
              value={company ? company["Label"] : data[0]["Label"]}
              options={
                data.length > 0 ? data?.map((option) => option["Label"]) : []
              }
              onChange={(event, newValue) => {
                if (newValue === null) {
                  setCompany(data[0]);
                  return;
                }
                setCompany(data?.find((x) => x["Label"] === newValue));
              }}
              renderInput={(params) => <TextField {...params} size="normal" />}
            />
          )}
        </Grid>
      )}

      <Grid
        item
        md={2}
        xs={12}
        style={{
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        <DropdownSelect
          label="Granularity"
          menuItems={["hourly", "daily", "weekly", "monthly"]}
          value={granularity}
          setValue={(val) => {
            setGranularity(val);
          }}
        />
      </Grid>
      <Grid
        item
        md={3}
        xs={12}
        style={{
          alignItems: "flex-end",
          display: "flex",
        }}
      >
        <div className="w-full flex gap-2">
          <DatePicker
            outsideLabel="From"
            selected={dayjs(dateFrom).toDate()}
            innerText={dateFrom}
            mode="single"
            onSelect={(date) => {
              setDateFrom(dayjs(date).format("YYYY-MM-DD"));
            }}
          ></DatePicker>
          <DatePicker
            outsideLabel="To"
            selected={dayjs(dateTo).toDate()}
            innerText={dateTo}
            disabled={{
              before: dayjs(dateFrom).toDate(),
            }}
            mode="single"
            onSelect={(date) => {
              setDateTo(dayjs(date).format("YYYY-MM-DD"));
            }}
          ></DatePicker>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        md={1}
        style={{
          alignItems: "flex-end",
          display: "flex",
          marginLeft: "auto",
        }}
      >
        {activeStatusData !== null && (
          <div
            className={`w-full flex p-3 items-center justify-center h-full text-white md:h-1/2  rounded-lg ${
              activeStatusData ? " bg-success" : "bg-error"
            }`}
          >
            {activeStatusData ? "Active" : "Inactive"}
          </div>
        )}
      </Grid>
    </Grid>
  );
});

export default FilterBar;
