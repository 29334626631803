import { Icon } from "@iconify/react/dist/iconify.js";
import { useFormContext } from "react-hook-form";

export default function SubscriptionTypeSection() {
  const { watch, setValue } = useFormContext();
  return (
    <section className="flex flex-col sm:flex-row gap-5">
      <div
        onClick={() => setValue("purchaseType", "SN")}
        className={`border-dashed flex gap-5 cursor-pointer h-auto transition-all flex-col justify-center items-center border w-full rounded-lg border-primary p-4
            ${watch("purchaseType") === "SN" && "border-4"}
          
          `}
      >
        <h2 className="font-bold text-2xl">Subscribe now</h2>
        <Icon icon="fluent:payment-16-filled" className="text-7xl" />
      </div>
      <div
        onClick={() => setValue("purchaseType", "SD")}
        className={`border-dashed flex gap-5 cursor-pointer  transition-all flex-col justify-center items-center border w-full rounded-lg border-primary p-4
            ${watch("purchaseType") === "SD" && "border-4"}
          
          `}
      >
        <h2 className="font-bold text-2xl text-center">
          Scheduled subscription
        </h2>
        <Icon icon="mingcute:time-line" className="text-7xl" />
      </div>
    </section>
  );
}
