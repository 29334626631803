import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import MDInput from "components/ui/MDInput";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import Button from "components/Button/Button";

export default function WarningModal({ open, handleClose, active }) {
  const qc = useQueryClient();
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { user } = useSelector(authState);
  const { register, getValues, handleSubmit, reset } = useForm();
  const { mutate } = useMutation(
    (data) =>
      ApiClient.admin.updateUserActiveStatus({
        password: data.password,
        paygId: company?.PAYG_ID,
        active: active ? 0 : 1,
        adminId: user?.payId,
        type: company?.Type,
      }),
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          return toast.error("Error updating operator");
        }
        toast.success("Operator updated successfully");
        qc.invalidateQueries(["active-status", company]);
        handleClose();
        reset();
      },
      onError: () => {
        toast.error("Incorrect password");
      },
    }
  );

  const formSubmit = async () => {
    mutate({
      password: getValues("password"),
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={`You are about to ${active ? "deactivate" : "activate"} this user`}
    >
      <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-5">
        <h2 className="text-lg text-center font-semibold text-secondary">
          To change the status of this user, you must first confirm your
          password.
        </h2>
        <MDInput
          type="password"
          className="w-full"
          label="Password"
          {...register("password")}
        />
        <div className="w-full flex items-center gap-5 flex-col lg:flex-row">
          <Button type="button" onClick={handleClose} className="w-full">
            Cancel
          </Button>
          <Button
            type="submit"
            className="w-full"
            variant={active ? "danger" : "success"}
          >
            {active ? "Deactivate" : "Activate"} user
          </Button>
        </div>
      </form>
    </BaseModal>
  );
}
