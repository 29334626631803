import { Card } from "@mui/material";
import dayjs from "dayjs";
import ApiClient from "api/v2/ApiClient";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import ManualPaymentSection from "pages/AdminFunctions/components/ManualPayments/ManualPaymentAccordion";
import InfoRow from "components/InfoRow/InfoRow";
import { formatCurrency } from "utils/currency";

export default function ContractInformation() {
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );

  const { data: contractData } = useQuery(["contract"], {
    queryFn: () =>
      ApiClient.account.getContractInformation({
        paygId: selectedUser?.PAYG_ID ?? "",
      }),
    enabled: !!selectedUser,
    select: (data) => {
      if (data.status !== 200) return null;

      const active = data.result?.chargeSummary?.summary.find(Boolean);

      return {
        info: data.result,
        summary: data.result.chargeSummary?.summary[0].summary,
        active: active ? active.active : [],
        vatRate:
          active && active?.active.length > 0 ? active.active[0].vatRate : 0,
      };
    },
  });

  if (!contractData || !contractData.info.chargeSummary) return null;

  return (
    <Card className="p-4">
      <h1 className="text-secondary font-semibold text-lg pb-2">
        Contract Information
      </h1>
      <div className="pb-5 text-primary">
        <InfoRow
          label="Status"
          value={contractData.info.chargeSummary.summary[0].scheduleStatus}
        />
        <InfoRow
          label="Contract Type"
          value={contractData?.info?.["contractType"]}
        />
        <InfoRow
          label="Chargeable Amount"
          value={formatCurrency(contractData.summary.chargeableAmount ?? 0)}
        />
        <InfoRow
          label="Total VAT"
          value={formatCurrency(contractData.summary.totalVAT ?? 0)}
        />
        <InfoRow
          label="VAT Rate"
          value={contractData.vatRate ? `${contractData.vatRate}%` : "N/A"}
        />
        <InfoRow
          label="Last Purchase"
          value={
            dayjs(contractData?.info?.["lastTransaction"]).isValid()
              ? dayjs(contractData?.info?.["lastTransaction"]).format(
                  "DD/MM/YYYY"
                )
              : "No purchases made"
          }
        />
        <InfoRow
          label="Upcoming Payments"
          value={
            dayjs(contractData?.info?.["futurePayments"]).isValid()
              ? dayjs(contractData?.info?.["futurePayments"]).format(
                  "DD/MM/YYYY"
                )
              : "No upcoming payments"
          }
        />
      </div>
      <hr className="my-2" />
      {contractData && contractData?.active.length > 0 ? (
        <ManualPaymentSection title="Active" data={contractData?.active} />
      ) : null}
    </Card>
  );
}
