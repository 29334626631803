import { Skeleton } from "@mui/material";

export default function AccountPageShimmer() {
  return (
    <div className="flex flex-col gap-y-2">
      <Skeleton
        height={450}
        className="rounded-lg"
        width={"100%"}
        variant="rectangular"
      />
      <Skeleton height={250} />
      <Skeleton height={200} />
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2">
        <Skeleton height={250} />
        <Skeleton height={250} />
        <Skeleton height={250} />
      </div>
    </div>
  );
}
