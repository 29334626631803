import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import React from "react";

export default function Footer() {
  return (
    <MDBox mt={3} mb={1} textAlign="center">
      <MDTypography variant="button" color="text">
        <MDTypography
          variant="button"
          color="info"
          to=""
          fontWeight="medium"
          textGradient
        >
          <a href="mailto:support@posttag.com" className="underline">
            Help?
          </a>
        </MDTypography>
        <br />
        Contact support@posttag.com
        <br />
        <a href="http://posttag.com/privacy-policy/" className="underline">
          Privacy policy
        </a>
      </MDTypography>
    </MDBox>
  );
}
