import BaseModal from "components/Modals/BaseModal/BaseModal";
import React from "react";

type Props = {
  open: boolean;
  handleClose: () => void;
  userUsageLimit: number;
};

export default function FurtherInfoModal({
  open,
  handleClose,
  userUsageLimit,
}: Props) {
  return (
    <BaseModal
      title={"Further Information"}
      handleClose={handleClose}
      open={open}
    >
      <div className="flex flex-col gap-5">
        <InfoSection title="API Keys">
          <ul className="list-disc pl-2">
            <li>
              Upon registration, you will receive a unique API key. Keep it
              confidential and never expose it publicly, as it grants access to
              your account and usage limits.
            </li>
            <li>
              Avoid hardcoding API keys directly into your applications. Use
              secure methods to manage and store your keys.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Usage Limits">
          <ul className="list-disc pl-2">
            <li>
              Respect the rate limits specified in our API documentation.
              Exceeding{" "}
              {userUsageLimit ? (
                <span className="font-bold">
                  your account's limit of {userUsageLimit} requests per minute
                </span>
              ) : (
                "these limits"
              )}{" "}
              may result in temporary or permanent suspension of your access.
            </li>
            <li>
              If you require higher usage limits, reach out to our support team
              for assistance at{" "}
              <span className="font-bold">support@posttag.com</span>.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Data Privacy and Security">
          <ul className="list-disc pl-2">
            <li>
              Protect sensitive data obtained through our API. Ensure compliance
              with data protection regulations such as GDPR, CCPA, etc.
            </li>
            <li>
              Use encryption (e.g., HTTPS) when transmitting data to and from
              our API endpoints to maintain security.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Reporting Issues">
          <ul className="list-disc pl-2">
            <li>
              Promptly report any API-related issues, bugs, or vulnerabilities
              you encounter to our support team.
            </li>
            <li>
              Do not attempt to exploit any vulnerabilities you discover.
              Instead, help us improve the service by reporting them
              responsibly.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Documentation">
          <ul className="list-disc pl-2">
            <li>
              Familiarize yourself with our API documentation before integrating
              it into your applications.
            </li>
            <li>
              If you have any questions or need clarification on API
              functionalities, refer to our documentation or reach out to our
              support team.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Feedback and Suggestions">
          <ul className="list-disc pl-2">
            <li>
              We value your feedback and suggestions for improving our API
              service. Feel free to share your ideas with us through our
              designated channels.
            </li>
          </ul>
        </InfoSection>
        <InfoSection title="Changes to Policy">
          <ul className="list-disc pl-2">
            <li>
              PostTag reserves the right to update this API Usage Policy at any
              time. We will notify you of any significant changes via email or
              through our website.
            </li>
          </ul>
        </InfoSection>
      </div>
    </BaseModal>
  );
}

const InfoSection = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="font-bold text-xl">{title}</h2>
      {children}
    </div>
  );
};
