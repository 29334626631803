import { Document, Page, View, Text, PDFViewer } from "@react-pdf/renderer";
import useStyles from "./useStyles";
import InvoiceTitle from "./InvoiceHeader";
import InvoiceBillingInfo from "./InvoiceBillingInfo";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTableBody from "./InvoiceTableBody";
import InvoiceTableTotal from "./InvoiceTableTotal";
import { Invoice } from "api/v2/Account/types";
import dayjs from "dayjs";
import { formatCurrency } from "utils/currency";

type Props = {
  invoice: Invoice;
};

export default function InvoicePDF({ invoice }: Props) {
  const { styles } = useStyles();
  return (
    <PDFViewer className="w-full min-h-screen">
      <Document>
        <Page size={"A4"} style={styles.page}>
          <InvoiceTitle
            styles={styles}
            posttagDetails={{
              companyName: invoice.posttagDetails.companyName,
              address1: invoice.posttagDetails.address1,
              address2: invoice.posttagDetails.address2,
              address3: invoice.posttagDetails.address3,
              town: invoice.posttagDetails.addressTown,
              county: invoice.posttagDetails.addressCounty,
              postcode: invoice.posttagDetails.addressPostcode,
              country: invoice.posttagDetails.addressCountry,
              vatReg: invoice.posttagDetails.vatReg,
              logo: invoice.posttagDetails.companyLogo,
            }}
            invoice={{
              number: invoice.transactionDetails.transactionReference,
              date: dayjs().format("DD/MM/YYYY"),
              amount: formatCurrency(
                invoice.transactionDetails.transactionValue
              ),
              customerId: invoice.invoiceDetails.PAYG_ID,
              paid: invoice.transactionDetails.transactionStatus === "Approved",
              email: invoice.invoiceDetails.invoiceEmail,
            }}
          />
          <Divider />
          <InvoiceBillingInfo
            user={{
              address1: invoice.invoiceDetails.address1,
              address2: invoice.invoiceDetails.address2,
              address3: invoice.invoiceDetails.address3,
              companyName: invoice.invoiceDetails?.CompanyName ?? "",
              postcode: invoice.invoiceDetails.addressPostcode,
              town: invoice.invoiceDetails.addressTown,
              vatReg: invoice.invoiceDetails.vatReg,
            }}
            billingInfo={{
              id: invoice.transactionDetails.transactionReference,
              card: {
                expiryMonth: invoice.invoiceDetails.cardExpiryMonth,
                expiryYear: invoice.invoiceDetails.cardExpiryYear,
                lastFourDigits: invoice.invoiceDetails.cardLastFour,
              },
              type: invoice.itemsInfo.paymentType,
              billingPeriod: dayjs(
                invoice.transactionDetails.transactionTime
              ).format("DD/MM/YYYY"),
              nextBillingDate: dayjs(
                invoice.transactionDetails.nextTransactionTime
              ).isValid()
                ? dayjs(invoice.transactionDetails.nextTransactionTime).format(
                    "DD/MM/YYYY"
                  )
                : null,
            }}
            styles={styles}
          />
          <Divider />
          <InvoiceTableHeader styles={styles} />
          <InvoiceTableBody
            items={invoice.itemsInfo.itemsBreakdown.map((item) => {
              return {
                description: item.itemName,
                total: formatCurrency(item.withVAT),
                priceWithoutVAT: formatCurrency(item.withoutVAT),
                quantity: item.credits,
                VAT: formatCurrency(item.vat),
              };
            })}
            styles={styles}
          />
          <InvoiceTableTotal
            summary={{
              total: formatCurrency(
                invoice.transactionDetails.transactionValue
              ),
              totalExclVat: formatCurrency(
                parseInt(invoice.transactionDetails.transactionValue) * 0.8
              ),
              vat: formatCurrency(
                parseInt(invoice.transactionDetails.transactionValue) * 0.2
              ),
            }}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}

const Divider = () => {
  return (
    <View
      style={{ border: "0.25px solid black", marginTop: 15, marginBottom: 15 }}
    />
  );
};
