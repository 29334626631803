import { Card, TextField } from "@mui/material";
import AuthLayout from "layouts/AuthLayout";
import Header from "pages/Auth/components/Header";
//@ts-ignore
import bgImage from "assets/images/doors.png";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { useState } from "react";
import { useParams } from "react-router-dom";
import InvoicePDF from "components/PDF/Invoice/InvoicePDF";
import { useMutation } from "react-query";
import ApiClient from "api/v2/ApiClient";
import Loading from "pages/Loading/Loading";
import { Invoice as InvoiceType } from "api/v2/Account/types";
import { BadErrorResponse } from "types/NetworkTypes";
import { toast } from "react-toastify";

type FormData = {
  password: string;
};

export default function Invoice() {
  const params = useParams();
  const [invoiceData, setInvoiceData] = useState<InvoiceType | null>(null);
  const [verificationConfirmed, setVerificationConfirmed] = useState(false);
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      password: "",
    },
  });

  const { mutate, isLoading } = useMutation(
    (data: FormData) =>
      ApiClient.account.getInvoice({
        ref: params?.invoiceRef ?? "",
        password: data.password,
      }),
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          toast.error("An error occurred");
          return;
        }
        setInvoiceData(data.result);
        setVerificationConfirmed(true);
      },
      onError: (error: BadErrorResponse) => {
        if (error.response.data.status === 401) {
          toast.error("Invalid password");
          return;
        }
        toast.error("An error occurred");
      },
    }
  );

  const onFormSubmit = (data: FormData) => {
    mutate(data);
  };

  if (!verificationConfirmed) {
    return (
      <AuthLayout image={bgImage}>
        <Card className="w-full max-w-[500px] flex flex-col z-10">
          <Header title={"Invoice Verification"} backNavigation={null} />
          <form
            className="w-full grid grid-cols-1 p-8 gap-5"
            onSubmit={handleSubmit(onFormSubmit)}
          >
            <TextField
              type="password"
              required
              label="Password"
              {...register("password")}
            />
            <Button type="submit">Submit</Button>
          </form>
        </Card>
      </AuthLayout>
    );
  }

  if (isLoading || !invoiceData) return <Loading />;

  return <InvoicePDF invoice={invoiceData} />;
}
