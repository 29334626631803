import React from "react";
import CurrencyInput, { CurrencyInputProps } from "react-currency-input-field";

type Props = {
  label?: string;
} & CurrencyInputProps;

const CurrencyField = ({ label, ...rest }: Props) => {
  return (
    <div className="relative w-full h-auto">
      <CurrencyInput
        className="border-gray-300 px-3 py-2 text-sm w-full h-full rounded-md border"
        {...rest}
      />
      {label && (
        <span className="text-xs text-gray-600 -top-2 absolute px-2 left-1 bg-white">
          {label}
        </span>
      )}
    </div>
  );
};

export default CurrencyField;
