import { Icon } from "@iconify/react";
import MDTypography from "components/ui/MDTypography";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";

export default function AccountView({ onNext }) {
  const { setValue, watch } = useFormContext();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setValue("type", "");
  }, []);

  useEffect(() => {
    if (searchParams.get("referral")) {
      setValue("referral", searchParams.get("referral"));
      setValue("type", "User");
      onNext();
    }
  }, []);

  return (
    <div className="flex flex-col gap-5 justify-center w-full">
      <MDTypography variant="h4" fontWeight="medium" className="text-center">
        Select account type
      </MDTypography>
      <div className="flex flex-col gap-3">
        <p className="text-sm">
          <span className="font-bold">Credit User:</span> Individuals or
          entities using services solely for their own or their organization's
          benefit.
        </p>
        <p className="text-sm">
          <span className="font-bold">Plugin User:</span> Individuals or
          entities using PostTag's address search plugin.
        </p>
      </div>
      <div className="flex items-center flex-col sm:flex-row gap-5">
        <div
          onClick={() => setValue("type", "CreditUser")}
          className={`border-dashed flex gap-5 cursor-pointer  transition-all flex-col justify-center items-center border w-full rounded-lg border-primary p-4
            ${watch("type") === "CreditUser" && "border-4"}
          
          `}
        >
          <h2 className="font-bold text-2xl">Credit User</h2>
          <Icon icon="bxs:user" className="text-7xl" />
        </div>
        <div
          onClick={() => {
            setValue("type", "PluginUser");
          }}
          className={`border-dashed flex gap-5 cursor-pointer  transition-all flex-col justify-center items-center border w-full rounded-lg border-primary p-4
          ${watch("type") === "PluginUser" && "border-4"}
      
        `}
        >
          <h2 className="font-bold text-2xl">Plugin User</h2>
          <Icon icon="fa-solid:users" className="text-7xl" />
        </div>
      </div>

      <div className="w-full flex justify-center">
        <button
          disabled={!watch("type")}
          className={`bg-primary text-white w-full px-12 py-2 rounded-lg
             ${watch("type") ? "bg-primary text-white" : "bg-gray-300"}
          `}
          onClick={() => onNext()}
        >
          Next
        </button>
      </div>
    </div>
  );
}
