import { ComponentPropsWithoutRef, useState } from "react";
import BasePriceCard from "./BasePriceCard";
import SubscriptionPaymentModal from "./SubscriptionPaymentModal/SubscriptionPaymentModal";
import { NumberFormatter } from "utils/helpers";

type PaygCardsProps = ComponentPropsWithoutRef<"div"> & {
  data: {
    Volume1: number;
    Price1: string;
    Volume2: number;
    Price2: string;
    Volume3: number;
    Price3: string;
    pricePerCredit: string;
  };
  setUpdateCreditsModal: ({
    quantity,
    price,
    type,
    ppc,
  }: {
    quantity: number;
    price: string;
    type: "One" | "Two" | "Three";
    ppc: string;
  }) => void;
};

export default function SubscriptionCards({
  setUpdateCreditsModal,
  data,
}: PaygCardsProps) {
  const [openPaymentModal, setOpenPaymentModal] = useState<{
    name: string;
    price: string;
    quantity: number;
    pricePerCredit: string;
  } | null>(null);
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8 lg:grid-cols-3 ">
      <BasePriceCard
        purchaseLabel="Purchase subscription"
        onEdit={() => {
          setUpdateCreditsModal({
            quantity: data?.["Volume1"],
            price: data?.["Price1"],
            type: "One",
            ppc: data?.pricePerCredit,
          });
        }}
        price={data?.["Price1"]}
        onCreditsSelect={() =>
          setOpenPaymentModal({
            name: `Price-${data?.["Price1"]}-Quantity-${data?.["Volume1"]}`,
            price: data?.["Price1"],
            quantity: data?.["Volume1"],
            pricePerCredit: data?.pricePerCredit,
          })
        }
        benefits={[`${NumberFormatter(data["Volume1"])} credits`]}
      />
      <BasePriceCard
        purchaseLabel="Purchase subscription"
        onEdit={() => {
          setUpdateCreditsModal({
            quantity: data?.["Volume2"],
            price: data?.["Price2"],
            type: "Two",
            ppc: data?.pricePerCredit,
          });
        }}
        price={data?.["Price2"]}
        benefits={[`${NumberFormatter(data["Volume2"])} credits`]}
        onCreditsSelect={() =>
          setOpenPaymentModal({
            name: `Price-${data?.["Price2"]}-Quantity-${data?.["Volume2"]}`,
            price: data?.["Price2"],
            quantity: data?.["Volume2"],
            pricePerCredit: data?.pricePerCredit,
          })
        }
      />
      <BasePriceCard
        purchaseLabel="Purchase subscription"
        onEdit={() =>
          setUpdateCreditsModal({
            quantity: data?.["Volume3"],
            price: data?.["Price3"],
            type: "Three",
            ppc: data?.pricePerCredit,
          })
        }
        price={data?.["Price3"]}
        benefits={[`${NumberFormatter(data["Volume3"])} credits`]}
        onCreditsSelect={() =>
          setOpenPaymentModal({
            name: `Price-${data?.["Price3"]}-Quantity-${data?.["Volume3"]}`,
            price: data?.["Price3"],
            quantity: data?.["Volume3"],
            pricePerCredit: data?.pricePerCredit,
          })
        }
      />
      <div className="lg:col-start-2 h-full">
        <BasePriceCard
          purchaseLabel="Purchase credits"
          price={null}
          benefits={["Custom Subscription?"]}
        >
          <p>
            Get in contact with us at{" "}
            <a href="mailto:support@posttag.com" className="font-bold">
              support@posttag.com
            </a>
          </p>
        </BasePriceCard>
      </div>
      {!!openPaymentModal && (
        <SubscriptionPaymentModal
          item={openPaymentModal}
          open={!!openPaymentModal}
          handleClose={() => setOpenPaymentModal(null)}
        />
      )}
    </div>
  );
}
