import Calendar from "components/Calendar/Calendar";
import useClickOutside from "hooks/useClickOutside";
import { forwardRef, useRef, useState } from "react";
import { DayPickerSingleProps } from "react-day-picker";

type DatePickerProps = DayPickerSingleProps & {
  innerText?: string;
  outsideLabel?: string;
};

const DatePicker = forwardRef<HTMLButtonElement, DatePickerProps>(
  ({ innerText, outsideLabel, ...rest }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useClickOutside(containerRef, () => {
      setIsOpen(false);
    });

    return (
      <div className="flex flex-col w-full relative" ref={containerRef}>
        {outsideLabel && (
          <span className="text-secondary italic text-sm font-light">
            {outsideLabel}
          </span>
        )}
        <button
          onClick={() => setIsOpen(!isOpen)}
          ref={ref}
          className=" flex border-opacity-40 items-center justify-start w-full border py-[11.5px] px-5 text-sm rounded-md border-secondary"
        >
          {innerText}
        </button>
        {isOpen && (
          <Calendar
            className="absolute z-10 bg-white top-14 shadow-xl p-4 -left-5"
            {...rest}
          />
        )}
      </div>
    );
  }
);

export default DatePicker;
