import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import Button from "components/Button/Button";
import { VerifyPaymentMethodRequest } from "api/v2/Payments/types";
import { TextField } from "@mui/material";
import { authState, dismissNotice } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector, useDispatch } from "react-redux";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";

type PaymentModalProps = {
  open: boolean;
  handleClose: () => void;
};

export default function AddPaymentDetailsModal({
  open,
  handleClose,
}: PaymentModalProps) {
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user } = useSelector(authState) as UserAuthSlice;
  const { data: scheduleRef } = useQuery({
    queryKey: ["manual-payments"],
    queryFn: () =>
      ApiClient.admin.listManualPayments({
        paygId: user?.payId ?? "",
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result.scheduleRef;
    },
  });

  const { mutate } = useMutation(
    (data: VerifyPaymentMethodRequest) =>
      ApiClient.payments.verifyPaymentMethod(data),
    {
      onSuccess: (data) => {
        window.open(data.result.token.redemption_url, "_blank") ||
          window.location.assign(data.result.token.redemption_url);
      },
    }
  );

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    defaultValues: {
      title: "",
      first_name: "",
      surname: "",
      company: "",
      mobile_number: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      postcode: "",
      property_name: "",
      town: "",
    },
  });

  const onFormSubmit = async (data: any) => {
    mutate({
      address: {
        address1: data.address_line_1,
        address2: data.address_line_2,
        postCode: data.postcode,
        propertyName: data.property_name,
        town: data.town,
      },
      callbackUrl: `${window.location.origin}/overview`,
      companyName: data.company,
      person: {
        email: data.email,
        firstName: data.first_name,
        mobileNumber: data.mobile_number,
        surname: data.surname,
        title: data.title,
      },
      scheduleRef: scheduleRef ?? "",
    });
  };

  const fetchContactInfo = async () => {
    await ApiClient.company
      .getCompanyDetails({
        paygId: selectedUser?.PAYG_ID ?? "",
      })
      .then((_res) => {
        const res = _res.result;
        setValue("title", res["personTitle"]);
        setValue("first_name", res["personFirstName"]);
        setValue("surname", res["personSurname"]);
        setValue("company", res["CompanyName"]);
        setValue("email", res["personEmail"]);
        setValue("address_line_1", res["address1"]);
        setValue("mobile_number", res["personMobileNumber"]);
        setValue("address_line_2", res["address2"]);
        setValue("postcode", res["addressPostcode"]);
        setValue("property_name", res["addressPropertyName"]);
        setValue("town", res["addressTown"]);
      });
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <BaseModal open={open} handleClose={handleClose} title="Add payment method">
      <div className="w-full flex flex-col gap-y-8">
        <Button
          className="w-fit mx-auto"
          onClick={() => fetchContactInfo()}
          type="button"
        >
          Use contact information
        </Button>
        <form
          action=""
          className="flex flex-col p-3 gap-3"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className="grid grid-cols-1 gap-y-3 gap-x-3 mb-5 sm:grid-cols-2">
            <TextField
              {...register("title")}
              value={watch("title")}
              required
              label="Title"
            />
            <TextField
              {...register("first_name")}
              value={watch("first_name")}
              label="First name"
              required
            />
            <TextField
              {...register("surname")}
              value={watch("surname")}
              required
              label="Surname"
            />
            <TextField
              {...register("company")}
              value={watch("company")}
              required
              label="Company"
            />
            <TextField
              {...register("mobile_number")}
              required
              label="Mobile Number"
              value={watch("mobile_number")}
            />
            <TextField
              {...register("email")}
              value={watch("email")}
              required
              label="Email Address"
            />
            <TextField
              {...register("property_name")}
              className="sm:col-span-2"
              label="Property Name"
              value={watch("property_name")}
            />
            <TextField
              className="sm:col-span-2"
              {...register("address_line_1")}
              required
              label="Address Line 1"
              value={watch("address_line_1")}
            />
            <TextField
              className="sm:col-span-2"
              {...register("address_line_2")}
              value={watch("address_line_2")}
              label="Address Line 2"
            />
            <TextField
              {...register("town")}
              value={watch("town")}
              required
              label="Town"
            />
            <TextField
              {...register("postcode")}
              value={watch("postcode")}
              required
              label="Post Code"
            />
          </div>

          <Button type="submit" className="self-end ml-auto">
            Continue verification
          </Button>
        </form>
      </div>
    </BaseModal>
  );
}
