import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { authState } from "store/authSlice";

export default function RoleProtectedRoute({ allowedRoles }) {
  const { user } = useSelector(authState);
  if (user && allowedRoles.includes(user?.type)) {
    return <Outlet />;
  }
  return <Navigate to="404" />;
}
