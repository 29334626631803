import ApiClient from "api/v2/ApiClient";
import BaseCard from "components/Cards/BaseCard";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useState } from "react";
import { useQuery } from "react-query";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import CreateManualPaymentModal from "./CreateManualPaymentModal";
import Button from "components/Button/Button";
import ManualPaymentAccordion from "./ManualPaymentAccordion";
import { ManualPaymentCharge } from "api/v2/Admin/types";
import EditChargeModal from "./EditChargeModal";
import DeleteChargeModal from "./DeleteChargeModal";
import ConfirmPendingChargesModal from "./ConfirmPendingChargesModal";
import InfoRow from "components/InfoRow/InfoRow";
import dayjs from "dayjs";
import { formatCurrency } from "utils/currency";
import EditManualPaymentModal from "./EditManualPaymentModal";
import DeleteManualPaymentModal from "./DeleteManualPaymentModal";
import CreateManualPaymentCard from "./CreateManualPaymentCard";

const ManualPaymentsCard = () => {
  const [createManualPaymentModalOpen, setCreateManualPaymentModalOpen] =
    useState<boolean>(false);
  const [deleteManualPaymentModalOpen, setDeleteManualPaymentModalOpen] =
    useState<ManualPaymentCharge | null>(null);
  const [
    editManualPaymentChargeModalOpen,
    setEditManualPaymentChargeModalOpen,
  ] = useState<ManualPaymentCharge | null>(null);
  const [confirmPendingChargesModalOpen, setConfirmPendingChargesModalOpen] =
    useState<boolean>(false);
  const [editManualPaymentModal, setEditManualPaymentModal] =
    useState<boolean>(false);
  const [deleteManualPaymentModal, setDeleteManualPaymentModal] =
    useState<boolean>(false);

  const { user } = useSelector(authState) as UserAuthSlice;
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { data: manualPaymentsData } = useQuery({
    queryKey: ["manual-payments", selectedUser],
    queryFn: () =>
      ApiClient.admin.listManualPayments({
        paygId: selectedUser?.PAYG_ID,
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      if (data.message.includes("No Manual Payments found")) return null;
      return data.result;
    },
  });

  const { data: chargeSummaryData } = useQuery({
    queryKey: ["charges", selectedUser, manualPaymentsData?.scheduleRef],
    queryFn: ({ queryKey }) =>
      ApiClient.admin.listChargeSummary({
        adminId: user.payId,
        paygId: selectedUser?.PAYG_ID,
        scheduleRef: manualPaymentsData?.scheduleRef ?? "",
      }),
    select: (data) => {
      if (data.status !== 200) {
        return {
          active: [],
          inactive: [],
          pending: [],
        };
      }

      const active: (ManualPaymentCharge & { isReady: boolean })[] = [];
      const pending: (ManualPaymentCharge & { isReady: boolean })[] = [];

      data.result.summary
        .find(Boolean)
        ?.active.forEach((charge: ManualPaymentCharge) => {
          return charge.confirmedRef
            ? active.push({
                ...charge,
                isReady: true,
              })
            : pending.push({
                ...charge,
                isReady: false,
              });
        });

      const res = data.result.summary.find(Boolean);
      return {
        paythru: data.result.paythruDetails.result,
        summary: res,
        active: active,
        pending: pending,
        inactive: res?.inactive,
      };
    },
    enabled: !!manualPaymentsData?.scheduleRef,
    useErrorBoundary: true,
  });

  if (!chargeSummaryData || !manualPaymentsData)
    return <CreateManualPaymentCard />;

  return (
    <BaseCard title="Manual Payments Schedule" className="flex gap-10 flex-col">
      <div className="flex flex-col gap-5 relative">
        <h2 className="text-xl text-secondary font-semibold">{"Summary"}</h2>
        <section className="flex items-center gap-5 flex-col sm:flex-row">
          <Button onClick={() => setEditManualPaymentModal(true)}>
            Edit manual payment
          </Button>
          <Button
            variant="danger"
            onClick={() => setDeleteManualPaymentModal(true)}
          >
            Delete manual payment schedule
          </Button>
        </section>
        <div className="flex flex-col gap-2">
          <InfoRow
            label="Status"
            value={chargeSummaryData.summary?.scheduleStatus}
          />
          <InfoRow
            label="Start Date"
            value={dayjs(chargeSummaryData?.paythru?.startDate).format(
              "DD/MM/YYYY"
            )}
          />
          <InfoRow
            label="Total without VAT"
            value={formatCurrency(
              chargeSummaryData.summary?.summary.totalAmountWithoutVAT ?? 0
            )}
          />
          <InfoRow
            label="Total Discount"
            value={formatCurrency(
              chargeSummaryData.summary?.summary.totalDiscount ?? 0
            )}
          />
          <InfoRow
            label="Total VAT"
            value={formatCurrency(
              chargeSummaryData.summary?.summary.totalVAT ?? 0
            )}
          />

          <InfoRow
            label="Payment Total"
            value={formatCurrency(
              chargeSummaryData.summary?.summary.chargeableAmount ?? 0
            )}
          />
          <InfoRow
            label="Manual Payments"
            value={
              <div className="flex flex-col">
                {chargeSummaryData.paythru?.manualPayments.map(
                  (payment, idx) => {
                    return (
                      <p key={idx} className="flex gap-2 font-semibold">
                        {formatCurrency(payment.amount)} -{" "}
                        {dayjs(payment.date).format("DD/MM/YYYY")}
                      </p>
                    );
                  }
                )}
              </div>
            }
          />
          <InfoRow
            label="Payment Exceptions"
            value={
              <div className="flex flex-col">
                {chargeSummaryData.paythru?.paymentExceptions.map(
                  (payment, idx) => {
                    return (
                      <p key={idx} className="flex gap-2 font-semibold">
                        {dayjs(payment).format("DD/MM/YYYY")}
                      </p>
                    );
                  }
                )}
              </div>
            }
          />
          <InfoRow
            label="Next Payment"
            value={dayjs
              .unix(chargeSummaryData?.paythru?.nextRunAt ?? dayjs().unix())
              .format("DD/MM/YYYY")}
          />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <hr />
        <h2 className="text-xl text-secondary font-semibold">
          {"Payment charges"}
        </h2>
        <section className="flex items-center gap-5 flex-col sm:flex-row">
          <Button onClick={() => setCreateManualPaymentModalOpen(true)}>
            Create payment charge
          </Button>
          <Button
            disabled={chargeSummaryData.pending.length === 0}
            onClick={() => setConfirmPendingChargesModalOpen(true)}
          >
            Confirm pending charges
          </Button>
        </section>
        <ManualPaymentAccordion
          onEdit={(e) => setEditManualPaymentChargeModalOpen(e)}
          onDelete={(e) => setDeleteManualPaymentModalOpen(e)}
          title="Active"
          data={chargeSummaryData.active}
        />
        <ManualPaymentAccordion
          onEdit={(e) => setEditManualPaymentChargeModalOpen(e)}
          onDelete={(e) => setDeleteManualPaymentModalOpen(e)}
          title="Pending"
          data={chargeSummaryData.pending}
        />
        <ManualPaymentAccordion
          onEdit={(e) => setEditManualPaymentChargeModalOpen(e)}
          onDelete={(e) => setDeleteManualPaymentModalOpen(e)}
          title="Inactive"
          data={chargeSummaryData.inactive ?? []}
        />
      </div>

      {selectedUser && manualPaymentsData && (
        <CreateManualPaymentModal
          open={createManualPaymentModalOpen}
          handleClose={() => setCreateManualPaymentModalOpen(false)}
          data={{
            idValue: selectedUser?.PAYG_ID,
            scheduleRef: manualPaymentsData?.scheduleRef ?? "",
          }}
        />
      )}

      {editManualPaymentChargeModalOpen && manualPaymentsData && (
        <EditChargeModal
          data={{
            idValue: editManualPaymentChargeModalOpen.paymentId,
            scheduleRef: manualPaymentsData.scheduleRef ?? "",
            manualPaymentCharge: editManualPaymentChargeModalOpen,
          }}
          open={!!editManualPaymentChargeModalOpen}
          handleClose={() => setEditManualPaymentChargeModalOpen(null)}
        />
      )}

      {deleteManualPaymentModalOpen && manualPaymentsData && (
        <DeleteChargeModal
          data={{
            idValue: deleteManualPaymentModalOpen.paymentId,
            scheduleRef: manualPaymentsData.scheduleRef ?? "",
            manualPaymentCharge: deleteManualPaymentModalOpen,
          }}
          open={!!deleteManualPaymentModalOpen}
          handleClose={() => setDeleteManualPaymentModalOpen(null)}
        />
      )}

      {editManualPaymentModal &&
        chargeSummaryData.paythru &&
        chargeSummaryData.summary && (
          <EditManualPaymentModal
            data={{
              paythruDetails: chargeSummaryData.paythru,
              summaryDetails: chargeSummaryData.summary,
            }}
            open={!!editManualPaymentModal}
            handleClose={() => {
              setEditManualPaymentModal(false);
            }}
          />
        )}

      <DeleteManualPaymentModal
        data={{
          idValue: selectedUser?.PAYG_ID ?? "",
          scheduleRef: manualPaymentsData?.scheduleRef ?? "",
        }}
        open={deleteManualPaymentModal}
        handleClose={() => setDeleteManualPaymentModal(false)}
      />

      <ConfirmPendingChargesModal
        open={confirmPendingChargesModalOpen}
        data={{
          scheduleRef: manualPaymentsData?.scheduleRef ?? "",
        }}
        handleClose={() => setConfirmPendingChargesModalOpen(false)}
      />
    </BaseCard>
  );
};

export default ManualPaymentsCard;
