import { ComponentPropsWithRef } from "react";
import Action from "./Action";

type Variant = {
  variant?: keyof typeof variants;
};

const variants = {
  primary: {
    classes: "rounded-md bg-primary text-white w-full py-3 px-16",
    loaderColor: "white",
  },
  secondary: {
    classes: "rounded-md bg-mp-lime-green text-primary  w-full  py-3 px-16",
    loaderColor: "primary",
  },
  success: {
    classes: "rounded-md bg-success text-white  w-full  py-3 px-16",
    loaderColor: "primary",
  },
  warning: {
    classes: "rounded-md bg-orange-500 text-white w-full py-3 px-16",
    loaderColor: "primary",
  },
  danger: {
    classes: "rounded-md bg-red-500 text-white  w-full  py-3 px-16",
    loaderColor: "white",
  },
  submit: {
    classes: "rounded-md bg-primary text-white  w-full  py-3 px-16",
    loaderColor: "white",
  },
  transparent: {
    classes:
      "rounded-md border text-primary border-primary  w-full  py-3 px-16",
    loaderColor: "primary",
  },
  none: {
    classes: "",
    loaderColor: "white",
  },
} as const;

type Props = {
  isLoading?: boolean;
  disabled?: boolean;
};

export default function Button({
  children,
  variant = "primary",
  className,
  disabled,
  isLoading,
  ...props
}: ComponentPropsWithRef<typeof Action> & Variant & Props) {
  const { classes, loaderColor } = variants[variant];
  return (
    <Action
      {...props}
      // eslint-disable-next-line
      // @ts-ignore
      disabled={isLoading || disabled}
      className={`group relative select-none focus:outline-none disabled:pointer-events-none disabled:opacity-30 ${
        className ?? ""
      }`}
    >
      <span
        className={`relative flex items-center w-full justify-center gap-2 py-3 px-6 text-center ${
          classes ?? ""
        } ${isLoading && "w-[150px]"}`}
      >
        {isLoading ? <div className=""></div> : children}
      </span>
    </Action>
  );
}

Button.defaultProps = {
  variant: "primary",
  isLoading: false,
  disabled: false,
};
