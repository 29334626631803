import { Icon } from "@iconify/react";
import { Card } from "@mui/material";
import ApiClient from "api/v2/ApiClient";
import PasswordResetWarningModal from "components/Modals/Misc/PasswordResetWarningModal";
import UpdateContactDetailsModal from "components/Modals/Update/UpdateContactDetailsModal";
import UpdateEmailModal from "components/Modals/Update/UpdateEmailModal/UpdateEmailModal";
import { useAtom } from "jotai";
import { registrationInfoAtom } from "pages/Auth/Register/Register";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type ContactInformationProps = {
  user: {
    firstName: string;
    lastName: string;
    company: string;
    phone: string;
    email: string;
    address1: string;
    address2: string;
    address3: string;
    postCode: string;
  };
};

export default function ContactInformation({ user }: ContactInformationProps) {
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const navigate = useNavigate();
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openUpdateEmailModal, setOpenUpdateEmailModal] = useState(false);
  const [openPasswordResetModal, setOpenPasswordResetModal] = useState(false);

  const handlePasswordReset = async () => {
    await ApiClient.auth
      .sendPasswordResetCode({ email: user.email })
      .then((res) => {
        if (res.status != 200) {
          toast.error("An error occurred. Please try again later.");
          return;
        }
        setRegistrationInfo(user.email as any);
        toast.warn("Please check your email for the password reset code.");
        localStorage.clear();
        navigate("/reset-password");
      });
  };

  return (
    <>
      <Card className="p-4 flex flex-col">
        <h2 className="font-bold text-secondary pb-8 text-lg">
          Contact Information
        </h2>
        <div className="bg-background-light flex flex-col gap-5 rounded-lg relative text-primary px-4 pt-6 pb-4 h-full">
          <h2 className="text-lg font-bold">Personal Information</h2>
          <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 pb-5">
            <p>First Name</p>
            <p className="font-bold">{user.firstName || "N/A"}</p>
            <p>Surname</p>
            <p className="font-bold">{user.lastName || "N/A"}</p>
            <p>Company Name</p>
            <p className="font-bold">{user.company || "N/A"}</p>
            <p>Telephone</p>
            <p className="font-bold">{user.phone || "N/A"}</p>
            <p>Address 1</p>
            <p className="font-bold">{user.address1 || "N/A"}</p>
            <p>Address 2</p>
            <p className="font-bold">{user.address2 || "N/A"}</p>
            <p>Address 3</p>
            <p className="font-bold">{user.address3 || "N/A"}</p>
            <p>Post Code</p>
            <p className="font-bold">{user.postCode || "N/A"}</p>
          </div>
          <button
            onClick={() => setOpenUpdateModal(true)}
            className="absolute top-3 right-3 flex items-center gap-x-1"
          >
            <Icon icon="material-symbols:edit" />
            <span className="font-bold">EDIT</span>
          </button>

          <div className="flex flex-col gap-5 relative">
            <h2 className="text-lg font-bold">Registered Information</h2>
            <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 pb-5">
              <p>Email</p>
              <p className="font-bold break-all flex items-center relative">
                {user.email || "N/A"}{" "}
              </p>
              <p>Password reset</p>
              <button
                onClick={() => setOpenPasswordResetModal(true)}
                className="font-bold text-white bg-primary flex gap-1 items-center justify-center border border-primary p-2 rounded-lg text-center"
              >
                Reset
                <Icon icon="system-uicons:reset" className="text-xl" />
              </button>
            </div>
            <button
              onClick={() => setOpenUpdateEmailModal(true)}
              className="absolute top-3 right-3 flex items-center gap-x-1"
            >
              <Icon icon="material-symbols:edit" />
              <span className="font-bold">EDIT</span>
            </button>
          </div>
        </div>
      </Card>
      <UpdateContactDetailsModal
        user={{
          personFirstName: user.firstName,
          personSurname: user.lastName,
          address1: user.address1,
          address2: user.address2,
          address3: user.address3,
          addressPostcode: user.postCode,
          personMobileNumber: user.phone,
          companyName: user.company,
        }}
        open={openUpdateModal}
        handleClose={() => {
          setOpenUpdateModal(false);
        }}
      />
      <UpdateEmailModal
        user={{
          email: user.email,
        }}
        open={openUpdateEmailModal}
        handleClose={() => {
          setOpenUpdateEmailModal(false);
        }}
      />
      <PasswordResetWarningModal
        open={openPasswordResetModal}
        onResetPassword={() => {
          handlePasswordReset();
        }}
        handleClose={() => {
          setOpenPasswordResetModal(false);
        }}
      />
    </>
  );
}
