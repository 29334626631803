import { useCallback } from "react";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import { ROLES } from "utils/constants";

export type Comment = {
  id: string;
  content: string;
  userId: string;
  postId: string;
  createdAt: string;
  updatedAt: string;
};

type User = Pick<UserAuthSlice, "user">["user"];

type RolesTypes = typeof ROLES[keyof typeof ROLES];

export const POLICIES = {
  "comment:delete": (user: User, comment: Comment) => {
    if (user.type === ROLES.ADMIN) return true;
    if (user.type === ROLES.USER && comment.userId === user.clientId)
      return true;
    return false;
  },
};

export const useAuthorization = () => {
  const { user } = useSelector(authState) as UserAuthSlice;
  if (!user) throw Error("Unauthorized");

  const checkAccess = useCallback(
    ({ allowedRoles }: { allowedRoles: any }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles?.includes(user.type);
      }
      return true;
    },
    [user.type]
  );

  return {
    checkAccess,
    role: user.type,
  };
};

type AuthorizationProps = {
  forbiddenFallback?: React.ReactNode;
  additionalCheck?: boolean;
  children: React.ReactNode;
} & (
  | {
      allowedRoles: RolesTypes[];
      policyCheck?: never;
    }
  | {
      allowedRoles?: never;
      policyCheck: boolean;
    }
);

export const Authorization = ({
  policyCheck,
  allowedRoles,
  forbiddenFallback = null,
  additionalCheck = true,
  children,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();
  let canAccess = false;

  if (allowedRoles) {
    canAccess = checkAccess({ allowedRoles });
  }

  if (typeof policyCheck !== "undefined") {
    canAccess = policyCheck;
  }

  return <>{canAccess && additionalCheck ? children : forbiddenFallback}</>;
};
