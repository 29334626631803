import { Autocomplete, TextField } from "@mui/material";
import ApiClient from "api/v2/ApiClient";
import Button from "components/Button/Button";
import BaseCard from "components/Cards/BaseCard";
import useFetchSelectableUsers from "hooks/useFetchSelectableUsers";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

const ManualPasswordReset = () => {
  const { users, isLoading } = useFetchSelectableUsers();
  const { register, watch, setValue } = useForm();
  const { mutate } = useMutation({
    mutationFn: () =>
      ApiClient.admin.manualPasswordReset({
        email: watch("email"),
        password: watch("password"),
      }),
    onSuccess: () => {
      toast.success("Password reset successfully!");
    },
  });
  return (
    <BaseCard title="Manual Password Reset">
      <div className="w-full grid grid-cols-3 gap-5">
        {!isLoading && (
          <Autocomplete
            value={watch("email") || ""}
            options={users && users.length > 0 ? users.map((u) => u.Email) : []}
            onChange={(event, newValue) => {
              setValue("email", newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}

        <TextField
          {...register("password")}
          label="Set password"
          className=""
        />
        <Button className="w-1/3" onClick={async () => mutate()}>
          Reset
        </Button>
      </div>
    </BaseCard>
  );
};

export default ManualPasswordReset;
