import React from "react";
import BaseModal from "../BaseModal/BaseModal";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { toast } from "react-toastify";
import { POSTTAG_ERRORS } from "utils/errors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Skeleton } from "@mui/material";
import dayjs from "dayjs";
import { useEffect } from "react";
import AddressLookup from "components/PostcodeLookup/PostcodeLookup";

export default function UpdateBillingInfoModal({ open, handleClose, user }) {
  const [defaultTransaction, setDefaultTransaction] = React.useState("");
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const qc = useQueryClient();
  const [transactionRows, setTransactionRows] = React.useState([]);
  const { isLoading } = useQuery(["transactions", company], {
    queryFn: () =>
      ApiClient.payments.getTransactions({
        paygId: company?.PAYG_ID,
      }),
    enabled: !!company,
    onSuccess: (data) => {
      if (
        data.message &&
        data.message.includes(POSTTAG_ERRORS.NO_TRANSACTIONS)
      ) {
        setTransactionRows([]);
        return;
      }
      const uniquePayments = new Set(
        data.result
          .filter(
            (transaction) =>
              transaction.transactionStatus.toUpperCase() === "APPROVED"
          )
          .map((transaction) => transaction.cardLastFour)
      );

      setTransactionRows(
        Array.from(uniquePayments).map((ref) => {
          const transaction = data.result.find(
            (transaction) => transaction.cardLastFour === ref
          );
          return {
            ref: transaction.ref,
            items0Name: transaction.items0Name,
            transactionTime: transaction.transactionTime,
            cardLastFour: transaction.cardLastFour,
            cardExpiryMonth: transaction.cardExpiryMonth,
            cardExpiryYear: transaction.cardExpiryYear,
          };
        })
      );
    },
  });
  const { register, handleSubmit, getValues, setValue } = useForm();
  const { mutate: updateMutation } = useMutation(
    (data) =>
      ApiClient.account.updateBillingField({
        paygId: company?.PAYG_ID,
        key: data.key,
        value: data.value,
        ref: data.transactionRef ?? "",
      }),
    {
      onSuccess: () => {
        qc.invalidateQueries(["billing-details", company], {
          exact: true,
        });
        toast.success("Billing details updated successfully");
        handleClose();
      },
      onError: () => {
        qc.invalidateQueries(["billing-details", company], {
          exact: true,
        });
      },
    }
  );

  const onSubmit = async () => {
    const data = getValues();
    const keys = Object.keys(data);
    const values = Object.values(data);
    const promises = [];
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== user[keys[i]]) {
        promises.push(updateMutation({ key: keys[i], value: values[i] }));
      }
    }
    await Promise.all(promises);
    handleClose();
  };

  const onDefaultTransactionChange = (e) => {
    updateMutation({
      transactionRef: defaultTransaction,
    });
  };

  useEffect(() => {
    setValue("personTitle", user?.personTitle ?? "");
    setValue("personFirstName", user?.personFirstName ?? "");
    setValue("personSurname", user?.personSurname ?? "");
    setValue("personEmail", user?.personEmail ?? "");
    setValue("personMobileNumber", user?.personMobileNumber ?? "");
    setValue("address1", user?.address1 ?? "");
    setValue("address2", user?.address2 ?? "");
    setValue("address3", user?.address3 ?? "");
    setValue("addressTown", user?.addressTown ?? "");
    setValue("addressPostcode", user?.addressPostcode ?? "");
    setValue("CompanyName", user?.companyName ?? "");
    setValue("addressPropertyName", user?.addressPropertyName ?? "");
    setValue("invoiceEmail", user?.invoiceEmail ?? "");
    setValue("vatReg", user?.vatReg ?? "");
  }, [open, user]);

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Update Billing Information"
    >
      {!isLoading && transactionRows.length > 0 ? (
        <form
          onSubmit={handleSubmit(onDefaultTransactionChange)}
          className="mb-5 flex flex-col gap-5"
        >
          <h3 className="text-lg font-bold">Set previous payment as default</h3>
          <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
            <div className="flex flex-col">
              <label htmlFor="" className="italic text-secondary">
                Select
              </label>
              <Select
                placeholder="Select a transaction"
                className="py-2 text-right px-8 w-fit border-black border"
                value={defaultTransaction}
                onChange={(e) => setDefaultTransaction(e.target.value)}
              >
                {transactionRows.map((transaction) => (
                  <MenuItem
                    key={transaction.ref}
                    value={transaction.ref}
                    className="flex flex-col !text-left"
                  >
                    <p className="w-full text-left">
                      <span className="font-bold">
                        {dayjs(transaction.TransactionTime).format(
                          "DD/MM/YYYY"
                        )}
                      </span>
                    </p>
                    <p className="w-full text-left">
                      <span className="font-bold">Card Number:</span> **** ****
                      **** {transaction.cardLastFour}
                    </p>
                  </MenuItem>
                ))}
              </Select>
            </div>
            <button
              type="submit"
              variant="contained"
              className="!text-white bg-primary py-3 px-6  w-fit rounded-lg lg:w-fit"
            >
              Set as default
            </button>
          </div>
        </form>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <h3 className="text-lg font-bold">Personal Information</h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <TextField
            label="Title"
            {...register("personTitle")}
            id="personTitle"
            variant="outlined"
          />
          <TextField
            label="Mobile Number"
            {...register("personMobileNumber")}
            id="personMobileNumber"
            variant="outlined"
          />
          <TextField
            label="First Name"
            {...register("personFirstName")}
            id="personFirstName"
            variant="outlined"
          />
          <TextField
            label="Surname"
            {...register("personSurname")}
            id="personSurname"
            variant="outlined"
          />
          <TextField
            label="Email"
            {...register("personEmail")}
            id="personEmail"
            variant="outlined"
          />
          <TextField
            label="Invoice Email"
            {...register("invoiceEmail")}
            id="invoiceEmail"
            variant="outlined"
          />
          <div className="flex flex-col lg:col-span-2 gap-4">
            <div className="col-span-2">
              <AddressLookup
                onAddressLookup={(address) => {
                  setValue("address1", address?.["Address Line 1"]);
                  setValue("address2", address?.["Address Line 2"]);
                  setValue("address3", address?.["Address Line 3"]);
                  setValue("addressTown", address?.["Town/City"]);
                  setValue("CompanyName", address?.["Organisation/Department"]);
                  setValue("addressPostcode", address?.["Postcode"]);
                }}
              />
            </div>
            <TextField
              label="Address 1"
              {...register("address1")}
              id="address1"
              variant="outlined"
            />
            <TextField
              label="Address 2"
              {...register("address2")}
              id="address2"
              variant="outlined"
            />
            <TextField
              label="Address 3"
              {...register("address3")}
              id="address3"
              variant="outlined"
            />
            <TextField
              label="Town"
              {...register("addressTown")}
              id="addressTown"
              variant="outlined"
            />
            <TextField
              label="Postcode"
              {...register("addressPostcode")}
              id="addressPostcode"
              variant="outlined"
            />
          </div>
        </div>
        <h3 className="text-lg font-bold">Company Information</h3>
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="flex flex-col lg:col-span-2 gap-1">
            <TextField
              label="Company Name"
              {...register("CompanyName")}
              id="CompanyName"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col lg:col-span-2 gap-1">
            <TextField
              label="Property Name"
              {...register("addressPropertyName")}
              id="addressPropertyName"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col lg:col-span-2 gap-1">
            <TextField
              label="Vat Reg"
              {...register("vatReg")}
              id="vatReg"
              variant="outlined"
            />
          </div>
        </div>
        <button
          type="submit"
          variant="contained"
          className="!text-white bg-primary py-3 px-8 w-fit rounded-lg lg:w-fit ml-auto self-end"
        >
          Save Changes
        </button>
      </form>
    </BaseModal>
  );
}
