import { Text, View } from "@react-pdf/renderer";
import { InvoiceStylesheet } from "./useStyles";

type Props = {
  summary: {
    totalExclVat: string;
    vat: string;
    total: string;
  };
};

export default function InvoiceTableTotal({ summary }: Props) {
  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        fontSize: 10,
        paddingTop: 20,
        justifyContent: "space-between",
      }}
    >
      <View style={{ width: "100%" }}></View>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          marginLeft: "auto",
        }}
      >
        <TotalRow label={"Total excl. VAT"} value={summary.totalExclVat} />
        <Divider />
        <TotalRow label={"VAT @ 20%"} value={summary.vat} />
        <Divider />
        <TotalRow
          label={"Total"}
          value={summary.total}
          valueOptions={{ fontWeight: "bold" }}
        />
        <Divider />
        <TotalRow label={"Amount Due (GBP)"} value={summary.total} />
      </View>
    </View>
  );
}

const Divider = () => {
  return (
    <View
      style={{
        border: "0.25px solid black",
        marginTop: 5,
        marginBottom: 5,
      }}
    ></View>
  );
};

const TotalRow = ({
  label,
  value,
  valueOptions = {},
}: {
  label: string;
  value: string;
  valueOptions?: object;
}) => {
  return (
    <View style={{ display: "flex", flexDirection: "row", gap: 5 }}>
      <Text
        style={{
          width: "100%",
          fontWeight: "bold",
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          width: "100%",
          ...valueOptions,
        }}
      >
        {value}
      </Text>
    </View>
  );
};
