import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: {
    idValue: string;
    scheduleRef: string;
  };
};

type FormData = {
  password: string;
};

const DeleteManualPaymentModal = ({ open, handleClose, data }: Props) => {
  const qc = useQueryClient();
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit } = useForm<FormData>();
  const { mutate } = useMutation({
    mutationKey: ["delete-manual-payment"],
    mutationFn: (_data: { password: string }) =>
      ApiClient.admin.deleteManualPaymentSchedule({
        adminId: user?.payId ?? "",
        password: _data.password,
        idValue: data.idValue,
      }),
    onSuccess: () => {
      toast.success("Manual payment schedule deleted successfully");
      qc.invalidateQueries(["manual-payments", selectedUser]);
      handleClose();
    },
    onError: (error: any) => {
      toast.error("There was an issue deleting the payment");
    },
  });

  const onSubmit = async (_data: FormData) => {
    mutate({
      password: _data.password,
    });
  };

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Delete manual payment"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <span className="text-center font-bold">
          To delete this manual payment schedule please confirm your password
        </span>
        <TextField
          label="Confirm password"
          variant="outlined"
          type="password"
          required
          {...register("password")}
        />
        <div className="flex w-full gap-5">
          <Button
            type="button"
            className="w-full"
            variant="danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" className="w-full">
            Delete
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};

export default DeleteManualPaymentModal;
