import ApiClient from "api/v2/ApiClient";
import { ScheduledPayment } from "api/v2/Payments/types";
import { useQuery } from "react-query";

type Props = {
  selectedScheduledPayment: ScheduledPayment | null;
  paygId: string;
};

export default function useSelectedScheduledPayment({
  selectedScheduledPayment,
  paygId,
}: Props) {
  const { data: pastPayments, isLoading: isPastPaymentsLoading } = useQuery(
    ["past-payments", paygId, selectedScheduledPayment?.scheduleRef],
    {
      queryFn: () =>
        ApiClient.payments.getScheduleInfo({
          ref: selectedScheduledPayment?.scheduleRef ?? "",
          operation: "getRuns",
        }),
      enabled: !!selectedScheduledPayment,
      suspense: false,
    }
  );

  const { data: futurePayments, isLoading: isFuturePaymentsLoading } = useQuery(
    ["future-payments", paygId, selectedScheduledPayment?.scheduleRef],
    {
      queryFn: () =>
        ApiClient.payments.getScheduleInfo({
          ref: selectedScheduledPayment?.scheduleRef ?? "",
          operation: "getFuturePayments",
        }),
      enabled: !!selectedScheduledPayment,
      suspense: false,
    }
  );

  const { data: lookupSchedule, isLoading: isLookupScheduleLoading } = useQuery(
    ["lookup-payments", paygId, selectedScheduledPayment?.scheduleRef],
    {
      queryFn: () =>
        ApiClient.payments.getScheduleInfo({
          ref: selectedScheduledPayment?.scheduleRef ?? "",
          operation: "lookup",
        }),
      enabled: !!selectedScheduledPayment,
      suspense: false,
    }
  );
  return {
    pastPayments,
    futurePayments,
    lookupSchedule,
    isLoading:
      isPastPaymentsLoading ||
      isFuturePaymentsLoading ||
      isLookupScheduleLoading,
  };
}
