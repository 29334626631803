import { StyleSheet, Font } from "@react-pdf/renderer";
// @ts-ignore
import RobotoRegular from "assets/fonts/Roboto-Regular.ttf";
// @ts-ignore
import RobotoBold from "assets/fonts/Roboto-Bold.ttf";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 400,
    },
    {
      src: RobotoBold,
      fontWeight: 700,
    },
  ],
});

const stylesheet = {
  page: {
    fontSize: 11,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    lineHeight: 1.5,
    color: "#212529",
    flexDirection: "column",
    fontFamily: "Roboto",
  },

  spaceBetween: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#3E3E3E",
  },

  bold: {
    fontWeight: "bold",
  },

  titleContainer: { flexDirection: "row", marginTop: 24, width: "100%" },

  reportTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },

  addressTitle: { fontSize: 11, fontStyle: "bold" },

  invoice: { fontWeight: "bold", fontSize: 20 },

  invoiceNumber: { fontSize: 11, fontWeight: "bold" },

  address: { fontWeight: 400, fontSize: 10 },

  theader: {
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1,
    height: 20,
    borderBottomWidth: 0.25,
    fontWeight: "bold",
  },

  theader2: {
    flex: 2,
    borderRightWidth: 0,
    borderBottomWidth: 0.25,
    fontWeight: "bold",
  },

  tbody: {
    fontSize: 9,
    paddingTop: 5,
    paddingLeft: 7,
    paddingRight: 10,
    flex: 1,
    borderColor: "black",
    borderBottomWidth: 0.25,
  },

  total: {
    fontSize: 9,
    paddingTop: 4,
    paddingLeft: 7,
    flex: 1.5,
    borderColor: "whitesmoke",
    borderBottomWidth: 1,
  },
} as const;

export type InvoiceStylesheet = typeof stylesheet;

export default function useStyles() {
  const styles = StyleSheet.create(stylesheet);

  return {
    styles,
  };
}
