import { useState } from "react";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { FormProvider, useForm } from "react-hook-form";
import SubscriptionTypeSection from "./SubscriptionTypeSection";
import SubscriptionPaymentDetailsSection from "./SubscriptionPaymentDetailsSection";
import Button from "components/Button/Button";
import { useMutation } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { PaymentModalType } from "../PriceCards";
import dayjs from "dayjs";
import { BadErrorResponse } from "types/NetworkTypes";
import { toast } from "react-toastify";
import { POSTTAG_RESPONSES } from "utils/responses";
import { PurchaseSubscriptionRequest } from "api/v2/Payments/types";

type Props = {
  open: boolean;
  handleClose: () => void;
  item: PaymentModalType;
};

export default function SubscriptionPaymentModal({
  open,
  handleClose,
  item,
}: Props) {
  const { mutate } = useMutation(
    (data: PurchaseSubscriptionRequest) =>
      ApiClient.payments.purchaseSubscription(data),
    {
      onSuccess: (data) => {
        if (
          data.message &&
          data.message.includes(POSTTAG_RESPONSES.SCHEDULE_PROCESSED)
        ) {
          toast.success(data.message);
          handleClose();
          return;
        }
        window.open(data.result.token.redemption_url, "_blank") ||
          window.location.assign(data.result.token.redemption_url);
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );
  const [formStep, setFormStep] = useState(0);
  const subscriptionForm = useForm({
    defaultValues: {
      title: "",
      first_name: "",
      surname: "",
      company: "",
      mobile_number: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      postcode: "",
      property_address: "",
      town: "",
      recurrenceType: true,
      purchaseType: "",
      Ref: "",
      startDate: dayjs().add(1, "day").format("YYYY-MM-DD"),
    },
  });

  const onFormSubmit = async (data: any) => {
    mutate({
      item: {
        name: item.name,
        price: item.pricePerCredit,
        quantity: item.quantity,
      },
      person: {
        email: data.email,
        title: data.title,
        firstName: data.first_name,
        surname: data.surname,
        mobileNumber: data.mobile_number,
      },
      address: {
        address1: data.address_line_1,
        address2: data.address_line_2,
        postCode: data.postcode,
        propertyName: data.property_name,
        town: data.town,
      },
      callbackUrl: `${window.location.origin}/overview`,
      companyName: data.company,
      recurrenceType: data.recurrenceType ? "first" : "single",
      startDate: data.startDate,
      purchaseType: data.purchaseType,
      maximumPayments: 12,
      ref: data.Ref,
      threshold: data.threshold,
      paymentAmount: item.price,
      credits: item.quantity,
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Purchase Subscription"
    >
      <div className="flex flex-col gap-10">
        <FormProvider {...subscriptionForm}>
          {formStep === 0 && <SubscriptionTypeSection />}
          {formStep === 1 && <SubscriptionPaymentDetailsSection />}
          <div className="flex mt-auto justify-between">
            {formStep === 0 ? (
              <Button
                onClick={() => setFormStep(1)}
                className="ml-auto"
                variant="primary"
              >
                Next
              </Button>
            ) : (
              <div className=""></div>
            )}
            {formStep === 1 && (
              <div className="flex flex-col w-full gap-3 sm:flex-row">
                <Button
                  className="self-start sm:w-fit mr-auto w-full"
                  onClick={() => setFormStep(0)}
                  variant="primary"
                >
                  Back
                </Button>
                <Button
                  className="w-full sm:w-fit"
                  onClick={() =>
                    subscriptionForm.handleSubmit(
                      onFormSubmit(subscriptionForm.getValues()) as any
                    )
                  }
                  variant="primary"
                >
                  Continue to payment
                </Button>
              </div>
            )}
          </div>
        </FormProvider>
      </div>
    </BaseModal>
  );
}
