import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useAtom } from "jotai";
import GenericLayout from "layouts/GenericLayout";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import ApiClient from "api/v2/ApiClient";
import AccountDetails from "./components/ApiBar";
import { useQuery } from "react-query";
import RegistrationCard from "./components/RegistrationCard";
import { ROLES } from "utils/constants";
import { POSTTAG_ERRORS } from "utils/errors";
import DocumentationCard from "./components/DocumentationCard";
import { Suspense } from "react";
import ApiDetailsShimmer from "./components/ApiDetailsShimmer";

export default function ApiDetails() {
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { user } = useSelector(authState);
  const { data, isLoading } = useQuery(["account", company], {
    queryFn: () =>
      ApiClient.admin.getApiDetails({
        paygId: company?.["PAYG_ID"] ?? user.payId,
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result;
    },
    enabled: !!company,
  });

  if (isLoading) return <ApiDetailsShimmer />;

  return (
    <GenericLayout showFilterBar>
      <Suspense fallback={<ApiDetailsShimmer />}>
        <div className="flex flex-col gap-y-8">
          <div className="flex flex-col gap-1">
            <AccountDetails
              apiKey={
                POSTTAG_ERRORS.NOT_AUTHORIZED === data?.["PAYG_ID"]
                  ? null
                  : data?.["PAYG_ID"]
              }
              clientId={
                POSTTAG_ERRORS.NOT_AUTHORIZED === data?.["ClientID"]
                  ? null
                  : data?.["ClientID"]
              }
              secret={
                POSTTAG_ERRORS.NOT_AUTHORIZED === data?.["Secret"]
                  ? null
                  : data?.["Secret"]
              }
            />
          </div>
          {user?.type !== ROLES.USER && <RegistrationCard />}
          <DocumentationCard userUsageLimit={data?.UsageLimit} />
        </div>
      </Suspense>
    </GenericLayout>
  );
}
