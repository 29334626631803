import { AxiosInstance } from "axios";
import { GenericResponse } from "types/GenericResponse";
import {
  ContractInfo,
  Invoice,
  UpdateBillingDetailRequest,
  UpdateUserEmailRequest,
  UpdateUserFieldRequest,
} from "./types";

export default class AccountApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async getContractInformation({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<ContractInfo>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getContractInfo",
      idValue: paygId,
    });
    return result.data;
  }

  async updateUserField({ paygId, key, value }: UpdateUserFieldRequest) {
    const result = await this.http.post(
      `
      ${this.baseURL}/payg_accounts.php`,
      {
        cmd: "updateUser",
        idValue: paygId,
        field: key,
        value: value,
      }
    );
    return result.data;
  }

  async updateEmail({ email, paygId, password }: UpdateUserEmailRequest) {
    const result = await this.http.post(
      `
      ${this.baseURL}/payg_accounts.php`,
      {
        cmd: "updateUser",
        idValue: paygId,
        field: "personEmail",
        value: email,
        currentPassword: password,
      }
    );
    return result.data;
  }

  async updateBillingField({
    paygId,
    key,
    value,
    ref,
  }: UpdateBillingDetailRequest) {
    const result = await this.http.post(
      `
      ${this.baseURL}/payg_accounts.php`,
      {
        cmd: "updateBillingInfo",
        idValue: paygId,
        field: key,
        value: value,
        ref: ref,
      }
    );
    return result.data;
  }

  async getInvoice({
    ref,
    password,
  }: {
    ref: string;
    password: string;
  }): Promise<GenericResponse<Invoice>> {
    const result = await this.http.post(
      `
      ${this.baseURL}/payg_accounts.php`,
      {
        cmd: "getInvoice",
        ref: ref,
        password: password,
      }
    );

    return result.data;
  }
}
