import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import MDInput from "components/ui/MDInput";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";

type Props = {
  open: boolean;
  handleClose: () => void;
  generatedKey: string;
};

export default function WarningModal({
  open,
  handleClose,
  generatedKey,
}: Props) {
  const { user } = useSelector(authState);
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { register, getValues, handleSubmit, reset } = useForm();
  const { mutate } = useMutation(
    (data: { password: string }) =>
      ApiClient.admin.updateOperatorSecret({
        adminId: user.payId,
        password: data.password,
        secret: generatedKey,
        paygId: company?.PAYG_ID ?? "",
      }),
    {
      onSuccess: (data) => {
        if (data) toast.success("Operator key updated successfully");
        handleClose();
        reset();
      },
      onError: () => {
        toast.error("Incorrect password");
      },
    }
  );

  const formSubmit = async () => {
    mutate({
      password: getValues("password"),
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="You are about to change the operator key"
    >
      <form
        action=""
        onSubmit={handleSubmit(formSubmit)}
        className="flex flex-col gap-5"
      >
        <h2 className="text-lg font-semibold text-secondary">
          To change the operator key, you must first confirm your password.
        </h2>
        <MDInput
          type="password"
          className="w-1/2"
          label="Password"
          {...register("password")}
        />
        <button
          type="submit"
          className="bg-primary py-3 px-8 w-fit rounded-lg text-white"
        >
          Update operator key
        </button>
      </form>
    </BaseModal>
  );
}
