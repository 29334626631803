import Sidebar from "components/Sidebar";
import MobileSidebar from "components/MobileSidebar/MobileSidebar";
import FilterBar from "pages/Dashboard/components/FilterBar";
import ApiClient from "api/v2/ApiClient";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { authState, logOut } from "store/authSlice";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useAtom } from "jotai";
import { ROLES } from "utils/constants";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import InactivePopup from "./InactivePopup";
import ShowTermsAndConditionsModal from "pages/Auth/Register/components/ShowTermsAndConditionsModal";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const timeout = 60 * 20 * 1000;

const promptBeforeIdle = 10 * 1000;

export default function GenericLayout({
  children,
  routeRefs = [],
  showFilterBar = false,
}) {
  const dispatch = useDispatch();
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { user } = useSelector(authState);
  const [searchParams] = useSearchParams();

  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [open, setOpen] = useState(false);

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: () => {
      setState("Idle");
      setOpen(false);
    },
    onActive: () => {
      setState("Active");
      setOpen(false);
    },
    onPrompt: () => {
      setState("Prompted");
      setOpen(true);
    },
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  const { data: companyDetails } = useQuery(["company-details"], {
    queryFn: () =>
      ApiClient.company.getCompanyDetails({
        paygId: user?.payId,
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result;
    },
  });

  const { data, isLoading: isCompaniesLoading } = useQuery(["companies"], {
    queryFn: () =>
      ApiClient.taxi.getTaxiList({
        payId: user?.payId,
      }),
    onSuccess: (data) => {
      if (user?.type === ROLES.OPERATOR && !companyDetails) {
        setCompany(data.find((company) => company["PAYG_ID"] === user?.payId));
        return;
      }
      if (!company) {
        setCompany(data.find(Boolean));
      }
    },
    select: (data) => {
      if (data.status !== 200) return [];
      return data?.result;
    },
    enabled: !!companyDetails,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const logoutUser = async () => {
    await ApiClient.auth
      .logout()
      .then(() => {
        dispatch(logOut());
        window.localStorage.removeItem("access");
        window.localStorage.removeItem("refresh");
        window.location.href = "/login";
        toast.success("Logged out successfully");
      })
      .catch(() => {
        toast.error("Failed to logout");
      });
  };

  if (state === "Idle") {
    logoutUser();
  }

  return (
    <>
      <main className="relative">
        <div className="xl:hidden">
          <MobileSidebar />
        </div>
        <div className="flex min-h-screen bg-[#F0F2F5] relative px-8 pt-7 gap-x-4 pb-10 ml-0 mr-0">
          <div className="hidden xl:block">
            <Sidebar routeRefs={routeRefs} />
          </div>
          <div className="w-full overflow-hidden flex flex-col gap-y-10">
            {showFilterBar && data ? (
              <FilterBar
                data={data}
                isLoading={isCompaniesLoading}
                from={searchParams.get("from")}
                to={searchParams.get("to")}
                gran={searchParams.get("granularity")}
              />
            ) : (
              <div className="hidden xl:block xl:h-[65px]"></div>
            )}

            {children}
          </div>
        </div>
        {open && (
          <InactivePopup
            timeRemaining={remaining}
            onStayLoggedIn={() => activate()}
          />
        )}
      </main>
      <Footer />
    </>
  );
}

const Footer = () => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  return (
    <footer className="bg-primary text-white w-full py-10 p-8 flex justify-start">
      <button
        onClick={() => setShowTermsAndConditions(true)}
        className="hover:underline cursor-pointer"
      >
        Terms and Conditions
      </button>
      <ShowTermsAndConditionsModal
        handleClose={() => setShowTermsAndConditions(false)}
        open={showTermsAndConditions}
      />
    </footer>
  );
};
