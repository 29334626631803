import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { ROLES } from "utils/constants";

type Props = {
  children: React.ReactNode;
  allowedRoles?: string[];
  additionalCheck?: () => boolean;
};

export default function RoleGate({
  children,
  allowedRoles,
  additionalCheck,
}: Props) {
  const { user } = useSelector(authState);
  if (!user) return null;
  return (
    <>
      {allowedRoles?.includes(user?.type) && additionalCheck?.()
        ? children
        : null}
    </>
  );
}

RoleGate.defaultProps = {
  allowedRoles: [ROLES.ADMIN, ROLES.USER, ROLES.OPERATOR],
  additionalCheck: () => true,
};
