import { Taxis } from "./types";
import { AxiosInstance } from "axios";
import { GenericResponse } from "types/GenericResponse";

export default class TaxiApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async getTaxiList(): Promise<GenericResponse<Taxis[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getTaxiList",
    });
    return result.data;
  }
}
