import { AxiosInstance } from "axios";
import {
  UpdatePaymentScheduleRequest,
  PurchaseCreditsRequest,
  ScheduledPayment,
  Runs,
  ScheduleDetails,
  Transaction,
  UpdateSubscriptionRequest,
  PriceCards,
  BillingInoformation,
  PurchaseSubscriptionRequest,
  PaygPortalResponse,
  VerifyPaymentMethodRequest,
} from "./types";
import { GenericResponse } from "types/GenericResponse";

export default class PaymentsApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async getTransactions({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<Transaction[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getTransactions",
      idValue: paygId,
    });
    return result.data;
  }

  async getBillingDetails({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<BillingInoformation>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getBillingInfo",
      idValue: paygId,
    });
    return result.data;
  }

  async getPrices({
    paygId,
    type,
  }: {
    paygId: string;
    type: "PAYG" | "Subscription";
  }): Promise<GenericResponse<PriceCards>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getPrices",
      idValue: paygId,
      type,
    });
    return result.data;
  }

  async verifyPaymentMethod({
    address,
    callbackUrl,
    companyName,
    person,
    scheduleRef,
  }: VerifyPaymentMethodRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getToken",
      companyName: companyName,
      personTitle: person.title,
      personFirstName: person.firstName,
      personSurname: person.surname,
      personEmail: person.email,
      personMobileNumber: person.mobileNumber,
      addressPropertyName: address.propertyName,
      address1: address.address1,
      address2: address.address2,
      addressTown: address.town,
      addressPostcode: address.postCode,
      callbackUrl,
      purchaseType: "CC",
      scheduleRef,
    });
    return result.data;
  }

  async purchaseCredits({
    item,
    person,
    address,
    companyName,
    callbackUrl,
    recurrenceType,
    promocode = "",
  }: PurchaseCreditsRequest): Promise<GenericResponse<PaygPortalResponse>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getToken",
      itemName: item.name,
      itemPrice: item.price,
      itemQuantity: item.quantity,
      companyName: companyName,
      personTitle: person.title,
      personFirstName: person.firstName,
      personSurname: person.surname,
      personEmail: person.email,
      personMobileNumber: person.mobileNumber,
      addressPropertyName: address.propertyName,
      address1: address.address1,
      address2: address.address2,
      addressTown: address.town,
      addressPostcode: address.postCode,
      callbackUrl,
      recurrenceType,
      purchaseType: "PAYG",
      promocode,
    });
    return result.data;
  }

  async purchaseSubscription({
    item,
    person,
    address,
    companyName,
    callbackUrl,
    purchaseType,
    recurrenceType,
    startDate,
    maximumPayments,
    threshold,
    ref,
    credits,
    paymentAmount,
    promocode = "",
  }: PurchaseSubscriptionRequest): Promise<
    GenericResponse<PaygPortalResponse>
  > {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getToken",
      itemName: item.name,
      itemPrice: item.price,
      itemQuantity: item.quantity,
      companyName: companyName,
      personTitle: person.title,
      personFirstName: person.firstName,
      personSurname: person.surname,
      personEmail: person.email,
      personMobileNumber: person.mobileNumber,
      addressPropertyName: address.propertyName,
      address1: address.address1,
      address2: address.address2,
      addressTown: address.town,
      addressPostcode: address.postCode,
      callbackUrl,
      purchaseType,
      recurrenceType,
      startDate,
      maximumPayments,
      threshold,
      ref,
      credits,
      repeatPattern: "month",
      paymentAmount,
      promocode,
    });
    return result.data;
  }

  async getLastTransaction({ paygId }: { paygId: string }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getLastTransaction",
      idValue: paygId,
    });
    return result.data;
  }

  async updateSubscription({
    ref,
    paygId,
    operation,
  }: UpdateSubscriptionRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "updateScheduleStatus",
      idValue: paygId,
      ref,
      operation,
    });
    return result.data;
  }

  async getScheduleDetails({
    paygId,
    scheduleRef,
  }: {
    paygId: string;
    scheduleRef: string;
  }): Promise<{ message: string; result: ScheduleDetails; status: number }> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "lookupSchedule",
      PAYG_ID: paygId,
      scheduleRef,
    });
    return result.data;
  }

  async getSchedules({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<ScheduledPayment[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getSchedules",
      idValue: paygId,
    });
    return result.data;
  }

  async getPastPayments({
    paygId,
    scheduleRef,
  }: {
    paygId: string;
    scheduleRef: string;
  }): Promise<{
    message: string;
    result: { runs: Runs[] };
    status: number;
  }> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "showPastPayments",
      PAYG_ID: paygId,
      scheduleRef,
    });
    return result.data;
  }

  async getFuturePayments({
    scheduleRef,
    paygId,
  }: {
    paygId: string;
    scheduleRef: string;
  }): Promise<{
    message: string;
    result: { futureRuns: Runs[] };
    status: number;
  }> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "showFuturePayments",
      PAYG_ID: paygId,
      scheduleRef,
    });
    return result.data;
  }

  async getRuns({
    scheduleRef,
    paygId,
  }: {
    paygId: string;
    scheduleRef: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getRuns",
      PAYG_ID: paygId,
      scheduleRef,
    });
    return result.data;
  }

  async updatePaymentSchedule({
    adminId,
    password,
    scheduleRef,
    paygId,
    key,
    value,
  }: UpdatePaymentScheduleRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "editSchedule",
      adminId: adminId,
      password,
      idValue: paygId,
      scheduleRef,
      field: key,
      value,
    });
    return result.data;
  }

  async getScheduleInfo({
    ref,
    operation,
  }: {
    ref: string;
    operation: "lookup" | "getFuturePayments" | "getRuns";
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getScheduleInfo",
      ref,
      operation,
    });
    return result.data;
  }
}
