import { Card } from "@mui/material";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";

export default function RegistrationCard() {
  const [selectedCompany, setSelectedCompany] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user } = useSelector(authState) as UserAuthSlice;

  return (
    <Card className="w-full p-5">
      <h1 className="font-bold text-secondary pb-5 text-lg">
        Registration Link
      </h1>
      <div className="grid grid-cols-api-key">
        <p className="text-secondary text-xl lg:text-base p-4">Referral link</p>
        <div className="flex items-center justify-center">
          <h2 className="font-bold text-lg">
            {`${window.location.origin}/register?referral=${
              selectedCompany?.ClientID || user?.clientId
            }`}
          </h2>
        </div>
        <div className="flex flex-row justify-end p-4">
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                `${window.location.origin}/register?referral=${
                  selectedCompany?.ClientID || user?.clientId
                }`
              );
              toast.success("Copied to clipboard");
            }}
          >
            <Icon
              icon="material-symbols:file-copy-outline"
              width="32px"
              className="mx-2"
            />
          </button>
        </div>
      </div>
    </Card>
  );
}
