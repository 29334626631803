import GenericLayout from "layouts/GenericLayout";
import { Suspense } from "react";
import AdminFunctionsPageShimmer from "./components/AdminFunctionsPageShimmer";
import AdminSignup from "./components/PlatformManagement/AdminSignup";
import ManualPasswordReset from "./components/PlatformManagement/ManualPasswordReset";
import ManualEmailVerification from "./components/PlatformManagement/ManualEmailVerification";

export default function UserManagement() {
  return (
    <GenericLayout>
      <Suspense fallback={<AdminFunctionsPageShimmer />}>
        <div className="section flex flex-col gap-5">
          <AdminSignup />
          <ManualPasswordReset />
          <ManualEmailVerification />
        </div>
      </Suspense>
    </GenericLayout>
  );
}
