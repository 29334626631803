import ApiClient from "api/v2/ApiClient";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import MDInput from "components/ui/MDInput";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { POSTTAG_ERRORS } from "utils/errors";
import { toast } from "react-toastify";
import Button from "components/Button/Button";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { PurchaseCreditsRequest } from "api/v2/Payments/types";
import { Checkbox } from "@mui/material";
import { useLocation } from "react-router-dom";

type PaymentModalProps = {
  open: boolean;
  handleClose: () => void;
  item: {
    name: string;
    price: string;
    quantity: number;
    pricePerCredit: string;
  };
};

export default function PaymentModal({
  open,
  handleClose,
  item,
}: PaymentModalProps) {
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { mutate } = useMutation(
    (data: PurchaseCreditsRequest) => ApiClient.payments.purchaseCredits(data),
    {
      onSuccess: (data) => {
        window.open(data.result.token.redemption_url, "_blank") ||
          window.location.assign(data.result.token.redemption_url);
      },
    }
  );
  const { data: hasBiilingInfo, isLoading: isBillingInfoLoading } = useQuery(
    ["billing-information"],
    {
      queryFn: () =>
        ApiClient.payments.getBillingDetails({
          paygId: company ? company.PAYG_ID : "",
        }),
      select: (data) =>
        !data.message.includes(POSTTAG_ERRORS.NO_BILLING_INFORMATION),
    }
  );

  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      first_name: "",
      surname: "",
      company: "",
      mobile_number: "",
      email: "",
      address_line_1: "",
      address_line_2: "",
      postcode: "",
      property_name: "",
      town: "",
      recurrenceType: true,
    },
  });

  const onFormSubmit = async (data: any) => {
    mutate({
      item: {
        name: item.name,
        price: item.pricePerCredit,
        quantity: item.quantity,
      },
      person: {
        email: data.email,
        title: data.title,
        firstName: data.first_name,
        surname: data.surname,
        mobileNumber: data.mobile_number,
      },
      address: {
        address1: data.address_line_1,
        address2: data.address_line_2,
        postCode: data.postcode,
        propertyName: data.property_name,
        town: data.town,
      },
      callbackUrl: `${window.location.origin}/overview`,
      companyName: data.company,
      recurrenceType: data.recurrenceType ? "first" : "single",
    });
  };

  const fetchBillingInfo = async () => {
    await ApiClient.payments
      .getBillingDetails({
        paygId: company ? company.PAYG_ID : "",
      })
      .then((res) => {
        if (
          res.status != 200 ||
          res.message.includes(POSTTAG_ERRORS.NO_BILLING_INFORMATION)
        )
          return toast.warn("No billing information found");

        const info = res.result;
        if (!info) return;

        setValue("title", info?.["personTitle"]);
        setValue("first_name", info["personFirstName"]);
        setValue("surname", info["personSurname"]);
        setValue("company", info["CompanyName"]);
        setValue("email", info["personEmail"]);
        setValue("mobile_number", info["personMobileNumber"]);
        setValue("address_line_1", info["address1"]);
        setValue("address_line_2", info["address2"]);
        setValue("postcode", info["addressPostcode"]);
        setValue("town", info["addressTown"]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const fetchContactInfo = async () => {
    await ApiClient.company
      .getCompanyDetails({
        paygId: company?.PAYG_ID ?? "",
      })
      .then((_res) => {
        const res = _res.result;
        setValue("title", res["personTitle"]);
        setValue("first_name", res["personFirstName"]);
        setValue("surname", res["personSurname"]);
        setValue("company", res["CompanyName"]);
        setValue("email", res["personEmail"]);
        setValue("address_line_1", res["address1"]);
        setValue("mobile_number", res["personMobileNumber"]);
        setValue("address_line_2", res["address2"]);
        setValue("postcode", res["addressPostcode"]);
        setValue("property_name", res["addressPropertyName"]);
        setValue("town", res["addressTown"]);
      });
  };

  useEffect(() => {
    reset();
  }, [open]);

  return (
    <BaseModal open={open} handleClose={handleClose} title="Purchase Credits">
      <div className="w-full flex flex-col gap-y-8">
        <div className="flex flex-col sm:flex-row justify-center gap-5 items-center">
          <Button
            disabled={isBillingInfoLoading || !hasBiilingInfo}
            onClick={() => fetchBillingInfo()}
          >
            Use Billing Information
          </Button>
          <Button onClick={() => fetchContactInfo()}>
            Use Contact Information
          </Button>
        </div>
        <form
          action=""
          className="flex flex-col p-3"
          onSubmit={handleSubmit(onFormSubmit)}
        >
          <div className="grid grid-cols-1 gap-y-3 gap-x-3 mb-5 sm:grid-cols-2">
            <MDInput
              {...register("title")}
              type="text"
              required
              value={watch("title")}
              label="Title"
              error={errors?.title?.message}
              fullWidth
            />
            <MDInput
              type="text"
              label="First name"
              required
              value={watch("first_name")}
              {...register("first_name")}
              error={errors?.first_name?.message}
            />
            <MDInput
              type="text"
              required
              label="Surname"
              value={watch("surname")}
              error={errors?.surname?.message}
              {...register("surname")}
            />
            <MDInput
              type="text"
              error={errors?.company?.message}
              label="Company"
              fullWidth
              value={watch("company")}
              required
              {...register("company")}
            />
            <MDInput
              {...register("mobile_number")}
              type="text"
              required
              value={watch("mobile_number")}
              label="Mobile Number"
              error={errors?.mobile_number?.message}
              fullWidth
            />
            <MDInput
              {...register("email")}
              error={errors?.email?.message}
              type="text"
              required
              value={watch("email")}
              label="Email Address"
              fullWidth
            />
            <div className="sm:col-span-2">
              <MDInput
                {...register("property_name")}
                type="text"
                label="Property Name"
                value={watch("property_name")}
                error={errors?.property_name?.message}
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("address_line_1")}
                type="text"
                label="Address Line 1"
                value={watch("address_line_1")}
                required
                error={errors?.address_line_1?.message}
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("address_line_2")}
                value={watch("address_line_2")}
                type="text"
                error={errors?.address_line_2?.message}
                label="Address Line 2"
                fullWidth
              />
            </div>
            <MDInput
              {...register("town")}
              type="text"
              value={watch("town")}
              label="Town"
              error={errors?.town?.message}
              required
              fullWidth
            />
            <MDInput
              {...register("postcode")}
              value={watch("postcode")}
              type="text"
              required
              label="Post Code"
              error={errors?.postcode?.message}
              fullWidth
            />
          </div>
          <div className="flex font-semibold items-center gap-2 text-primary">
            <label htmlFor="">Remember for future payments?</label>
            <Checkbox
              size="medium"
              checked={watch("recurrenceType")}
              onChange={() => {
                setValue("recurrenceType", !getValues("recurrenceType"));
              }}
              className="!border !border-primary"
            />
          </div>
          <Button type="submit" className="self-end ml-auto">
            Continue Payment
          </Button>
        </form>
      </div>
    </BaseModal>
  );
}
