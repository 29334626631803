import BillingCards from "pages/PaymentDetails/components/BillingCards/BillingCards";
import ContractInformation from "pages/PaymentDetails/components/ContractInformation";
import Transactions from "pages/PaymentDetails/components/Transactions/Transactions";
import GenericLayout from "layouts/GenericLayout";
import ScheduledPayments from "pages/AdminFunctions/components/ScheduledPayments/ScheduledPayments";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { Suspense } from "react";
import AccountPageShimmer from "pages/PaymentDetails/components/AccountPageShimmer";
import { UserAuthSlice } from "types/ReduxTypes";
import { ROLES } from "utils/constants";
import { SelectedCompanyAtomType } from "types/AtomTypes";

export default function PaymentDetails() {
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  return (
    <GenericLayout showFilterBar={user?.type === ROLES.ADMIN}>
      <Suspense fallback={<AccountPageShimmer />}>
        <div className="flex flex-col gap-y-8">
          <Transactions />
          <ContractInformation />
          {company && (
            <ScheduledPayments company={company} paygId={user?.payId ?? ""} />
          )}
          <BillingCards />
        </div>
      </Suspense>
    </GenericLayout>
  );
}
