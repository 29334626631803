import BaseModal from "components/Modals/BaseModal/BaseModal";
import PropTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { ManageCreditsRequest } from "api/v2/Admin/types";
import { BadErrorResponse } from "types/NetworkTypes";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import ApiClient from "api/v2/ApiClient";
import { useForm } from "react-hook-form";
import { ROLES } from "utils/constants";
import MDInput from "components/ui/MDInput";
import CurrencyInput from "react-currency-input-field";

type Props = {
  open: boolean;
  handleClose: () => void;
  credits: number;
};

const actions = {
  ADD: "add",
  REMOVE: "remove",
  SET: "set",
} as const;

const types = {
  USER: "User",
  OPERATOR: "Operator",
} as const;

type FormData = {
  credits: number;
  password: string;
  type: typeof types[keyof typeof types];
  action: typeof actions[keyof typeof actions];
};

export default function UpdateCreditsAmountModal({
  open,
  handleClose,
  credits,
}: Props) {
  const qc = useQueryClient();
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, getValues, handleSubmit, watch, setValue, reset } =
    useForm<FormData>({
      defaultValues: {
        credits: undefined,
        password: "",
        type: "User",
        action: "add",
      },
    });
  const { mutate } = useMutation(
    (data: ManageCreditsRequest) => ApiClient.admin.manageCredits(data),
    {
      onSuccess: () => {
        if (getValues("action") === "add") {
          toast.success(`Added ${getValues("credits")} credits`);
        }
        if (getValues("action") === "remove") {
          toast.success(`Removed ${getValues("credits")} credits`);
        }
        if (getValues("action") === "set") {
          toast.success(`Credits set to ${getValues("credits")}`);
        }
        qc.invalidateQueries(["credits"]);
        handleClose();
        reset();
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const formSubmit = (data: FormData) => {
    if (getValues("credits") < 0) {
      return toast.error("Amount must be greater than 0");
    }
    if (isNaN(getValues("credits")) || getValues("credits") % 1 !== 0) {
      return toast.error("Amount must be a whole number");
    }
    mutate({
      ...data,
      adminId: user?.payId ?? "",
      paygId: company?.PAYG_ID ?? "",
      type: company?.Type === "Operator" ? getValues("type") : "User",
      action: getValues("action"),
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={`Edit credit amount`}
    >
      <div className="flex flex-col gap-5 mb-10 justify-center">
        <h1 className="flex self-center mb-5 text-primary font-bold text-3xl">
          Current Credits: {credits}
        </h1>
        <div className="flex flex-col gap-5">
          <div className="flex justify-center gap-5">
            <CreditFunctionButton
              onSelect={(val) => setValue("action", val)}
              label="Add Credits"
              selected={watch("action") === actions.ADD}
              value={actions.ADD}
            />
            <CreditFunctionButton
              onSelect={(val) => setValue("action", val)}
              label="Remove Credits"
              selected={watch("action") === actions.REMOVE}
              value={actions.REMOVE}
            />
            <CreditFunctionButton
              onSelect={(val) => setValue("action", val)}
              label="Set Credits"
              selected={watch("action") === actions.SET}
              value={actions.SET}
            />
          </div>
          {company?.Type === ROLES.OPERATOR && (
            <div className="flex justify-center gap-5">
              <CreditFunctionButton
                onSelect={(val) => setValue("type", val)}
                label="Individual User"
                selected={watch("type") === types.USER}
                value={types.USER}
              />
              <CreditFunctionButton
                onSelect={(val) => setValue("type", val)}
                label="All users"
                selected={watch("type") === types.OPERATOR}
                value={types.OPERATOR}
              />
            </div>
          )}
        </div>
        <form
          onSubmit={handleSubmit(formSubmit)}
          className="flex flex-col gap-5"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div className="col-span-1 sm:col-span-2">
              <CurrencyInput
                decimalsLimit={0}
                allowDecimals={false}
                disableGroupSeparators
                value={watch("credits") || 0}
                className="py-3 px-2 w-full border border-secondary border-opacity-30 rounded-lg text-sm"
                {...register("credits", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
            </div>

            <div className="col-span-1 sm:col-span-2">
              <MDInput
                type="password"
                label="Password"
                {...register("password", {
                  required: true,
                })}
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-primary self-end py-3 px-8 w-fit rounded-lg text-white"
          >
            Update
          </button>
        </form>
      </div>
    </BaseModal>
  );
}

function CreditFunctionButton<T>({
  onSelect,
  label,
  selected,
  value,
}: {
  onSelect: (value: T) => void;
  label: string;
  value: T;
  selected: boolean;
}) {
  return (
    <button
      className={`px-8 py-3 ${
        selected
          ? "bg-primary text-white"
          : "bg-white text-black border border-primary"
      } rounded-lg`}
      onClick={() => {
        onSelect(value);
      }}
    >
      {label}
    </button>
  );
}

UpdateCreditsAmountModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
