import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import React, { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { Switch, TextField } from "@mui/material";
import { EditChargeRequest, ManualPaymentCharge } from "api/v2/Admin/types";
import { toast } from "react-toastify";
import {
  convertFromPennies,
  convertToPennies,
  formatCurrency,
} from "utils/currency";
import CurrencyField from "components/FormElements/CurrencyField";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: {
    idValue: string;
    scheduleRef: string;
    manualPaymentCharge: ManualPaymentCharge & {
      isReady?: boolean;
    };
  };
};

type StringyNull = string | null;

type FormData = {
  paymentLabel: StringyNull;
  paymentPrice: StringyNull | number;
  paymentQuantity: StringyNull;
  paymentTotal: StringyNull;
  paymentFrequency: StringyNull;
  paymentStartDate: StringyNull;
  paymentEndDate: StringyNull;
  paymentStatus: StringyNull;
  paymentType: StringyNull;
  isActive: boolean;
  vatRate: StringyNull;
  paymentExceptions: StringyNull;
  manualPayments: StringyNull;
  scheduleStatus: StringyNull;
  password: string;
  paymentDiscount: StringyNull | number;
  vatAmount: StringyNull;
};

const EditChargeModal = ({ open, handleClose, data }: Props) => {
  const qc = useQueryClient();
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit, setValue, watch } = useForm<FormData>();
  const { mutate } = useMutation({
    mutationKey: ["create-manual-payment"],
    mutationFn: (_data: EditChargeRequest) =>
      ApiClient.admin.editCharge({
        ..._data,
      }),
    onSuccess: () => {
      toast.success("Charge edited successfully");
      qc.invalidateQueries(["charges", selectedUser, data.scheduleRef]);
      handleClose();
    },
    onError: (error: any) => {
      toast.error("Failed to edit charge");
    },
  });

  const onSubmit = async (_data: FormData) => {
    mutate({
      adminId: user?.payId ?? "",
      password: _data.password,
      idValue: data.idValue,
      scheduleRef: data.scheduleRef,
      ref: data.manualPaymentCharge.paymentId,
      paymentLabel: _data.paymentLabel,
      paymentPrice: _data.paymentPrice ?? 0,
      paymentQuantity: _data.paymentQuantity,
      value: _data.paymentTotal,
      paymentFrequency: "",
      paymentStartDate: "",
      paymentEndDate: "",
      paymentStatus: "",
      paymentType: "",
      isActive: Number(_data.isActive),
      vatRate: _data.vatRate,
      paymentExceptions: "",
      manualPayments: "",
      scheduleStatus: "",
      paymentDiscount: _data.paymentDiscount ?? 0,
    });
  };

  useEffect(() => {
    setValue("paymentLabel", data.manualPaymentCharge.paymentLabel);
    setValue("paymentPrice", data.manualPaymentCharge.paymentPrice);
    setValue("paymentQuantity", data.manualPaymentCharge.paymentQuantity);
    setValue("paymentTotal", data.manualPaymentCharge.paymentTotal);
    setValue("paymentDiscount", data.manualPaymentCharge.paymentDiscount);
    setValue("vatRate", data.manualPaymentCharge.vatRate);
    setValue("vatAmount", data.manualPaymentCharge.vatAmount);
    setValue("isActive", data.manualPaymentCharge.confirmedRef ? true : false);
  }, [open, data]);

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Edit payment charge"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <TextField label="Label" {...register("paymentLabel")} />
          <CurrencyField
            label="Price"
            prefix="£"
            decimalsLimit={10}
            defaultValue={convertFromPennies(
              data.manualPaymentCharge.paymentPrice ?? 0
            )}
            onValueChange={(value) => {
              setValue("paymentPrice", convertToPennies(value));
            }}
          />
          <TextField label="Quantity" {...register("paymentQuantity")} />
          <TextField
            disabled
            label="Total"
            value={
              watch("paymentPrice") && watch("paymentQuantity")
                ? formatCurrency(
                    (
                      parseFloat(watch("paymentPrice") as string) *
                      parseFloat(watch("paymentQuantity") as string)
                    ).toFixed(2)
                  )
                : "N/A"
            }
          />
          <CurrencyField
            label="Discount"
            prefix="£"
            decimalsLimit={10}
            defaultValue={convertFromPennies(
              data.manualPaymentCharge.paymentDiscount ?? 0
            )}
            onValueChange={(value) => {
              setValue("paymentDiscount", convertToPennies(value));
            }}
          />
          <TextField label="VAT rate %" {...register("vatRate")} />
          <TextField
            disabled
            label="VAT amount"
            value={
              watch("vatRate") && watch("paymentTotal")
                ? formatCurrency(
                    (
                      (parseFloat(watch("vatRate") as string) / 100) *
                      parseFloat(watch("paymentTotal") as string)
                    ).toFixed(2)
                  )
                : "N/A"
            }
          />
          <div className="flex items-center justify-center font-bold">
            Set as active
            <Switch
              checked={watch("isActive") || false}
              {...register("isActive")}
            />
          </div>
        </div>
        <hr />
        <TextField
          label="Confirm password"
          variant="outlined"
          type="password"
          required
          {...register("password")}
        />
        <div className="flex w-full gap-5">
          <Button
            type="button"
            className="w-full"
            variant="danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" className="w-full">
            Edit
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};

export default EditChargeModal;
