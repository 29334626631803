import MDBox from "components/ui/MDBox";
import PostTagLogo from "assets/images/posttag-logo.png";
import MDTypography from "components/ui/MDTypography";
import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

export default function Header({ title, backNavigation }) {
  return (
    <>
      <MDBox
        variant="gradient"
        bgColor="primary"
        borderRadius="lg"
        coloredShadow="info"
        className="flex flex-col gap-5"
        mx={2}
        mt={-3}
        p={2}
        mb={1}
        textAlign="center"
      >
        {backNavigation && (
          <Link
            className="text-white text-xl flex items-center gap-1 justify-start"
            to={backNavigation?.link}
          >
            <Icon icon="octicon:chevron-left-16" className="text-xl" />
            {backNavigation?.name}
          </Link>
        )}
        <MDTypography variant="h4" fontWeight="medium" color="white">
          {title}
        </MDTypography>
        <MDBox
          sx={{
            background: "white",
            borderRadius: "10px",
            width: "fit-content",
            padding: "10px 18px",
            margin: "auto",
          }}
        >
          <img
            src={PostTagLogo}
            className="h-[60px] mx-auto"
            alt="The PostTag Logo"
          />
        </MDBox>
      </MDBox>
    </>
  );
}
