import { ComponentPropsWithRef } from "react";
import { DayPicker } from "react-day-picker";
import "./style.css";
import "react-day-picker/dist/style.css";

type Props = ComponentPropsWithRef<typeof DayPicker>;

export default function Calendar({ ...props }: Props) {
  return <DayPicker {...props} />;
}
