import ApiClient from "api/v2/ApiClient";
import {
  ScheduledPayment,
  SubscriptionStatusCommand,
  UpdateSubscriptionRequest,
} from "api/v2/Payments/types";
import Button from "components/Button/Button";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { BadErrorResponse } from "types/NetworkTypes";
import { useAtom } from "jotai";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { ScheduledPaymentStatus } from "../ScheduledPayments";

type Props = {
  onFormSubmit: (data: any) => void;
  subscription: ScheduledPayment;
};

type SubscriptionStatus =
  typeof SubscriptionStatusCommand[keyof typeof SubscriptionStatusCommand];

export default function ScheduleStatusForm({
  onFormSubmit,
  subscription,
}: Props) {
  const qc = useQueryClient();
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { mutate: updateScheduleStatusMutation } = useMutation(
    (data: UpdateSubscriptionRequest) =>
      ApiClient.payments.updateSubscription(data),
    {
      onSuccess: (data) => {
        qc.invalidateQueries(["scheduled-payments", company?.PAYG_ID], {
          exact: true,
        });
        toast.success(data.message);
        onFormSubmit(data);
      },
      onError: (data: BadErrorResponse) => {
        toast.error(data.response.data.message);
      },
    }
  );
  const onStatusUpdate = async (status: SubscriptionStatus) => {
    updateScheduleStatusMutation({
      operation: status,
      paygId: company?.PAYG_ID ?? "",
      ref: subscription.scheduleRef,
    });
  };

  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-xl text-primary font-semibold">Settings</h2>
      <div className="flex sm:flex-row flex-col gap-2 w-full">
        <Button
          onClick={async () => {
            onStatusUpdate(
              subscription.posttagStatus === ScheduledPaymentStatus.ENABLED
                ? SubscriptionStatusCommand.DISABLE
                : SubscriptionStatusCommand.ENABLE
            );
          }}
          variant={
            subscription.posttagStatus === ScheduledPaymentStatus.ENABLED
              ? "warning"
              : "success"
          }
          className="flex items-center gap-2 w-full"
        >
          {subscription.posttagStatus === ScheduledPaymentStatus.ENABLED
            ? "Disable subscription"
            : "Enable subscription"}
        </Button>
        <Button
          onClick={async () => {
            onStatusUpdate(SubscriptionStatusCommand.DELETE);
          }}
          variant="danger"
          className="flex items-center gap-2 w-full"
        >
          Delete subscription
        </Button>
      </div>
    </div>
  );
}
