import ApiClient from "api/v2/ApiClient";
import { ScheduledPayment } from "api/v2/Payments/types";
import BaseCard from "components/Cards/BaseCard";
import { useState } from "react";
import { useQuery } from "react-query";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import useSelectedScheduledPayment from "./useSelectedScheduledPayment";
import ScheduledPaymentTab, {
  ScheduledPaymentPreview,
} from "./ScheduledPaymentTab";
import Shimmer from "components/Shimmer/Shimmer";

type Props = {
  company: SelectedCompanyAtomType;
  paygId: string;
};

export const ScheduledPaymentStatus = {
  ENABLED: "Enabled",
  DISABLED: "Disabled",
  PENDING: "Pending",
} as const;

export default function ScheduledPayments({ paygId, company }: Props) {
  const [selectedPayment, setSelectedPayment] =
    useState<ScheduledPayment | null>();

  const { data: scheduledPaymentsData, isLoading: isScheduledPaymentsLoading } =
    useQuery(["scheduled-payments", paygId], {
      queryFn: () => {
        return ApiClient.payments.getSchedules({ paygId: paygId });
      },
      select: (data) => {
        if (data.status !== 200) return [];
        return data.result;
      },
    });

  const {
    futurePayments: futurePaymentData,
    lookupSchedule: lookupDetailsData,
    pastPayments: pastPaymentsData,
    isLoading: isSelectedScheduledPaymentLoading,
  } = useSelectedScheduledPayment({
    paygId: paygId,
    selectedScheduledPayment: selectedPayment ?? null,
  });

  if (!scheduledPaymentsData || !scheduledPaymentsData.length) return null;

  return (
    <BaseCard
      title="Scheduled Payments - click to inspect"
      className="flex gap-10 flex-col"
    >
      <div className="w-full max-h-[250px] overflow-scroll overflow-x-hidden">
        {scheduledPaymentsData.map((payment, idx) => {
          return (
            <ScheduledPaymentPreview
              status={payment.posttagStatus}
              active={selectedPayment?.scheduleRef === payment.scheduleRef}
              amount={payment.paymentAmount}
              date={payment.startDate}
              key={payment.scheduleRef}
              onSelect={() => setSelectedPayment(payment)}
            />
          );
        })}
      </div>
      {selectedPayment ? (
        isSelectedScheduledPaymentLoading ? (
          <Shimmer height="h-[200px]" />
        ) : (
          <ScheduledPaymentTab
            schedule={{
              futurePayments: futurePaymentData?.result.futureRuns ?? [],
              information:
                scheduledPaymentsData.find(
                  (p) => selectedPayment?.scheduleRef === p.scheduleRef
                ) ?? null,
              lookupDetails: lookupDetailsData?.result ?? null,
              pastPayments: pastPaymentsData?.result.runs ?? [],
            }}
          />
        )
      ) : null}
    </BaseCard>
  );
}
