export const POSTTAG_ERRORS = {
  NO_TRANSACTIONS: "No Transactions Logged",
  ACCESS_TOKEN_EXPIRED: "Unauthorized: Token Expired",
  NO_DATA: "No Data Found",
  VERIFY_EMAIL: "Please Verify Email",
  LOOKUP_FAIL: "Postcode Lookup Failed",
  NOT_AUTHORIZED: "Not Authorized",
  NO_BILLING_INFORMATION: "No Billing Info",
  NO_SCHEDULES_FOUND: "No Schedules",
  SCHEDULE_PROCESSED: "Schedule processed successfully.",
};
