import BaseModal from "../BaseModal/BaseModal";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { toast } from "react-toastify";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UpdateUserFieldRequest } from "api/v2/Account/types";
import PostcodeLookup from "components/PostcodeLookup/PostcodeLookup";
import { useEffect } from "react";

type Props = {
  open: boolean;
  handleClose: () => void;
  user: {
    personFirstName: string;
    personSurname: string;
    address1: string;
    address2: string;
    address3: string;
    addressPostcode: string;
    personMobileNumber: string;
    companyName: string;
  };
};

export default function UpdateContactDetailsModal({
  open,
  handleClose,
  user,
}: Props) {
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const qc = useQueryClient();
  const { register, watch, handleSubmit, getValues, setValue } = useForm();
  const { mutate: updateMutation } = useMutation(
    (data: UpdateUserFieldRequest) =>
      ApiClient.account.updateUserField({
        paygId: company?.PAYG_ID ?? "",
        key: data.key,
        value: data.value,
      }),
    {
      onSuccess: () => {
        qc.invalidateQueries(["company-details", company], {
          exact: true,
        });
        toast.success("Contact details updated successfully");
      },
      onError: () => {
        qc.invalidateQueries(["company-details", company], {
          exact: true,
        });
      },
    }
  );

  const onSubmit = async () => {
    const data = getValues();
    const keys = Object.keys(data);
    const values = Object.values(data);
    const promises = [];
    for (let i = 0; i < keys.length; i++) {
      if (data[keys[i]] !== user[keys[i]]) {
        promises.push(updateMutation({ key: keys[i], value: values[i] }));
      }
    }
    await Promise.all(promises);
    handleClose();
  };

  useEffect(() => {
    setValue("personFirstName", user.personFirstName);
    setValue("personSurname", user.personSurname);
    setValue("CompanyName", user.companyName);
    setValue("address1", user.address1);
    setValue("address2", user.address2);
    setValue("address3", user.address3);
    setValue("addressPostcode", user.addressPostcode);
    setValue("personMobileNumber", user.personMobileNumber);
  }, [open]);

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Update Contact Information"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <TextField
            label="First Name"
            {...register("personFirstName")}
            id="personFirstName"
            variant="outlined"
          />
          <TextField
            label="Surname"
            {...register("personSurname")}
            id="personSurname"
            variant="outlined"
          />
          <div className="flex flex-col lg:col-span-2  gap-1">
            <TextField
              label="Company Name"
              {...register("CompanyName")}
              id="CompanyName"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col lg:col-span-2 gap-4">
            <div className="col-span-2">
              <PostcodeLookup
                onAddressLookup={(address) => {
                  setValue("address1", address?.["Address Line 1"]);
                  setValue("address2", address?.["Address Line 2"]);
                  setValue("address3", address?.["Address Line 3"]);
                  setValue("addressPostcode", address?.["Postcode"]);
                }}
              />
            </div>
            <TextField
              label="Address 1"
              {...register("address1")}
              id="address1"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col lg:col-span-2  gap-1">
            <TextField
              label="Address 2"
              {...register("address2")}
              id="address2"
              variant="outlined"
            />
          </div>
          <div className="flex flex-col lg:col-span-2  gap-1">
            <TextField
              label="Address 3"
              {...register("address3")}
              id="address3"
              variant="outlined"
            />
          </div>
          <TextField
            label="Postcode"
            {...register("addressPostcode")}
            id="addressPostcode"
            variant="outlined"
          />
          <TextField
            label="Mobile Number"
            {...register("personMobileNumber")}
            id="personMobileNumber"
            variant="outlined"
          />
        </div>
        <button
          type="submit"
          className="!text-white bg-primary py-3 px-8 w-fit rounded-lg lg:w-fit ml-auto self-end"
        >
          Save Changes
        </button>
      </form>
    </BaseModal>
  );
}
