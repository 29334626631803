import React from "react";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import TermsAndConditons from "assets/misc/terms-and-conditions.pdf";

export default function ShowTermsAndConditionsModal({ open, handleClose }) {
  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Terms and Conditions"
    >
      <div className="flex flex-col gap-3 text-sm">
        <p>
          Standard Terms and Conditions for use of the Portal These terms and
          conditions (“Terms”) apply to your use of PostTag’s Pay As You Go
          online account (“the Portal”) and you agree to be bound by these
          Terms. If you do not agree with these Terms then you should not use
          the Portal. Your continued use of the Portal shall constitute deemed
          acceptance of these Terms and confirmation by you that you agree to
          comply with them. We may amend these Terms at any time and such
          amendments shall become immediately effective upon the amended terms
          and conditions being posted on the Portal. Your use of the Portal
          following such posting shall be deemed as acceptance by you of the
          amended terms. In these Terms, where we use the expressions “we”, “us”
          or “our” we are referring to PostTag Group Limited (company number:
          08649785) and its successors and assigns.
        </p>
        <p>
          1. Interpretation 1.1 The definitions and rules of interpretation in
          this clause apply in these Terms. Account: an account set up by you
          for use of the Portal. API Credits: the right to use our API to verify
          a single address and provide location information in relation to that
          address. Business Day: a day other than a Saturday, Sunday or public
          holiday in England when banks in London are open for business. Data
          Protection Legislation:(a) to the extent the UK GDPR applies, the law
          of the United Kingdom or of a part of the United Kingdom which relates
          to the protection of Personal Data and (b) to the extent the EU GDPR
          applies, the law of the European Union or any member state of the
          European Union to which the Supplier is subject, which relates to the
          protection of Personal Data. End Results: has the meaning given in
          clause 9.1. EU GDPR: the General Data Protection Regulation (EU
          2016/679). Intellectual Property Rights: all patents, rights to
          inventions, utility models, copyright and related rights, trade marks,
          service marks, trade, business and domain names, rights in trade dress
          or get up, rights in goodwill or to sue for passing off, unfair
          competition rights, rights in designs, rights in computer software,
          database rights, topography rights, rights in confidential information
          (including know-how and trade secrets) and any other intellectual
          property rights, in each case whether registered or unregistered and
          including all applications for, and renewals or extensions of, such
          rights, and all similar or equivalent rights or forms of protection in
          any part of the world. Linked Sites: has the meaning given in clause
          5.1. Login Details: the username and password required to log in to
          your Account. Normal Business Hours: 9.00 am to 5.00 pm local UK time,
          each Business Day. Permitted Persons: you, your employees and your
          contractors. Personal Data: has the meaning given to it in the
          applicable Data Protection Legislation. Privacy Policy: our privacy
          policy at https://posttag.com/privacy-policy/, as adopted and varied
          from time to time. Purpose: has the meaning given in clause 2.1.
          Results: the address and/or location information provided by us to TP
          Sites following use of an API Credit and the API call. Supplier IPR:
          all Intellectual Property Rights either substituting in the Portal,
          the API Credits, the Results or otherwise necessary or desirable to
          enable you to access and use the Portal, the API Credits and the
          Results. TP Sites: has the meaning given in clause 4.2. UK GDPR: has
          the meaning given to it in the Data Protection Act 2018. Virus: any
          thing or device (including any software, code, file or programme)
          which may: (a) prevent, impair or otherwise adversely affect the
          operation of any computer software, hardware or network, any
          telecommunications service, equipment or network or any other service
          or device; (b) prevent, impair or otherwise adversely affect access to
          or the operation of any programme or data, including the reliability
          of any programme or data (whether by re-arranging, altering or erasing
          the programme or data in whole or part or otherwise); or (c) adversely
          affect the user experience, including worms, trojan horses, viruses
          and other similar things or devices, and the term Viruses shall be
          construed accordingly. Vulnerability: a weakness in the computational
          logic (for example, code) found in software and hardware components
          that when exploited, results in a negative impact to the
          confidentiality, integrity, or availability, and the term
          Vulnerabilities shall be construed accordingly.
        </p>
        <p>
          1.2 Clause headings shall not affect the interpretation of these
          Terms.
        </p>
        <p>
          1.3 A person includes an individual, corporate or unincorporated body
          (whether or not having separate legal personality) and that person's
          legal and personal representatives, successors or permitted assigns.
        </p>
        <p>
          1.4 A reference to a company shall include any company, corporation or
          other body corporate, wherever and however incorporated or
          established.
        </p>
        <p>
          1.5 Unless the context otherwise requires, words in the singular shall
          include the plural and in the plural shall include the singular.
        </p>
        <p>
          1.6 Unless the context otherwise requires, a reference to one gender
          shall include a reference to the other genders.
        </p>
        <p>
          1.7 A reference to a statute or statutory provision is a reference to
          it as amended, extended or re-enacted from time to time. A reference
          to a statute or statutory provision shall include all subordinate
          legislation made under that statute or statutory provision from time
          to time.
        </p>
        <p>
          1.8 A reference to writing or written includes email, but not faxes,
          social media or other electronic communication.
        </p>
        <p>1.9 References to clauses are to the clauses of these Terms.</p>
        <p>
          2. Portal Access 2.1 Upon setting up an Account, and subject to the
          restrictions in this clause and these Terms, we hereby grant you a
          non-exclusive, non-transferable right, without the right to grant
          sub-licences, to access and use the Portal for the sole purpose of you
          purchasing and using the API Credits for commercial use by Permitted
          Persons and for general account management in relation to the purchase
          and use of such API Credits (“Purpose”).
        </p>
        <p>
          2.2 The rights provided in this clause 2 are granted to you only, and
          shall not be granted to any subsidiary or holding company of yours or
          to any other person or entity.
        </p>
        <p>
          2.3 We may update or change the Portal from time to time. We will try
          and give you reasonable notice of any major changes made.
        </p>
        <p>
          2.4 Upon setting up an Account, you will be provided with Login
          Details. You must treat the Login Details as confidential and you must
          not disclose your Login Details to any person or otherwise allow any
          person to access the Portal using your Login Details.
        </p>
        <p>
          2.5 You must not use the Portal, the API Credits or the Results for
          any unlawful purpose or for any purpose which is prohibited by these
          Terms.
        </p>
        <p>
          2.6 We reserve the right to terminate your access to and use of the
          Portal if you breach any of these Terms. Such termination will result
          in a loss of any purchased but unused API Credits.
        </p>
        <p>
          3. Availability and Use of the Portal 3.1 Upon you setting up an
          Account, we shall use our reasonable endeavours to ensure you have
          access to the Portal for the Purpose. We shall not be liable to you,
          or any third party, if you are unable to access the Portal at any time
          for any reason.
        </p>
        <p>
          3.2 We shall use commercially reasonable endeavours to make the Portal
          available 24 hours a day, seven days a week, except for: (a) planned
          maintenance carried out from time to time; and (b) unscheduled
          maintenance performed outside Normal Business Hours, provided that we
          have used reasonable endeavours to give you reasonable notice in
          advance.
        </p>
        <p>
          3.3 We do not guarantee that the Portal will be secure or free from
          Viruses or Vulnerabilities. You are responsible for ensuring that your
          access to the Portal does not expose you to any Virus or Vulnerability
          which may damage your computer system. We do not accept any
          responsibility for any interference or damage to your computer system
          which arises as a result of or in connection with your use of the
          Portal.
        </p>
        <p>
          3.4 We: (a) do not warrant that: (i) your use of the Portal will be
          uninterrupted or error-free; or (ii) that the Portal and any
          information obtained by you through the Portal will meet your
          requirements. (b) are not responsible for any delays, delivery
          failures, or any other loss or damage resulting from the transfer of
          data over communications networks and facilities, including the
          internet, and you acknowledge that the Portal may be subject to
          limitations, delays and other problems inherent in the use of such
          communications facilities.
        </p>
        <p>
          3.5 We warrant that we have and will maintain all necessary licences,
          consents, and permissions necessary for the performance of our
          obligations under these Terms.
        </p>
        <p>
          3.6 We warrant that we will comply with all applicable laws and
          regulations with respect to our activities under these Terms.
        </p>
        <p>
          3.7 We shall comply with our own privacy and security polices as
          adopted and varied from time to time.
        </p>
        <p>
          3.8 You shall not access, store, distribute or transmit any Viruses,
          or any material during the course of your use of and access to the
          Portal that: (a) is unlawful, harmful, threatening, defamatory,
          obscene, infringing, harassing or racially or ethnically offensive;
          (b) facilitates illegal activity; (c) depicts sexually explicit
          images; (d) promotes unlawful violence; (e) is discriminatory based on
          race, gender, colour, religious belief, sexual orientation,
          disability; (f) is otherwise illegal or causes damage or injury to any
          person or property; or (g) would, by the use or analysis, result in a
          breach of your obligations under these Terms, and we reserve the
          right, without liability or prejudice to our other rights to you, to
          disable or terminate your password, Account and/or access to all or
          part of the Portal. Such termination will result in a loss of any
          purchased but unused API Credits.
        </p>
        <p>
          3.9 You shall not: (a) except as may be allowed by any applicable law
          which is incapable of exclusion by agreement between the parties and
          except to the extent expressly permitted under these Terms: (i)
          attempt to copy, modify, duplicate, create derivative works from,
          frame, mirror, republish, download, display, transmit, or distribute
          all or any portion of the Portal, the Results and/or the Supplier’s
          IPR (as applicable) in any form or media or by any means; or (ii)
          attempt to de-compile, reverse compile, disassemble, reverse engineer
          or otherwise reduce to human-perceivable form all or any part of the
          Portal or the Results; or (b) access all or any part of the Portal or
          the Results in order to build a product or service which competes with
          the Portal or the Results; or (c) without our written consent,
          license, sell, rent, lease, transfer, assign, distribute, display,
          disclose, or otherwise commercially exploit, or otherwise make the
          Portal or the Results available to any third party; or (d) attempt to
          obtain, or assist third parties in obtaining, access to the Portal or
          the Results; or (e) introduce or permit the introduction of, any Virus
          or Vulnerability into our network and information systems.
        </p>
        <p>
          3.10 You shall use all reasonable endeavours to prevent any
          unauthorised access to, or use of, the Portal and the Results and, in
          the event of any such unauthorised access or use, promptly notify us.
        </p>
        <p>
          4. Use of the API Credits 4.1 You agree and acknowledge that your use
          of the Portal is solely for the Purpose.
        </p>
        <p>
          4.2 Once you have accessed the Portal and purchased API Credits, such
          credits may be used on third party websites and software (“TP Sites”).
          Use of the API Credits on TP Sites shall result in us delivering the
          Results to such TP Sites, however we have no control over such
          websites or software, nor the service that they provide.
        </p>
        <p>
          4.3 Your use of TP Sites is at your own risk and subject to any terms
          and conditions and policies as provided by such third party. You agree
          that we shall have no liability to you for any information obtained
          from such websites and/or software or the services provided to you by
          them. 4.4 We shall not be liable for any loss or damage incurred as a
          result of: (a) the operation, availability and accessibility of the TP
          Sites; (b) the content, information, data and/or material provided on
          TP Sites; or (c) the services provided on the TP Sites including the
          qualify, efficiency, reliability and lawfulness of such services.
        </p>
        <p>
          5. Third Party Websites 5.1 The Portal may direct you or provide a
          link to websites hosted and provided by third parties (“Linked
          Sites”). These links are provided for your use, information and/or
          convenience only and should not be interpreted as approval or
          recommendation by us of the Linked Sites or the information or
          services you may obtain from them.
        </p>
        <p>
          5.2 We have no control over, and do not monitor, the contents of
          Linked Sites and are not responsible for the content or privacy
          practices associated with the Linked Sites. Your use of the links to
          Linked Sites is at your own risk. You agree that we shall have no
          liability to you for any information obtained from Linked Sites,
          services provided on such Linked Sites or for any materials or
          software found at Linked Sites.
        </p>
        <p>
          6. Data protection 6.1 We will only use your personal information as
          set out in our Privacy Policy.
        </p>
        <p>
          6.2 You will comply with all applicable requirements of the Data
          Protection Legislation. This clause 6 is in addition to, and does not
          relieve, remove or replace, a party’s obligations under the Data
          Protection Legislation.
        </p>
        <p>
          7. Your obligations 7.1 You shall: (a) comply with all applicable laws
          and regulations with respect to your activities under these Terms; (b)
          ensure that the Portal is compatible with your software and IT system;
          (c) ensure that the API Credits can be used on the TP sites which you
          intend to use; (d) ensure that your network and systems comply with
          the relevant specifications provided by us from time to time; (e)
          comply with our reasonable privacy and security polices (including the
          Privacy Policy) as adopted and varied by us from time to time; and (f)
          be, to the extent permitted by law and except as otherwise expressly
          provided in these Terms, solely responsible for procuring, maintaining
          and securing your network connections and telecommunications links
          from your systems to our data centres, and all problems, conditions,
          delays, delivery failures and all other loss or damage arising from or
          relating to your network connections or telecommunications links or
          caused by the internet.
        </p>
        <p>
          8. Intellectual Property 8.1 You acknowledge and agree that we and/or
          our licensors own all Intellectual Property Rights in and to the
          Portal, the API Credits, the Results and all other software, materials
          and works created or developed in the course of the provision of
          access to and use of the Portal, the API Credits and/or the Results.
          Except as expressly stated herein, these Terms do not grant you any
          rights to, under or in, any patents, copyright, database right, trade
          secrets, trade names, trade marks (whether registered or
          unregistered), or any other rights or licences in respect of the
          Portal, the API Credits, the Results or all other software, materials
          and works created or developed in the course of the provision of
          access to and use of the Portal, the API Credits and/or the Results.
        </p>
        <p>
          8.2 We confirm that we have all the rights in relation to the Portal,
          the API Credits and the Results that are necessary to grant all the
          rights we purport to grant under, and in accordance with, these Terms.
        </p>
        <p>
          8.3 To the extent that the Supplier IPR does not automatically vest in
          us but is owned by you, you hereby irrevocably assign the same to us
          by way of a present and (where possible) future assignment with full
          title guarantee. You shall do or procure to be done all such things
          and execute or procure the execution of all such documents which may
          be necessary or desirable to vest such rights in us absolutely.
        </p>
        <p>
          8.4 You shall keep us indemnified in full against any sums awarded by
          a court against us as a result or in connection with any claim brought
          against us for infringement of a third party’s rights (including any
          Intellectual Property Rights) arising out of, or in connection with,
          the use of data inputted by you during your use of the Portal, the API
          Credits or the Results.
        </p>
        <p>
          9. Limitation of liability 9.1 Except as expressly and specifically
          provided in these Terms: (a) you assume sole responsibility for all
          information, notifications, results, data or disclosures delivered
          (including automated delivery) (End Results) from your use of the
          Portal, the API Credits and the Results, and we hereby to the fullest
          extent permissible under applicable law expressly disclaim any and all
          responsibility and liability to you in respect of such End Results,
          the conclusions drawn from the End Results, reliance on the End
          Results or any steps, measures or actions taken or omitted to be taken
          in relation to such End Results; (b) we shall have no liability for
          any damage caused by errors or omissions in any information,
          instructions or scripts provided to us by you in connection with your
          use of the Portal, the API Credits or the Results, or any actions
          taken by us at your direction; (c) all warranties, representations,
          conditions and all other terms of any kind whatsoever implied by
          statute or common law are, to the fullest extent permitted by
          applicable law, excluded from these Terms; and (d) the Portal, the API
          Credits and the Results are provided to you on an "as is" basis.
        </p>
        <p>
          9.2 Nothing in these Terms excludes our liability for: (a) death or
          personal injury caused by our negligence; or (b) fraud or fraudulent
          misrepresentation.
        </p>
        <p>
          9.3 Subject to clause 9.2: (a) we shall not be liable whether in tort
          (including for negligence or breach of statutory duty), contract,
          misrepresentation, restitution or otherwise for any loss of profits,
          loss of sales, loss of business, depletion of goodwill and/or similar
          losses or loss or corruption of data or information, or pure economic
          loss, or for any special, indirect or consequential loss, costs,
          damages, charges or expenses however arising under these Terms; and
          (b) our total aggregate liability in contract, tort (including
          negligence or breach of statutory duty), misrepresentation,
          restitution or otherwise, arising in connection with the performance
          or contemplated performance of this agreement shall be limited to the
          total API Credits purchased by you on the Portal during the 6 months
          immediately preceding the date on which the claim arose.
        </p>
        <p>
          9.4 Nothing in these Terms excludes your liability for any breach,
          infringement or misappropriation of the Supplier IPR.
        </p>
        <p>
          10. Refund Policy 10.1 All purchases of API Credits on the Portal
          shall not be refundable under any circumstances, including but not
          limited to, the termination of your access to or use of the Portal.
          10.2 Any API Credits not used within 12 months of the date of purchase
          shall expire and shall no longer be capable of being used.
        </p>
        <p>
          11. Waiver No failure or delay by either of us to exercise any right
          or remedy provided under these Terms or by law shall constitute a
          waiver of that or any other right or remedy, nor shall it prevent or
          restrict the further exercise of that or any other right or remedy. No
          single or partial exercise of such right or remedy shall prevent or
          restrict the further exercise of that or any other right or remedy.
        </p>
        <p>
          12. Rights and remedies Except as expressly provided in these Terms,
          the rights and remedies provided under these Terms are in addition to,
          and not exclusive of, any rights or remedies provided by law.
        </p>
        <p>
          13. Severance If any provision or part-provision of these Terms is or
          becomes invalid, illegal or unenforceable, it shall be deemed deleted,
          but that shall not affect the validity and enforceability of the rest
          of these Terms.
        </p>
        <p>
          14. Misrepresentation Subject to clause 9.2, each party acknowledges
          that in entering into these Terms it does not rely on, and shall have
          no remedies in respect of, any statement, representation, assurance or
          warranty (whether made innocently or negligently) that is not set out
          in this agreement. Each party agrees that it shall have no claim for
          innocent or negligent misrepresentation or negligent misstatement
          based on any statement in this agreement.
        </p>
        <p>
          15. Assignment 15.1 We may at any time assign, transfer, charge,
          sub-contract or deal in any other manner with all or any of our rights
          or obligations under these Terms. 15.2 You may not, without our prior
          written consent, assign, transfer, charge, sub-contract or deal in any
          other manner with all or any of your rights or obligations under these
          Terms.
        </p>
        <p>
          16. No partnership or agency Nothing in these Terms is intended to or
          shall operate to create a partnership between the parties, or
          authorise either party to act as agent for the other, and neither
          party shall have the authority to act in the name or on behalf of or
          otherwise to bind the other in any way (including, but not limited to,
          the making of any representation or warranty, the assumption of any
          obligation or liability and the exercise of any right or power).
        </p>
        <p>
          17. Third party rights These Terms do not confer any rights on any
          person or party (other than you and us and, where applicable, the
          successors and permitted assigns) pursuant to the Contracts (Rights of
          Third Parties) Act 1999.
        </p>
        <p>
          18. Notices 18.1 Any notice required to be given to us under these
          Terms shall be in writing. Such notice shall be delivered by hand or
          sent by pre-paid first-class post or recorded delivery post to our
          registered office address, or such other address as may have been
          notified by us to you for such purposes, or sent by email to the email
          address provided to you for such purposes.
        </p>
        <p>
          18.2 Any notice required to be given to you under these Terms shall be
          in writing and delivered by hand or sent by pre-paid first-class post
          or recorded delivery post to you at the address registered to the
          Account, or such other address as may have been notified by you to us
          for such purposes, or sent by email to your email address, as
          registered to the Account.
        </p>
        <p>
          18.3 A notice delivered by hand shall be deemed to have been received
          when delivered (or if delivery is not in business hours, at 9am on the
          first Business Day following delivery). A correctly addressed notice
          sent by pre-paid first-class post or recorded delivery post shall be
          deemed to have been received at the time at which it would have been
          delivered in the normal course of post. A notice sent by email shall
          be deemed to have been received at the time of transmission.
        </p>
        <p>
          18.4 This clause 18 does not apply to the service of proceedings or
          other documents in any legal action.
        </p>
        <p>
          19. Governing law These Terms and any dispute or claim arising out of
          or in connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed
          in accordance with the law of England and Wales.
        </p>
        <p>
          20. Jurisdiction It is hereby irrevocably agreed that the courts of
          England and Wales shall have exclusive jurisdiction to settle any
          dispute or claim arising out of or in connection with these Terms or
          its subject matter or formation (including non-contractual disputes or
          claims).
        </p>
      </div>
    </BaseModal>
  );
}
