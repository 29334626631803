import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ManualUserCreationRequest } from "api/v2/Admin/types";
import ApiClient from "api/v2/ApiClient";
import Button from "components/Button/Button";
import BaseCard from "components/Cards/BaseCard";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { authState } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";

type FormData = ManualUserCreationRequest;

type UserType = Pick<FormData, "type">["type"];

const AdminSignup = () => {
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, setValue, watch, handleSubmit, reset, resetField } =
    useForm<FormData>();
  const { mutate } = useMutation({
    mutationFn: (data: FormData) =>
      ApiClient.admin.manualUserCreation({
        ...data,
        adminId: user.payId ?? "",
      }),
    onSuccess: () => {
      toast.success("User created successfully");
      reset();
    },
    onError: (error: any) => {
      toast.error("Failed to create user");
    },
  });

  const onSubmit = async (data: FormData) => {
    mutate(data);
  };

  return (
    <BaseCard title="Create New User">
      <form
        action=""
        className="w-full flex flex-col gap-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2">
          <TextField label="Title" {...register("personTitle")} />
          <FormControl>
            <InputLabel className="h-full">User Type</InputLabel>
            <Select
              className="h-full"
              value={watch("type") || ""}
              label="User Type"
              onChange={(e) => setValue("type", e.target.value as UserType)}
            >
              <MenuItem value={"CreditUser"}>Credit User</MenuItem>
              <MenuItem value={"PluginUser"}>Plugin User</MenuItem>
              <MenuItem value={"SubscriptionUser"}>Subscription User</MenuItem>
              <MenuItem value={"Operator"}>Operator</MenuItem>
              <MenuItem value={"Admin"}>Admin</MenuItem>
            </Select>
          </FormControl>

          <TextField label="Client ID" {...register("clientId")} />
          <FormControl>
            <InputLabel className="h-full">Operator User Type</InputLabel>
            <Select
              className="h-full"
              value={watch("operatorUserType") || ""}
              label="Operator User Type"
              onChange={(e) =>
                setValue(
                  "operatorUserType",
                  e.target.value as "CreditUser" | "SubscriptionUser"
                )
              }
            >
              <MenuItem value={"CreditUser"}>Credit User</MenuItem>
              <MenuItem value={"SubscriptionUser"}>Subscription User</MenuItem>
            </Select>
          </FormControl>

          <TextField label="Email" {...register("personEmail")} />
          <TextField label="First Name" {...register("personFirstName")} />
          <TextField label="Last Name" {...register("personSurname")} />
          <TextField label="Password" {...register("userPassword")} />
          <TextField
            label="Mobile Number"
            {...register("personMobileNumber")}
          />
          <TextField label="Company Name" {...register("companyName")} />
          <TextField
            label="Address Property Name"
            {...register("addressPropertyName")}
          />
          <TextField label="Address One" {...register("addressOne")} />
          <TextField label="Address Two" {...register("addressTwo")} />
          <TextField label="Address Three" {...register("addressThree")} />
          <TextField label="Town" {...register("addressTown")} />
          <TextField label="County" {...register("addressCounty")} />
          <TextField label="Postcode" {...register("addressPostcode")} />
        </div>

        <div className="flex items-center gap-5 justify-end">
          <TextField
            className="w-1/3"
            type="password"
            label="Confirm Admin Password"
            {...register("password")}
          />
          <Button className="w-fit self-end" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </BaseCard>
  );
};

export default AdminSignup;
