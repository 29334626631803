import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: {
    scheduleRef: string;
  };
};

type FormData = {
  password: string;
};

const ConfirmPendingChargesModal = ({ open, handleClose, data }: Props) => {
  const qc = useQueryClient();
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit } = useForm<FormData>();
  const { mutate } = useMutation({
    mutationKey: ["confirm-charges"],
    mutationFn: (_data: { password: string }) =>
      ApiClient.admin.confirmCharges({
        adminId: user?.payId ?? "",
        password: _data.password,
        scheduleRef: data.scheduleRef,
      }),
    onSuccess: () => {
      toast.success("Charges confirmed");
      qc.invalidateQueries(["charges", selectedUser, data.scheduleRef]);
      handleClose();
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = async (_data: FormData) => {
    mutate({
      password: _data.password,
    });
  };

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Confirm pending charges"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <span className="text-center font-bold">
          To confirm pending charges please enter your password
        </span>
        <TextField
          label="Confirm password"
          variant="outlined"
          type="password"
          required
          {...register("password")}
        />
        <div className="flex w-full gap-5">
          <Button
            type="button"
            className="w-full"
            variant="danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" className="w-full">
            Confirm charges
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};

export default ConfirmPendingChargesModal;
