import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      type: null,
      clientId: null,
      email: null,
      payId: null,
      pages: [],
      notice: null,
    },
    isLoggedIn: false,
  },
  reducers: {
    logIn: (state, action) => {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.user = {
        type: null,
        clientId: null,
        email: null,
        payId: null,
        pages: [],
        notice: null,
      };
      state.isLoggedIn = false;
    },
    dismissNotice: (state) => {
      state.user.notice = null;
    },
  },
});

export const { logIn, logOut, dismissNotice } = authSlice.actions;
export const authState = (state) => state.auth;

export default authSlice.reducer;
