import Button from "components/Button/Button";
import { useDispatch } from "react-redux";
import { logOut } from "store/authSlice";
import ApiClient from "api/v2/ApiClient";
import { toast } from "react-toastify";
import { useIdleTimer } from "react-idle-timer";

type Props = {
  timeRemaining: number;
  onStayLoggedIn: () => void;
};

export default function InactivePopup({
  timeRemaining,
  onStayLoggedIn,
}: Props) {
  const dispatch = useDispatch();

  const logoutUser = async () => {
    await ApiClient.auth
      .logout()
      .then(() => {
        dispatch(logOut());
        window.localStorage.removeItem("access");
        window.localStorage.removeItem("refresh");
        window.location.href = "/login";
        toast.success("Logged out successfully");
      })
      .catch(() => {
        toast.error("Failed to logout");
      });
  };

  return (
    <>
      <div className="fixed !z-50 inset-0 min-h-screen w-full flex items-center justify-center">
        <div className="bg-white text-center flex gap-5 flex-col lg:max-w-[50vw] max-w-full mx-5 opacity-100 rounded-lg p-10">
          <h1 className="font-bold text-5xl">Your account has been inactive</h1>
          <p className="text-xl">
            You've been inactive for a while. For your security, we'll log you
            out soon. If you want to stay logged in, click the button below.
          </p>
          <p>
            Automatic logout in{" "}
            <span className="font-bold">{timeRemaining}</span> seconds
          </p>
          <div className="flex gap-5">
            <Button
              className="w-full"
              onClick={() => {
                onStayLoggedIn();
              }}
            >
              Stay logged in
            </Button>
            <Button
              className="w-full"
              onClick={() => {
                logoutUser();
              }}
            >
              Log out
            </Button>
          </div>
        </div>
      </div>
      <div className="fixed z-10 inset-0 min-h-screen w-full bg-black opacity-70 flex items-center justify-center"></div>
    </>
  );
}
