import { AxiosInstance } from "axios";
import { LoginResponse, RegisterRequest } from "./types";
import { GenericResponse } from "types/GenericResponse";

export default class AuthApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async logout() {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "logout",
    });
    return result.data;
  }

  async login({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<LoginResponse> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "login",
      idValue: email,
      password: password,
    });
    return result.data;
  }

  async register({ data }: { data: RegisterRequest }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "signup",
      termsConditions: 1,
      type: data.type,
      clientId: data.clientId,
      companyName: data.company,
      personEmail: data.email,
      password: data.password,
      addressPropertyName: data.propertyName,
      address1: data.addressLine1,
      address2: data.addressLine2,
      address3: data.addressLine3,
      addressTown: data.town,
      addressCounty: "",
      memorableWord: "",
      addressPostcode: data.postcode,
      personFirstName: data.firstName,
      personSurname: data.surname,
      personMobileNumber: data.telephoneNumber,
      personTitle: data.title,
    });
    return result.data;
  }

  async sendEmailVerification({ email }: { email: string }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "emailVerification",
      idValue: email,
    });
    return result.data;
  }

  async forgotPasswordReset({ email }: { email: string }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "sendPasswordReset",
      idValue: email,
    });
    return result.data;
  }

  async verifyEmailCode({
    email,
    code,
  }: {
    email: string;
    code: string;
  }): Promise<GenericResponse> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "verifyCode",
      idValue: email,
      code: code,
    });
    return result.data;
  }

  async passwordReset({
    email,
    code,
    password,
  }: {
    email: string;
    code: string;
    password: string;
  }): Promise<GenericResponse> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "resetPassword",
      idValue: email,
      code: code,
      password: password,
    });
    return result.data;
  }

  async sendPasswordResetCode({
    email,
  }: {
    email: string;
  }): Promise<GenericResponse> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "sendPasswordReset",
      idValue: email,
    });
    return result.data;
  }

  async addressLookUp({
    input,
  }: {
    input: string;
  }): Promise<GenericResponse<{ Address: string; Idx: string }[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "postcodeLookup",
      postcode: input,
    });
    return result.data;
  }
}
