import { Card } from "@mui/material";

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export default function BaseCard({ title, children, className = "" }: Props) {
  return (
    <Card className="p-4">
      <h1 className="font-bold text-secondary pb-8 text-lg">{title}</h1>
      <section className={className}>{children}</section>
    </Card>
  );
}
