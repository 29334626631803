import { useNavigate, useSearchParams } from "react-router-dom";
import MDBox from "components/ui/MDBox";
import AuthLayout from "layouts/AuthLayout";
import bgImage from "assets/images/doors.png";
import { FormProvider, useForm } from "react-hook-form";
import ApiClient from "api/v2/ApiClient";
import { schema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-toastify";
import { useState } from "react";
import UserInformationView from "./components/UserInformationView";
import Header from "../components/Header";
import { useAtom, atom } from "jotai";
import AccountView from "./components/AccountView";

import Footer from "../components/Footer";
import { ROLES } from "utils/constants";

export const registrationInfoAtom = atom(null);

function Basic() {
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const [formStep, setFormStep] = useState(0);
  const registerForm = useForm({
    resolver: zodResolver(schema),
  });

  const onFormSubmit = async (data) => {
    await ApiClient.auth
      .register({
        data: {
          ...data,
          type: registerForm.getValues("referral")
            ? ROLES.SUBSCRIPTION_USER
            : registerForm.getValues("type"),
          clientId: registerForm.getValues("referral")
            ? registerForm.getValues("referral")
            : 3000,
        },
      })
      .then(() => {
        toast.success("Successfully registered");
        setRegistrationInfo(data.email);
        navigate("/verify-email");
      })
      .catch((e) => {
        if (e.response?.data?.message && e.response?.data.status === 400) {
          toast.error(e.response?.data?.message);
          return;
        }
        toast.error("Invalid data");
      });
  };

  return (
    <AuthLayout image={bgImage}>
      <div className="bg-white w-full max-w-[500px] z-10 mt-14">
        <Header
          backNavigation={
            !searchParams.get("referral")
              ? {
                  name: "Back",
                  link: "/login",
                }
              : null
          }
          title="Register new account"
        />
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            onSubmit={registerForm.handleSubmit(onFormSubmit)}
            component="form"
            role="form"
          >
            <FormProvider {...registerForm}>
              {formStep === 0 && (
                <AccountView onNext={() => setFormStep(formStep + 1)} />
              )}
              {formStep === 1 && (
                <UserInformationView onNext={() => setFormStep(formStep + 1)} />
              )}
            </FormProvider>
          </MDBox>
          <Footer />
        </MDBox>
      </div>
    </AuthLayout>
  );
}

export default Basic;
