import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ApiClient from "api/v2/ApiClient";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import dayjs from "dayjs";
import MDDatePicker from "components/ui/MDDatePicker";
import { useEffect } from "react";
import BaseCard from "components/Cards/BaseCard";
import { Link } from "react-router-dom";
import TransactionsTable from "./TransactionsTable";
import { TRANSACTION_STATUS } from "utils/constants";
import { NumberFormatter } from "utils/helpers";

export default function Transactions() {
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    status: "APPROVED",
  });
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const [transactionRows, setTransactionRows] = React.useState([]);
  const { data: transactionsData, isLoading } = useQuery(
    ["transactions", company],
    {
      queryFn: () =>
        ApiClient.payments.getTransactions({
          paygId: company?.PAYG_ID,
        }),
      enabled: !!company,
      onSuccess: (data) => {
        setTransactionRows(
          data.sort((a, z) => {
            return dayjs(a.transactionTime).isBefore(dayjs(z.transactionTime));
          })
        );
      },
      select: (data) => {
        if (data.status !== 200) return [];
        return data.result
          .filter((res) => {
            if (res.purchaseType === "SD" && res.TotalWithVAT === "£1.00")
              return false;
            return true;
          })
          .map((transaction) => {
            return {
              ...transaction,
              ref: transaction.ref,
              credits: NumberFormatter(transaction.credits),
              date: dayjs(transaction.TransactionTime).format(
                "DD/MM/YY HH:mm:ss"
              ),
              totalExcVat: transaction.TotalWithoutVAT,
              total: transaction.TotalWithVAT,
              purchaseType: transaction.purchaseTypeValue,
              invoice:
                transaction.transactionStatus === TRANSACTION_STATUS.APPROVED &&
                transaction.purchaseType !== "CC" ? (
                  <Link
                    className="font-bold"
                    to={`/account/invoices/${transaction.ref}`}
                  >
                    View
                  </Link>
                ) : (
                  "N/A"
                ),
            };
          });
      },
    }
  );

  useEffect(() => {
    if (!transactionsData || !transactionsData.length > 0) return;
    setTransactionRows(() => {
      return transactionsData
        ?.filter((row) => {
          if (!row.transactionStatus) return;
          if (filters.status === "APPROVED")
            return row.transactionStatus.toUpperCase() === "APPROVED";
          if (filters.status === "REJECTED")
            return row.transactionStatus.toUpperCase() === "REJECTED";
          return row;
        })
        .filter((row) => {
          if (!filters.startDate || !filters.endDate) return row;
          return (
            dayjs(row.transactionTime).isBefore(dayjs(filters.endDate)) &&
            dayjs(row.transactionTime).isAfter(dayjs(filters.startDate))
          );
        })
        .sort((a, z) => {
          return dayjs(a.transactionTime).isBefore(dayjs(z.transactionTime));
        });
    });
  }, [filters]);

  return (
    <BaseCard title="Transactions">
      <div className="flex gap-2">
        <div className="ml-auto flex items-center justify-end gap-2 w-full">
          <Select
            className="py-3 text-right px-3 w-fit"
            value={filters.status}
            onChange={(e) =>
              setFilters((prev) => {
                return {
                  ...prev,
                  status: e.target.value,
                };
              })
            }
          >
            <MenuItem value={"ALL"}>All</MenuItem>
            <MenuItem value={"APPROVED"}>Approved</MenuItem>
            <MenuItem value={"REJECTED"}>Rejected</MenuItem>
          </Select>
        </div>
        <div className="w-fit self-end">
          <MDDatePicker
            sx={{ width: "100%" }}
            from={{
              label: [],
              input: { placeholder: "From Date" },
              options: {
                mode: "single",
                dateFormat: "Y-m-d",
                defaultDate: [dayjs(filters.startDate).format("YYYY-MM-DD")],
              },
              onChange: (date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    startDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              },
            }}
            to={{
              label: [],
              input: { placeholder: "To Date" },
              options: {
                mode: "single",
                dateFormat: "Y-m-d",
                defaultDate: [dayjs(filters.endDate).format("YYYY-MM-DD")],
              },
              onChange: (date) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    endDate: dayjs(date).format("YYYY-MM-DD"),
                  };
                });
              },
            }}
          />
        </div>
      </div>
      {transactionRows && transactionRows.length > 0 ? (
        <TransactionsTable transactionRowsData={transactionRows} />
      ) : (
        <div className="w-full flex justify-center items-center">
          <h2 className="text-primary py-10 font-semibold text-xl">
            No Purchases Made
          </h2>
        </div>
      )}
    </BaseCard>
  );
}
