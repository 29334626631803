import { TotalFiltersRequest } from "api/v2/Stats/types";

export const formatGranularity = (
  granularity: string
): TotalFiltersRequest["granularity"] => {
  if (granularity === "hourly") return "Hour";
  if (granularity === "daily") return "Day";
  if (granularity === "weekly") return "Week";
  if (granularity === "monthly") return "Month";
  return "Month";
};
