import { Text, View } from "@react-pdf/renderer";
import { InvoiceStylesheet } from "./useStyles";

type Props = {
  styles: InvoiceStylesheet;
};

export default function InvoiceTableHeader({ styles }: Props) {
  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        fontSize: 8,
      }}
    >
      <View style={[styles.theader]}>
        <Text>DESCRIPTION</Text>
      </View>
      <View style={styles.theader}>
        <Text>QUANTITY</Text>
      </View>
      <View style={styles.theader}>
        <Text>PRICE (EXCL. VAT)</Text>
      </View>
      <View style={styles.theader}>
        <Text>VAT</Text>
      </View>
      <View style={styles.theader}>
        <Text>Total (Incl. VAT)</Text>
      </View>
    </View>
  );
}
