import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import MDInput from "components/ui/MDInput";
import MDButton from "components/ui/MDButton";
import AuthLayout from "layouts/AuthLayout";
import bgImage from "assets/images/doors.png";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { logIn } from "store/authSlice";
import dayjs from "dayjs";
import { setStorage } from "utils/storage";
import Header from "../components/Header";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { registrationInfoAtom } from "../Register/Register";
import { POSTTAG_ERRORS } from "utils/errors";
import Footer from "../components/Footer";
import ApiClient from "api/v2/ApiClient";
import { useMutation, useQueryClient } from "react-query";

function Basic() {
  const qc = useQueryClient();
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, getValues } = useForm();
  const { mutate: loginMutation } = useMutation(
    (data) => ApiClient.auth.login(data),
    {
      onSuccess: (res) => {
        qc.clear();
        dispatch(
          logIn({
            email: res.result.personEmail,
            payId: res.result.PAYG_ID,
            clientId: res.result.ClientID,
            type: res.result.Type,
            pages: res.result.Pages.split(","),
            notice: res?.notice ?? null,
          })
        );
        setStorage("access", res.execute_token);
        setStorage("refresh", res.refresh_token);
        navigate(
          `/overview?from=${dayjs()
            .subtract(1, "month")
            .format("YYYY-MM-DD")}&to=${dayjs()
            .add(1, "day")
            .format("YYYY-MM-DD")}&granularity=monthly`
        );
      },
      onError: (err) => {
        if (
          err.response.status === 400 &&
          err.response.data.result === POSTTAG_ERRORS.VERIFY_EMAIL
        ) {
          setRegistrationInfo(getValues("email"));
          navigate("/verify-email");
          toast.warning("Please verify your email");
          return;
        }
        toast.error("Error User not found");
      },
    }
  );

  const onFormSubmit = async (data) => {
    loginMutation(data);
  };

  return (
    <AuthLayout image={bgImage}>
      <Card className="max-w-[500px] w-full">
        <Header title={"Sign in"} />
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            onSubmit={handleSubmit(onFormSubmit)}
            component="form"
            role="form"
          >
            <MDBox mb={2}>
              <MDInput
                {...register("email")}
                type="text"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={1}>
              <MDInput
                {...register("password")}
                type="password"
                label="Password"
                fullWidth
              />
              <Link to="/reset-password">
                <MDTypography
                  variant="button"
                  fontWeight="medium"
                  mt={1}
                  color="info"
                  textGradient
                >
                  Forgot password?
                </MDTypography>
              </Link>
            </MDBox>
            <MDBox mt={5} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="primary"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mt={1.5} mb={1}>
            <MDButton
              onClick={() => navigate("/register")}
              variant="gradient"
              color="primary"
              fullWidth
            >
              New User? Register Here
            </MDButton>
          </MDBox>
          <Footer />
        </MDBox>
      </Card>
    </AuthLayout>
  );
}

export default Basic;
