import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import RoleGate from "components/RoleGate/RoleGate";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES } from "utils/constants";
import { useDispatch } from "react-redux";
import { logOut } from "store/authSlice";
import ApiClient from "api/v2/ApiClient";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";

const navigation = [
  { name: "Overview", href: "/overview", value: "Overview" },
  { name: "API Details", href: "/api-details", value: "ApiDetails" },
  { name: "Account", href: "/account", value: "Account" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MobileSidebar() {
  const { user } = useSelector(authState);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    await ApiClient.auth
      .logout()
      .then(() => {
        dispatch(logOut());
        navigate("/login");
        toast.success("Logged out successfully");
      })
      .catch(() => {
        toast.error("Failed to logout");
      });
  };
  return (
    <Disclosure as="nav" className="bg-primary">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center xl:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-success focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xl:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => {
                if (!user.pages.includes(item.value)) {
                  return null;
                }
                return (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      location.pathname.includes(item.href)
                        ? "bg-success text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                );
              })}

              <RoleGate
                allowedRoles={[ROLES.ADMIN]}
                additionalCheck={() => {
                  return user.pages.includes("AdminFunctions");
                }}
              >
                <Disclosure.Button
                  as="a"
                  href={`/admin-functions/user-details${location?.search}`}
                  className={classNames(
                    location.pathname.includes("user-details")
                      ? "bg-success text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                >
                  User Details
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href={`/admin-functions/user-management${location?.search}`}
                  className={classNames(
                    location.pathname.includes("user-management")
                      ? "bg-success text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                >
                  User Management
                </Disclosure.Button>
              </RoleGate>

              <Disclosure.Button
                color="white"
                className="block w-full hover:bg-gray-700 py-2 hover:text-white text-start rounded-md px-3 text-base font-medium text-gray-300"
                variant="contained"
                sx={{
                  marginBottom: "3px",
                }}
                onClick={() => {
                  logoutUser();
                }}
              >
                Log Out
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
