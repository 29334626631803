import React from "react";
import { useFormContext } from "react-hook-form";
import MDInput from "components/ui/MDInput";
import { isValidPassword } from "../schema";
import ShowTermsAndConditionsModal from "./ShowTermsAndConditionsModal";
import { Switch } from "@mui/material";
import { useState } from "react";
import AddressLookUp from "components/PostcodeLookup/PostcodeLookup";

export default function UserInformationView() {
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    React.useState(false);
  const [allowManalAddress, setAllowManualAddress] = useState(false);
  const {
    register,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="grid grid-cols-1 gap-y-3 gap-x-3 mb-5 sm:grid-cols-2">
        <div className="col-span-2 sm:col-span-1">
          <MDInput
            {...register("title")}
            type="text"
            required
            label="Title"
            error={errors?.title?.message}
            fullWidth
          />
        </div>
        <div className="sm:col-span-1 sm:block hidden"></div>
        <div className="col-span-2 sm:col-span-1">
          <MDInput
            type="text"
            label="First name"
            required
            {...register("firstName")}
            error={errors?.firstName?.message}
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            type="text"
            required
            label="Surname"
            error={errors?.surname?.message}
            {...register("surname")}
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            {...register("email")}
            error={errors?.email?.message}
            type="text"
            required
            label="Email Address"
            fullWidth
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            {...register("confirm_email")}
            error={errors?.confirm_email?.message}
            type="text"
            required
            onBlur={() => {
              if (watch("email") !== watch("confirm_email")) {
                setError("confirm_email", {
                  message: "Emails do not match",
                });
              } else {
                clearErrors("confirm_email");
              }
            }}
            label="Confirm Email Address"
            fullWidth
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            {...register("password")}
            type="password"
            onBlur={() => {
              if (!isValidPassword(watch("password"))) {
                return setError("password", {
                  message:
                    "Password must be at least 6 characters long, contain at least one special character and one uppercase letter",
                });
              }
              clearErrors("password");
            }}
            required
            error={errors?.password?.message}
            label="Choose a password"
            fullWidth
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            {...register("confirm_password")}
            error={errors?.confirm_password?.message}
            type="password"
            onBlur={() => {
              if (watch("password") !== watch("confirm_password")) {
                return setError("confirm_password", {
                  message: "Passwords do not match",
                });
              }
              clearErrors("confirm_password");
            }}
            label="Confirm password"
            fullWidth
            required
          />
        </div>

        <div className="col-span-2">
          <MDInput
            {...register("telephoneNumber")}
            type="text"
            required
            label="Telephone Number"
            error={errors?.telephoneNumber?.message}
            fullWidth
          />
        </div>

        <div className="col-span-2 sm:col-span-1">
          <MDInput
            type="text"
            error={errors?.company?.message}
            label="Company"
            fullWidth
            required
            {...register("company")}
          />
        </div>

        <div className="col-span-2 justify-center flex sm:justify-start items-center sm:col-span-1">
          <Switch
            checked={allowManalAddress}
            onChange={() => setAllowManualAddress(!allowManalAddress)}
          />
          <label className="text-sm" htmlFor="">
            Enter address manually
          </label>
        </div>

        <AddressLookUp
          onAddressLookup={(address) => {
            setValue("addressLine1", address["Address Line 1"]);
            setValue("addressLine2", address["Address Line 2"]);
            setValue("addressLine3", address["Address Line 3"]);
            setValue("propertyName", address["Organisation/Department"]);
            setValue("postcode", address["Postcode"]);
            setValue("town", address["Town/City"]);
            setAllowManualAddress(true);
          }}
        />

        {allowManalAddress && (
          <>
            <div className="sm:col-span-2">
              <MDInput
                {...register("propertyName")}
                value={watch("propertyName")}
                type="text"
                label="Property Name or Number"
                error={errors?.propertyName?.message}
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("addressLine1")}
                type="text"
                label="Company Address Line 1"
                value={watch("addressLine1")}
                required
                error={errors?.addressLine1?.message}
                fullWidth
              />
            </div>

            <div className="sm:col-span-2">
              <MDInput
                {...register("addressLine2")}
                value={watch("addressLine2")}
                type="text"
                error={errors?.addressLine2?.message}
                label="Company Address Line 2"
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("addressLine3")}
                type="text"
                value={watch("addressLine3")}
                label="Company Address Line 3"
                error={errors?.addressLine3?.message}
                fullWidth
              />
            </div>

            <MDInput
              type="text"
              required
              {...register("town")}
              label="Town / City"
              value={watch("town")}
              error={errors?.town?.message}
              fullWidth
            />
            <div className="sm:col-span-2">
              <MDInput
                value={watch("postcode")}
                {...register("postcode")}
                type="text"
                required
                label="Post Code"
                error={errors?.postcode?.message}
                fullWidth
              />
            </div>
          </>
        )}
      </div>
      <div className="w-full flex pb-3 justify-center items-center gap-x-2">
        <input
          type="checkbox"
          {...register("termsAndConditions")}
          className="text-2xl"
        />
        <button
          type="button"
          onClick={() => {
            setShowTermsAndConditionsModal(true);
          }}
          className="text-primary font-bold underline"
        >
          I accept the terms and conditions
        </button>
      </div>
      <div className="w-full flex justify-center">
        <button
          disabled={!watch("termsAndConditions")}
          className={` text-white w-full px-12 py-2 rounded-lg ${
            watch("termsAndConditions")
              ? "bg-primary text-white"
              : "bg-gray-300"
          }`}
          type="submit"
        >
          Create Account
        </button>
      </div>
      <ShowTermsAndConditionsModal
        open={showTermsAndConditionsModal}
        handleClose={() => setShowTermsAndConditionsModal(false)}
      />
    </>
  );
}
