import * as z from "zod";

export function isValidPassword(password) {
  if (password.length < 6) {
    return false;
  }
  if (!/[A-Z]/.test(password)) {
    return false;
  }
  if (!/[@!#$%^&*()\-_=+[\]{};:'",.<>/?\\|`~]/.test(password)) {
    return false;
  }
  return true;
}

export const updateEmailSchema = z
  .object({
    email: z.string().nonempty({
      message: "Email is required",
    }),
    confirmEmail: z.string().nonempty({
      message: "Confirmation email is required",
    }),
    password: z.string().nonempty({
      message: "Password is required",
    }),
  })
  .refine((data) => data.email === data.confirmEmail, {
    message: "Emails don't match",
    path: ["confirmEmail"],
  });
