import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import PostTagLogo from "../../assets/images/posttag-logo.png";
import { Box, Button } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logOut } from "store/authSlice";
import RoleGate from "components/RoleGate/RoleGate";
import { PAGES, ROLES } from "utils/constants";
import ApiClient from "api/v2/ApiClient";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { toast } from "react-toastify";
import { Authorization } from "components/Authorization";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";

const SidebarItem = ({ icon, title, selectItem, link, visible, children }) => {
  const location = useLocation();
  if (!visible) return null;
  return (
    <div className="flex flex-col">
      <button
        onClick={() => {
          selectItem(link);
        }}
        className={`pl-5 py-4 rounded-[4px] w-full flex gap-x-3 items-center ${
          location.pathname.includes(link) && "bg-success text-white"
        }`}
      >
        <Icon className="text-xl" icon={icon} />
        <span className="text-left">{title}</span>
      </button>
      {children}
    </div>
  );
};

export default function Sidebar() {
  const [selectedUser, setSelectedUser] = useAtom(selectedCompanyAtom);
  const { user } = useSelector(authState);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    await ApiClient.auth
      .logout()
      .then(() => {
        dispatch(logOut());
        navigate("/login");
        toast.success("Logged out successfully");
      })
      .catch(() => {
        toast.error("Failed to logout");
      });
  };

  return (
    <Box
      className="flex flex-col gap-y-4"
      sx={{ marginTop: "10px", width: "250px", position: "sticky", top: 10 }}
    >
      <div className="flex flex-col sticky top-0 gap-y-3">
        <Link to={`/overview${location?.search}`}>
          <img src={PostTagLogo} className="h-[80px] mx-auto" alt="" />
        </Link>
        <div className="bg-primary sticky w-full  rounded-3xl pl-2 pr-6 pt-12 pb-3 max-w-250px h-[84vh] flex flex-col">
          <div className="text-white">
            <SidebarItem
              visible={user.pages.includes(PAGES["Overview"].name)}
              title={"Overview"}
              selectItem={(e) => {
                if (location.pathname === "/overview") {
                  window.scrollTo(0, 0);
                  return;
                }
                navigate(e);
              }}
              icon={"uil:chart"}
              link={"/overview"}
            />
            <SidebarItem
              visible={user.pages.includes(PAGES["ApiDetails"].name)}
              title={"API Details"}
              selectItem={(e) => {
                navigate(`${e}${location?.search}`);
              }}
              icon={"zondicons:network"}
              link={"/api-details"}
            />
            <SidebarItem
              visible={user.pages.includes(PAGES["Account"].name)}
              title={"Account"}
              selectItem={(e) => {
                navigate(`${e}${location?.search}`);
              }}
              icon={"bxs:user"}
              link={"/account"}
            />
            <RoleGate allowedRoles={[ROLES.ADMIN]}>
              <SidebarItem
                visible={user.pages.includes(PAGES["AdminFunctions"].name)}
                title={"Admin Functions"}
                selectItem={(e) => {}}
                icon={"ri:admin-fill"}
              >
                <div className="flex flex-col pl-5">
                  <SidebarItem
                    visible={user.pages.includes(PAGES["AdminFunctions"].name)}
                    title="User Details"
                    icon="ri:user-settings-fill"
                    link="/admin-functions/user-details"
                    selectItem={(e) => navigate(`${e}${location?.search}`)}
                  />
                  <SidebarItem
                    visible={user.pages.includes(PAGES["AdminFunctions"].name)}
                    title="User Management"
                    icon={"eos-icons:cluster-management"}
                    link="/admin-functions/user-management"
                    selectItem={(e) => navigate(`${e}${location?.search}`)}
                  />
                </div>
              </SidebarItem>
            </RoleGate>
          </div>
          <div className="mt-auto text-sm ml-4 flex items-center flex-col gap-5 pb-5 justify-center text-white">
            <Authorization
              allowedRoles={[
                ROLES.USER,
                ROLES.PLUGIN_USER,
                ROLES.CREDIT_USER,
                ROLES.OPERATOR,
              ]}
              additionalCheck={selectedUser?.Type !== ROLES.SUBSCRIPTION_USER}
            >
              <Button
                className="self-end w-full !bg-success !text-white"
                variant="contained"
                sx={{
                  marginBottom: "3px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  navigate(
                    `/overview${location?.search}#purchase-credits-card`
                  );
                }}
              >
                Purchase Credits
              </Button>
            </Authorization>

            <Button
              color="white"
              className="self-end w-full"
              variant="contained"
              sx={{
                marginBottom: "3px",
                fontSize: "14px",
              }}
              onClick={() => {
                logoutUser();
              }}
            >
              Log Out
            </Button>
            <a href="http://posttag.com/privacy-policy/" className="underline">
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </Box>
  );
}
