import { Icon } from "@iconify/react/dist/iconify.js";
import { Runs, ScheduleDetails, ScheduledPayment } from "api/v2/Payments/types";
import InfoRow from "components/InfoRow/InfoRow";
import dayjs from "dayjs";
import DataTable from "examples/Tables/DataTable";
import { formatCurrency } from "utils/currency";
import { ScheduledPaymentStatus } from "./ScheduledPayments";
import { memo, useState } from "react";
import UpdateScheduledPaymentsModal from "./UpdateSchedulePaymentModal/UpdateScheduledPaymentsModal";
import { NumberFormatter } from "utils/helpers";

type Props = {
  schedule: {
    information: ScheduledPayment | null;
    pastPayments: Runs[];
    futurePayments: Runs[];
    lookupDetails: ScheduleDetails | null;
  };
};

const tableColDef = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

const ScheduledPaymentTab = memo(({ schedule }: Props) => {
  const [editable, setEditable] = useState<ScheduledPayment | null>(null);
  return (
    <>
      <div className="flex flex-col px-4 pt-8 lg:pt-0 gap-10 relative">
        {schedule?.information?.posttagStatus !==
        ScheduledPaymentStatus.PENDING ? (
          <button
            className="ml-auto flex gap-1 items-center italic sm:absolute right-5 top-5"
            onClick={() => setEditable(schedule.information)}
          >
            Edit
            <Icon
              icon="material-symbols:settings"
              className="text-3xl text-primary"
            />
          </button>
        ) : null}
        <section className="flex flex-col gap-5">
          <h2 className="font-bold text-primary text-xl">
            Subscription Information
          </h2>
          <div className="w-full grid grid-cols-1 gap-2 ">
            <InfoRow
              label="Reference"
              value={schedule.lookupDetails?.reference ?? ""}
            />
            <InfoRow
              label="Description"
              value={schedule.lookupDetails?.description ?? ""}
            />
            <hr />
            <InfoRow
              label="Credit Amount"
              value={NumberFormatter(schedule?.information?.credit) ?? 0}
            />
            <InfoRow
              label="Credit Threshold"
              value={NumberFormatter(schedule?.lookupDetails?.threshold) ?? 0}
            />
            <InfoRow
              label="Payment Amount"
              value={formatCurrency(
                parseInt(schedule?.information?.paymentAmount ?? "0")
              )}
            />
            <hr />
            <InfoRow
              label="Manual Payments"
              value={
                <div className="flex flex-col">
                  {schedule.lookupDetails?.manualPayments.map(
                    (payment, idx) => {
                      return (
                        <p key={idx} className="flex gap-2 font-semibold">
                          {formatCurrency(payment.amount)} -{" "}
                          {dayjs(payment.date).format("DD/MM/YYYY")}
                        </p>
                      );
                    }
                  )}
                </div>
              }
            />
            <InfoRow
              label="Payment Exceptions"
              value={
                <div className="flex flex-col">
                  {schedule.lookupDetails?.paymentExceptions.map(
                    (payment, idx) => {
                      return (
                        <p key={idx} className="flex gap-2 font-semibold">
                          {dayjs(payment).format("DD/MM/YYYY")}
                        </p>
                      );
                    }
                  )}
                </div>
              }
            />
            <InfoRow
              label="Start Date"
              value={dayjs(schedule?.information?.startDate).format(
                "DD/MM/YYYY"
              )}
            />
            <InfoRow
              label="Next Payment"
              value={dayjs
                .unix(schedule?.lookupDetails?.nextRunAt ?? dayjs().unix())
                .format("DD/MM/YYYY")}
            />
          </div>
        </section>
        <ScheduledPaymentTable
          paymentDate={(val) => dayjs.unix(val.date).format("DD/MM/YYYY")}
          title="Upcoming Payments"
          payments={schedule.futurePayments}
          noPaymentsText="No Upcoming Payments"
        />
        <ScheduledPaymentTable
          paymentDate={(val) => dayjs(val.runDate).format("DD/MM/YYYY")}
          title="Past Payments"
          payments={schedule.pastPayments}
          noPaymentsText="No Past Payments"
        />
      </div>
      {!!editable && (
        <UpdateScheduledPaymentsModal
          subscription={{
            base: editable,
            lookupDetails: schedule.lookupDetails,
          }}
          open={!!editable}
          handleClose={() => setEditable(null)}
        />
      )}
    </>
  );
});

export const ScheduledPaymentPreview = ({
  amount,
  date,
  onSelect,
  active,
  status,
}: {
  amount: string;
  date: string;
  onSelect: () => void;
  active: boolean;
  status: string;
}) => {
  return (
    <button
      onClick={onSelect}
      className={`w-full relative px-2 rounded-lg justify-between py-4 border-b-2 pr-4 flex items-start font-semibold
        ${active ? "bg-primary text-white" : "bg-white text-primary"}
      `}
    >
      <p className="w-full flex flex-col items-start text-left">
        <span>Upcoming payment for: {formatCurrency(parseInt(amount))}</span>
        <span>Date: {dayjs(date).format("DD/MM/YYYY")}</span>
      </p>
      <div
        className={`
        absolute right-2 top-2
                    py-2 px-4 rounded-md text-white
                ${
                  status === ScheduledPaymentStatus.ENABLED
                    ? "bg-success"
                    : status === ScheduledPaymentStatus.DISABLED
                    ? "bg-red-500"
                    : "bg-orange-300"
                }`}
      >
        {status}
      </div>
    </button>
  );
};

export const ScheduledPaymentTable = ({
  title,
  payments,
  noPaymentsText,
  paymentDate,
}: {
  title: string;
  payments: Runs[];
  noPaymentsText: string;
  paymentDate: (payment: Runs & { runDate: string }) => string;
}) => {
  return (
    <section className="flex flex-col gap-5">
      <h2 className="font-bold text-primary text-xl">{title}</h2>
      {payments.length > 0 ? (
        <DataTable
          title=""
          entriesPerPage={{
            defaultValue: 5,
            entries: [5, 10, 15, 20],
          }}
          table={{
            columns: tableColDef,
            rows:
              payments.map((payment) => {
                return {
                  date: paymentDate(payment as Runs & { runDate: string }),
                  amount: formatCurrency(payment.amount),
                };
              }) ?? [],
          }}
        />
      ) : (
        <h2 className="text-primary font-bold text-center">{noPaymentsText}</h2>
      )}
    </section>
  );
};

export default ScheduledPaymentTab;
