import { Icon } from "@iconify/react";
import { Card } from "@mui/material";
import React, { ComponentPropsWithoutRef } from "react";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { ROLES } from "utils/constants";
import RoleGate from "components/RoleGate/RoleGate";
import { formatCurrency } from "utils/currency";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "../FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";

type BasePriceCardProps = ComponentPropsWithoutRef<"div"> & {
  benefits: string[];
  price: number | string | null;
  onCreditsSelect?: () => void;
  onEdit?: () => void;
  children?: React.ReactNode;
  purchaseLabel: string;
};

const BasePriceCard = ({
  benefits,
  price,
  onCreditsSelect,
  onEdit,
  children,
  purchaseLabel,
}: BasePriceCardProps) => {
  const { user } = useSelector(authState);
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  return (
    <Card className="p-4 relative h-full  !bg-slate-100">
      {user?.type === ROLES.ADMIN &&
        onEdit &&
        selectedUser?.Type !== ROLES.ADMIN && (
          <button className="absolute right-2 top-2" onClick={() => onEdit()}>
            <Icon
              icon="material-symbols:settings"
              className="text-3xl text-primary"
            />
          </button>
        )}

      <div className="flex flex-col justify-center items-center">
        <h1 className="font-bold flex justify-center flex-col gap-1  py-10">
          {price ? (
            <span className="text-5xl">{formatCurrency(price)}</span>
          ) : (
            <span className="text-5xl text-center">***</span>
          )}
          <span className="text-sm font-semibold text-center">
            Excluding VAT
          </span>
        </h1>
        <div className="flex flex-col self-start gap-y-4 font-semibold">
          {benefits?.map((benefit) => {
            return (
              <div className="flex items-center gap-x-2" key={benefit}>
                <Icon icon="mdi:tick" className="text-green-400 text-2xl" />
                {benefit}
              </div>
            );
          })}
        </div>

        <RoleGate allowedRoles={[ROLES.CREDIT_USER, ROLES.PLUGIN_USER]}>
          {onCreditsSelect && (
            <button
              onClick={() => onCreditsSelect()}
              className="!mt-10 px-8 py-3 bg-white rounded-2xl shadow-md"
            >
              {purchaseLabel}
            </button>
          )}
        </RoleGate>
      </div>
      <div className="flex flex-col mt-5">{children}</div>
    </Card>
  );
};

export default BasePriceCard;
