import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { PAGES } from "utils/constants";

export default function Error() {
  const navigate = useNavigate();
  const { user, isLoggedIn } = useSelector(authState);

  if (user && isLoggedIn) {
    if (!user.pages || !user.pages.find(Boolean)) {
      localStorage.clear();
      window.location.replace("/login");
      return;
    }
    window.location.replace(`/${user.pages.find(Boolean).toLowerCase()}`);
    return;
  }

  return (
    <div className="w-full h-screen flex justify-center flex-col gap-5 items-center">
      <h1 className="text-4xl font-bold text-primary">
        It seems that this page doesn't exist
      </h1>
      <Link
        to="/overview"
        type="button"
        className="px-8 py-4 rounded-lg bg-primary text-white"
      >
        Go back
      </Link>
    </div>
  );
}
