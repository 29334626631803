import ApiClient from "api/v2/ApiClient";
import Calendar from "components/Calendar/Calendar";
import MDInput from "components/ui/MDInput";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { authState } from "store/authSlice";
import { POSTTAG_ERRORS } from "utils/errors";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { MenuItem, Select } from "@mui/material";
import { useQuery } from "react-query";
import { Icon } from "@iconify/react/dist/iconify.js";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "../../FilterBar/FilterBar";
import Button from "components/Button/Button";

export default function SubscriptionPaymentDetailsSection() {
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState);
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { data: hasBiilingInfo, isLoading: isBillingInfoLoading } = useQuery(
    ["billing-information"],
    {
      queryFn: () =>
        ApiClient.payments.getBillingDetails({
          paygId: company ? company.PAYG_ID : "",
        }),
      select: (data) =>
        !data.message.includes(POSTTAG_ERRORS.NO_BILLING_INFORMATION),
    }
  );

  const { data: transactionsData, isLoading: isTransactionsLoading } = useQuery(
    ["transactions"],
    {
      queryFn: () =>
        ApiClient.payments.getTransactions({ paygId: user?.payId }),
      select: (data) => {
        if (data.status !== 200) return [];
        return data.result.filter(
          (transaction) => transaction.transactionStatus === "Approved"
        );
      },
    }
  );

  const fetchBillingInfo = async () => {
    await ApiClient.payments
      .getBillingDetails({
        paygId: company ? company.PAYG_ID : "",
      })
      .then((res) => {
        if (
          res.status != 200 ||
          res.message.includes(POSTTAG_ERRORS.NO_BILLING_INFORMATION)
        )
          return toast.warn("No billing information found");
        const info = res.result;
        if (!info) return;
        setValue("title", info?.["personTitle"]);
        setValue("first_name", info["personFirstName"]);
        setValue("surname", info["personSurname"]);
        setValue("company", info["CompanyName"]);
        setValue("email", info["personEmail"]);
        setValue("mobile_number", info["personMobileNumber"]);
        setValue("address_line_1", info["address1"]);
        setValue("address_line_2", info["address2"]);
        setValue("postcode", info["addressPostcode"]);
        setValue("town", info["addressTown"]);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const fetchContactInfo = async () => {
    await ApiClient.company
      .getCompanyDetails({
        paygId: company?.PAYG_ID ?? "",
      })
      .then((_res) => {
        const res = _res.result;
        setValue("title", res["personTitle"]);
        setValue("first_name", res["personFirstName"]);
        setValue("surname", res["personSurname"]);
        setValue("company", res["CompanyName"]);
        setValue("email", res["personEmail"]);
        setValue("address_line_1", res["address1"]);
        setValue("mobile_number", res["personMobileNumber"]);
        setValue("address_line_2", res["address2"]);
        setValue("postcode", res["addressPostcode"]);
        setValue("property_name", res["addressPropertyName"]);
        setValue("town", res["addressTown"]);
      });
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-center gap-5 items-center">
        <Button
          onClick={() => fetchBillingInfo()}
          disabled={isBillingInfoLoading || !hasBiilingInfo}
        >
          Use Billing Information
        </Button>
        <Button onClick={() => fetchContactInfo()}>
          Use Contact Information
        </Button>
      </div>
      <form action="" className="flex flex-col p-3">
        <div className="flex flex-col gap-5">
          <h2 className="font-semibold text-xl text-primary">
            Personal details
          </h2>
          <div className="grid grid-cols-1 gap-y-3 gap-x-3 mb-5 sm:grid-cols-2">
            <MDInput
              {...register("title")}
              type="text"
              required
              value={watch("title")}
              label="Title"
              error={errors?.title?.message}
              fullWidth
            />
            <MDInput
              type="text"
              label="First name"
              required
              value={watch("first_name")}
              {...register("first_name")}
              error={errors?.first_name?.message}
            />
            <MDInput
              type="text"
              required
              label="Surname"
              value={watch("surname")}
              error={errors?.surname?.message}
              {...register("surname")}
            />
            <MDInput
              type="text"
              error={errors?.company?.message}
              label="Company"
              fullWidth
              value={watch("company")}
              required
              {...register("company")}
            />
            <MDInput
              {...register("mobile_number")}
              type="text"
              required
              value={watch("mobile_number")}
              label="Mobile Number"
              error={errors?.mobile_number?.message}
              fullWidth
            />
            <MDInput
              {...register("email")}
              error={errors?.email?.message}
              type="text"
              required
              value={watch("email")}
              label="Email Address"
              fullWidth
            />
            <div className="sm:col-span-2">
              <MDInput
                {...register("property_name")}
                type="text"
                label="Property Name"
                value={watch("property_name")}
                error={errors?.property_name?.message}
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("address_line_1")}
                type="text"
                label="Address Line 1"
                value={watch("address_line_1")}
                required
                error={errors?.address_line_1?.message}
                fullWidth
              />
            </div>
            <div className="sm:col-span-2">
              <MDInput
                {...register("address_line_2")}
                value={watch("address_line_2")}
                type="text"
                error={errors?.address_line_2?.message}
                label="Address Line 2"
                fullWidth
              />
            </div>
            <MDInput
              {...register("town")}
              type="text"
              value={watch("town")}
              label="Town"
              error={errors?.town?.message}
              required
              fullWidth
            />
            <MDInput
              {...register("postcode")}
              value={watch("postcode")}
              type="text"
              required
              label="Post Code"
              error={errors?.postcode?.message}
              fullWidth
            />
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <h2 className="font-semibold text-xl text-primary">
            Subscription details
          </h2>
          <div className="grid items-end grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-2">
            {watch("purchaseType") === "SD" && (
              <Calendar
                disabled={{
                  before: dayjs().toDate(),
                }}
                selected={dayjs(watch("startDate")).toDate()}
                mode="single"
                onSelect={(date) => {
                  setValue("startDate", dayjs(date).format("YYYY-MM-DD"));
                }}
              />
            )}
            {watch("purchaseType") === "SD" && <div className=""></div>}
            <MDInput
              {...register("threshold")}
              value={watch("threshold")}
              type="text"
              label="Minimum credit top-up threshold (optional)"
              error={errors?.postcode?.message}
              fullWidth
            />
          </div>
          <div className="flex flex-col gap-2">
            {watch("purchaseType") === "SD" &&
              transactionsData &&
              transactionsData.length > 0 && (
                <div className="flex flex-col sm:flex-row font-semibold items-start sm:items-center gap-2 text-primary">
                  <label htmlFor="">Use past payment (optional):</label>
                  <div className="flex items-center gap-2">
                    {!isTransactionsLoading && (
                      <Select
                        className="py-2 text-left min-w-[200px] max-w-full"
                        value={watch("Ref") || ""}
                        onChange={(e) => {
                          setValue("Ref", e.target.value);
                        }}
                      >
                        {transactionsData?.map((transaction, idx) => {
                          return (
                            <MenuItem
                              key={idx}
                              value={transaction.ref}
                              className="flex flex-col text-left"
                            >
                              <p>Credits: {transaction.credits}</p>

                              <p>
                                Last 4 digits: **** **** ****{" "}
                                {transaction.cardLastFour}
                              </p>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    {watch("Ref") && (
                      <button
                        type="button"
                        onClick={() => {
                          setValue("Ref", "");
                        }}
                      >
                        <Icon
                          icon="mdi:times"
                          className="text-primary text-2xl"
                        />
                      </button>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>
      </form>
    </>
  );
}
