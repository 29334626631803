import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { useAtom } from "jotai";
import GenericLayout from "layouts/GenericLayout";
import { useSearchParams } from "react-router-dom";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import AccountSettings from "pages/AdminFunctions/components/AccountSettings/AccountSettings";
import KeyGenerator from "./components/KeyGenerator/KeyGenerator";
import RefundTable from "./components/RefundTable";
import InfoCards from "./components/InfoCards";
import ScheduledPayments from "./components/ScheduledPayments/ScheduledPayments";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { ROLES } from "utils/constants";
import { Suspense } from "react";
import AdminFunctionsPageShimmer from "./components/AdminFunctionsPageShimmer";
import { Skeleton } from "@mui/material";
import ManualPaymentsCard from "./components/ManualPayments/ManualPaymentsCard";
import LogsCard from "./components/Logs/LogsCard";
import EmailsSent from "./components/EmailsSent/EmailsSent";
import AsyncComponent from "components/AsyncComponent/AsyncComponent";
import Shimmer from "components/Shimmer/Shimmer";

export default function AdminFunctions() {
  const { user } = useSelector(authState);
  const [searchParams] = useSearchParams();
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );

  return (
    <GenericLayout showFilterBar>
      <Suspense fallback={<AdminFunctionsPageShimmer />}>
        <div className="flex flex-col gap-y-8">
          <div className="w-full flex justify-center">
            <h1 className="text-primary break-all text-5xl underline font-bold">
              {company?.["CompanyName"]}
            </h1>
          </div>
          <RefundTable company={company} />
          {company?.Type === ROLES.SUBSCRIPTION_USER && (
            <Suspense fallback={<Skeleton className="w-full" height={200} />}>
              <ManualPaymentsCard />
            </Suspense>
          )}

          <Suspense fallback={<Skeleton className="w-full" height={200} />}>
            {company && (
              <ScheduledPayments
                company={company}
                paygId={company?.["PAYG_ID"]}
              />
            )}
          </Suspense>

          <AsyncComponent
            suspenseFallback={<Shimmer height="h-[500px]" width="w-full" />}
          >
            <LogsCard />
          </AsyncComponent>

          <AsyncComponent>
            <EmailsSent />
          </AsyncComponent>

          {company?.Type === ROLES.OPERATOR && <KeyGenerator />}

          <AccountSettings />
        </div>
      </Suspense>
    </GenericLayout>
  );
}
