import BaseModal from "../../BaseModal/BaseModal";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { toast } from "react-toastify";
import { zodResolver } from "@hookform/resolvers/zod";
import { updateEmailSchema } from "./schema";
import { registrationInfoAtom } from "pages/Auth/Register/Register";
import { useNavigate } from "react-router-dom";
import { UpdateUserEmailRequest } from "api/v2/Account/types";
import { SelectedCompanyAtomType } from "types/AtomTypes";

type Props = {
  open: boolean;
  handleClose: () => void;
  user: {
    email: string;
  };
};

export default function UpdateEmailModal({ open, handleClose, user }: Props) {
  const [registrationInfo, setRegistrationInfo] = useAtom<string | null>(
    registrationInfoAtom
  );
  const navigate = useNavigate();
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const qc = useQueryClient();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(updateEmailSchema),
    defaultValues: {
      email: user.email ?? "",
      confirmEmail: "",
      password: "",
    },
  });

  const { mutate: updateMutation } = useMutation(
    (data: Omit<UpdateUserEmailRequest, "paygId">) =>
      ApiClient.account.updateEmail({
        email: data.email,
        password: data.password,
        paygId: company?.PAYG_ID ?? "",
      }),
    {
      onSuccess: () => {
        qc.invalidateQueries(["company-details", company], {
          exact: true,
        });
        toast.warn(
          "You will be logged out shortly, please re-verify your email"
        );
        setTimeout(() => {
          localStorage.clear();
          setRegistrationInfo(getValues("email"));
          navigate("/verify-email");
        }, 5000);
        handleClose();
      },
      onError: () => {
        qc.invalidateQueries(["company-details", company], {
          exact: true,
        });
      },
    }
  );

  const onSubmit = async () => {
    updateMutation({
      email: getValues("email"),
      password: getValues("password"),
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Update Registered Information"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="flex flex-col gap-1">
            <TextField
              label="Email"
              {...register("email")}
              error={!!errors?.email?.message}
              id="email"
              variant="outlined"
            />
            <span className="text-red-500 text-xs ml-2">
              {errors?.email?.message}
            </span>
          </div>
          <div className="flex flex-col gap-1">
            <TextField
              label="Confirm Email"
              error={!!errors?.confirmEmail?.message}
              {...register("confirmEmail")}
              id="confirmEmail"
              variant="outlined"
            />
            <span className="text-red-500 text-xs ml-2">
              {errors?.confirmEmail?.message}
            </span>
          </div>
          <div className="flex flex-col lg:col-span-2 gap-1">
            <TextField
              label="Password"
              {...register("password")}
              id="password"
              required
              type="password"
              variant="outlined"
            />
          </div>
        </div>
        <button
          type="submit"
          className="!text-white bg-primary py-3 px-8 w-fit rounded-lg lg:w-fit ml-auto self-end"
        >
          Save Changes
        </button>
      </form>
    </BaseModal>
  );
}
