import { Skeleton } from "@mui/material";

export default function AdminFunctionsPageShimmer() {
  return (
    <div className="flex flex-col gap-8">
      <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-3">
        <Skeleton height={200} />
        <Skeleton height={200} />
        <Skeleton height={200} />
      </div>
      <Skeleton height={100} className="w-1/4 mx-auto" />
      <Skeleton
        height={450}
        className="rounded-lg"
        width={"100%"}
        variant="rectangular"
      />
      <Skeleton height={200} />
      <Skeleton className="w-full" height={400} />;
    </div>
  );
}
