import { Card, Grid, Skeleton } from "@mui/material";
import React, { forwardRef } from "react";
import MDTypography from "components/ui/MDTypography";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useState } from "react";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";

const ApiBar = forwardRef(({ secret, apiKey, clientId }, ref) => {
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const [showApiKey, setShowApiKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [showClientId, setShowClientId] = useState(false);

  const copyApiKey = (e) => {
    navigator.clipboard.writeText(e);
    toast.success("Copied to clipboard");
  };

  if (!company)
    return (
      <Skeleton
        ref={ref}
        height={80}
        className="rounded-lg"
        width={"100%"}
        variant="rectangular"
      />
    );

  return (
    <Grid container columns={8} gap={"60px"}>
      <Card className="w-full p-5">
        <h1 className="font-bold text-secondary pb-5 text-lg">API Details</h1>
        {clientId && (
          <div className="grid grid-cols-api-key" ref={ref}>
            <p className="text-secondary text-xl lg:text-base p-4">
              Client ID <span className="text-sm">(id)</span>
            </p>
            <div className="flex items-center justify-center">
              <MDTypography variant="h3" className="p-4">
                {showClientId
                  ? clientId
                  : new Array(clientId.length).fill("*").join("")}
              </MDTypography>
            </div>
            <div className="flex flex-row justify-end p-4">
              <button onClick={() => setShowClientId(!showClientId)}>
                <Icon
                  icon={
                    showClientId ? "mdi:eye-outline" : "mdi:eye-off-outline"
                  }
                  width="32px"
                  className="mx-2"
                />
              </button>
              <button onClick={() => copyApiKey(clientId)}>
                <Icon
                  icon="material-symbols:file-copy-outline"
                  width="32px"
                  className="mx-2"
                />
              </button>
            </div>
          </div>
        )}

        <div className="grid grid-cols-api-key" ref={ref}>
          <p className="text-secondary text-xl lg:text-base p-4">
            PAYG Key <span className="text-sm">(ptid)</span>
          </p>
          <div className="flex items-center justify-center">
            <MDTypography variant="h3" className="p-4">
              {showApiKey
                ? apiKey
                : new Array(apiKey.length).fill("*").join("")}
            </MDTypography>
          </div>
          <div className="flex flex-row justify-end p-4">
            <button onClick={() => setShowApiKey(!showApiKey)}>
              <Icon
                icon={showApiKey ? "mdi:eye-outline" : "mdi:eye-off-outline"}
                width="32px"
                className="mx-2"
              />
            </button>
            <button onClick={() => copyApiKey(apiKey)}>
              <Icon
                icon="material-symbols:file-copy-outline"
                width="32px"
                className="mx-2"
              />
            </button>
          </div>
        </div>

        {secret && (
          <div className="grid grid-cols-api-key" ref={ref}>
            <p className="text-secondary  text-xl lg:text-base p-4">
              Secret Key <span className="text-sm">(key)</span>
            </p>
            <div className="flex items-center justify-center">
              <MDTypography variant="h3" className="p-4">
                {showSecret
                  ? secret
                  : new Array(secret.length).fill("*").join("")}
              </MDTypography>
            </div>
            <div className="flex flex-row justify-end p-4">
              <button onClick={() => setShowSecret(!showSecret)}>
                <Icon
                  icon={showSecret ? "mdi:eye-outline" : "mdi:eye-off-outline"}
                  width="32px"
                  className="mx-2"
                />
              </button>
              <button onClick={() => copyApiKey(secret)}>
                <Icon
                  icon="material-symbols:file-copy-outline"
                  width="32px"
                  className="mx-2"
                />
              </button>
            </div>
          </div>
        )}
      </Card>
    </Grid>
  );
});

export default ApiBar;
