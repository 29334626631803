// @ts-ignore
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { useQuery } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { formatGranularity } from "utils/converters";
import _ from "lodash";
import { ActivityTypeGrouped } from "api/v2/Stats/types";
import BaseCard from "components/Cards/BaseCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import { FormatAxis, FormatData } from "utils/helpers";

type Props = {
  startDate: string;
  endDate: string;
  granularity: string;
  paygId: string;
  scope: "User" | "Operator";
};

type GroupedSubActivity = {
  description: string;
  data: ActivityTypeGrouped[];
};

export default function ActivityBreakdownCarousel({
  startDate,
  endDate,
  granularity,
  paygId,
  scope,
}: Props) {
  const { data: activityBreakdownData } = useQuery(
    ["activity-breakdown", startDate, endDate, granularity, paygId],
    {
      queryFn: () =>
        ApiClient.stats.getTotalFilters({
          activityType: 1,
          endDate: endDate,
          granularity: formatGranularity(granularity),
          paygId: paygId,
          startDate: startDate,
          scope: scope,
        }),
      select: (data) =>
        _.groupBy(
          data.result as unknown as ActivityTypeGrouped,
          "ActivityType"
        ),
      enabled: !!startDate && !!endDate,
    }
  );
  const { data: products } = useQuery(["products"], {
    queryFn: () => ApiClient.stats.listProducts(),
    select: (data) => {
      const res: any = Object.entries(
        _.groupBy(data.result, (data) => data.GTP)
      ).map(([key, value]) => {
        return [
          key,
          value.map((product) => {
            return {
              description: product.Description,
              data: activityBreakdownData?.[product.Description] ?? [],
            };
          }),
        ];
      });
      return res.filter(([_, values]: [string, GroupedSubActivity[]]) => {
        if (values.every((data) => data.data.length === 0)) return false;
        return true;
      });
    },
    enabled: !!activityBreakdownData,
  });

  if (!activityBreakdownData || !products || products?.length === 0)
    return null;

  return (
    <BaseCard title="Product Breakdown">
      <Swiper
        spaceBetween={50}
        slidesPerView={1}
        modules={[Pagination]}
        pagination={{ clickable: true }}
        className="w-full cursor-grab"
      >
        {activityBreakdownData &&
          products &&
          products.map((slide: any, idx: number) => {
            const [activityType, subActivity] = slide;
            return (
              <SwiperSlide key={idx}>
                <BaseCard title={activityType}>
                  <div className="flex flex-col xl:flex-row">
                    {subActivity.map((activity: GroupedSubActivity) => {
                      return (
                        <div
                          className="flex w-full flex-col"
                          key={activity.description}
                        >
                          <h2 className="text-secondary text-lg font-semibold">
                            {activity.description}
                          </h2>
                          <VerticalBarChart
                            chart={{
                              labels: FormatAxis(
                                startDate,
                                endDate,
                                granularity
                              ),
                              datasets: [
                                {
                                  label: "Total",
                                  color: "dark",
                                  data: FormatData({
                                    rawData: activity.data,
                                    granularity: granularity,
                                    from: startDate,
                                    to: endDate,
                                    extractDate: (data: { tdate: string }) =>
                                      data.tdate,
                                    returnDataType: (data: { Total: number }) =>
                                      data.Total,
                                  }),
                                },
                              ],
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </BaseCard>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </BaseCard>
  );
}
