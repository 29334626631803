export type PurchaseCreditsRequest = {
  promocode?: string;
  item: {
    name: string;
    price: string;
    quantity: number;
  };
  companyName: string;
  person: {
    title: string;
    firstName: string;
    surname: string;
    email: string;
    mobileNumber: string;
  };
  address: {
    propertyName: string;
    address1: string;
    address2: string;
    town: string;
    postCode: string;
  };
  callbackUrl: string;
  recurrenceType: "first" | "single";
};

export type VerifyPaymentMethodRequest = {
  companyName: string;
  person: {
    title: string;
    firstName: string;
    surname: string;
    email: string;
    mobileNumber: string;
  };
  address: {
    propertyName: string;
    address1: string;
    address2: string;
    town: string;
    postCode: string;
  };
  callbackUrl: string;
  scheduleRef: string;
};

export type PaygPortalResponse = {
  token: {
    tokenid: string;
    redemption_url: string;
    success_url: string;
    cancel_url: string;
    callback_url: string;
    trans_reference: string;
    uses: string;
  };
  user: any;
  items: {
    name: string;
    price: string;
    quantity: string;
    reference: string;
  }[];
};

export type PurchaseSubscriptionRequest = PurchaseCreditsRequest & {
  purchaseType: "SN" | "SD" | "DS";
  startDate: string;
  maximumPayments: number;
  threshold: number | null; //auto top up threshold, null to opt out
  ref: string; //only used by SubscriptionDate
  credits: number;
  paymentAmount: string;
};

export type BillingInoformation = {
  personTitle: string;
  personFirstName: string;
  personSurname: string;
  CompanyName: string;
  personEmail: string;
  personMobileNumber: string;
  address1: string;
  address2: string;
  addressTown: string;
  addressPostcode: string;
  addressPropertyName: string;
  addressCountry: string;
  addressCounty: string;
};

export type PurchaseTypes = "SN" | "PG" | "SD" | "DS";

export type UpdatePaymentScheduleRequest = {
  adminId: string;
  scheduleRef: string;
  password: string;
  paygId: string;
  key: typeof PaymentScheduleEditableFields[number];
  value: string;
};

export const PaymentScheduleEditableFields = [
  "threshold",
  "credit",
  "description",
  "repeatPattern",
  "startDate",
  "endDate",
  "paymentAmount",
  "maximumRuns",
  "manualPayments",
  "paymentExceptions",
] as const;

export type ScheduledPayment = {
  PAYG_ID: string;
  completedRuns: number;
  credit: string;
  nextPayment: string;
  paymentAmount: string;
  posttagStatus: string;
  scheduleRef: string;
  startDate: string;
};

export type Runs = {
  amount: number;
  date: number;
};

export type ScheduleDetails = {
  alertMessage: string | null;
  calculatedPaymentAmount: string | null;
  calculatedTotalRepaymentAmount: string | null;
  campaignKey: string | null;
  cardKey: string;
  cardStatus: string | null;
  completedRuns: number;
  createdAt: number;
  currency: string;
  description: string;
  endDate: string | null;
  extraParameters: {
    merchantReference: string;
  };
  finalRunAt: number;
  futureRuns: Runs[];
  manualPayments: {
    amount: string;
    date: string;
  }[];
  maximumRuns: null;
  nextRunAmount: number | null;
  nextRunAt: number | null;
  paymentAmount: number;
  paymentExceptions: string[];
  reference: string;
  repeatPattern: string;
  repeatPatternString: string;
  startDate: string;
  status: string;
  terminalKey: string;
  totalAmount: string;
  totalIncludesManualPayments: number;
  totalRuns: number;
  version: string;
  threshold: number | null;
};

export type Transaction = {
  PAYG_ID: string;
  cardExpiryMonth: string;
  TotalWithVAT: string;
  TotalWithoutVAT: string;
  cardExpiryYear: string;
  cardLastFour: string;
  items0Name: string;
  items0Price: string;
  items0Quantity: string;
  items0Reference: string;
  items1Name: string;
  items1Price: string;
  items1Quantity: string;
  items1Reference: string;
  priceExVat: string;
  priceIncVat: string;
  ref: string;
  transactionStatus: string;
  transactionValue: string;
  TransactionTime: string;
  purchaseType: "PAYG" | "SN" | "SD" | "DS";
  credits: number;
  purchaseTypeValue: string;
};

export type UpdateSubscriptionRequest = {
  operation: "disable" | "enable" | "delete";
  paygId: string;
  ref: string;
};

export const SubscriptionStatusCommand = {
  DISABLE: "disable",
  ENABLE: "enable",
  DELETE: "delete",
} as const;

export type PriceCards = {
  Volume1: number;
  Volume2: number;
  Volume3: number;
  Price1: string;
  Price2: string;
  Price3: string;
  ppc: string;
};
