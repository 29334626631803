import AuthLayout from "layouts/AuthLayout";
import Card from "@mui/material/Card";
import bgImage from "assets/images/doors.png";
import { useMutation } from "react-query";
import Header from "../components/Header";
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import MDInput from "components/ui/MDInput";
import MDButton from "components/ui/MDButton";
import { registrationInfoAtom } from "../Register/Register";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import ApiClient from "api/v2/ApiClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Footer";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      email: registrationInfo,
      verificationCode: "",
    },
  });
  const { mutate: sendPasswordResetCodeMutation } = useMutation(
    (data) => ApiClient.auth.sendPasswordResetCode(data),
    {
      onSuccess: () => {
        toast.success("Password reset code sent to email");
      },
    }
  );

  const { mutate: verifyCodeMutation } = useMutation(
    (data) => ApiClient.auth.verifyEmailCode(data),
    {
      onSuccess: (data) => {
        if (data.status !== 200) return toast.error(data.message);
        toast.success("Successfully verified email");
        navigate(
          `/reset-password/confirmation?token=${data.result}&email=${getValues(
            "email"
          )}`
        );
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );

  const onFormSubmit = async (data) => {
    sendPasswordResetCodeMutation(data);
    setRegistrationInfo(data.email);
  };

  const onVerifyCode = async (data) => {
    verifyCodeMutation({
      email: registrationInfo ?? data.email,
      code: data.verificationCode,
    });
  };

  return (
    <AuthLayout image={bgImage}>
      <Card className="text-black max-w-[500px] w-full">
        <Header
          backNavigation={{
            name: "Back",
            link: "/login",
          }}
          title={"Reset Password"}
        />
        <MDBox pt={4} pb={3} px={3}>
          <form action="" onSubmit={handleSubmit(onFormSubmit)}>
            <h2 className="flex justify-center">
              <MDTypography
                variant="p"
                className="max-w-[300px] text-center"
                fontWeight="medium"
                mt={1}
              >
                Please enter your email address below and we will send you a
                code to reset your password.
              </MDTypography>
            </h2>
            <MDBox mb={2} mt={5} className="flex flex-col gap-3">
              <MDInput
                {...register("email")}
                type="text"
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mt={1} mb={1} className="flex flex-col gap-3">
              <MDButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
              >
                Send Reset Link
              </MDButton>
            </MDBox>
          </form>

          <MDBox mb={2} mt={5}>
            <form
              action=""
              className="flex flex-col gap-3"
              onSubmit={handleSubmit(onVerifyCode)}
            >
              <MDInput
                {...register("verificationCode")}
                type="text"
                label="Code"
                fullWidth
              />
              <MDButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
              >
                Verify Code
              </MDButton>
            </form>
          </MDBox>
          <Footer />
        </MDBox>
      </Card>
    </AuthLayout>
  );
}
