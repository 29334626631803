import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import { useState } from "react";
import { useQuery } from "react-query";
import { FormatAxis } from "utils/helpers";
import ApiClient from "api/v2/ApiClient";
import { FormatGranularity } from "utils/helpers";
import { FormatData } from "utils/helpers";
import { POSTTAG_ERRORS } from "utils/errors";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function ApiUsageChart({ from, to, gran, token, scope }) {
  const [showByFilter, setShowByFilter] = useState("TOTAL_USAGE");
  const { data } = useQuery(["totals", from, to, gran, token, gran], {
    queryFn: () =>
      ApiClient.stats.getTotalFilters({
        paygId: token,
        startDate: from,
        endDate: to,
        activityType: 0,
        granularity: FormatGranularity(gran),
        scope: scope,
      }),
    select: (data) => {
      if (data.message === POSTTAG_ERRORS.NO_DATA) return [];
      return data.result;
    },
    enabled: !!token,
  });

  return (
    <VerticalBarChart
      class="w-full"
      icon={{ color: "info", component: "leaderboard" }}
      title={
        <div className="flex w-full items-start justify-between">
          <h1 className="font-medium text-secondary w-full text-lg">
            Usage Metrics
          </h1>
          <div className="ml-auto flex items-center justify-end gap-2 w-full">
            <label className="text-sm font-bold" htmlFor="">
              Show by:
            </label>
            <Select
              className="py-2 text-right px-3 w-fit"
              value={showByFilter}
              onChange={(e) => setShowByFilter(e.target.value)}
            >
              <MenuItem value={"TOTAL_USAGE"}>Total Usage</MenuItem>
              <MenuItem value={"CREDITS_USED"}>Credits Used</MenuItem>
            </Select>
          </div>
        </div>
      }
      chart={{
        labels: FormatAxis(from, to, gran),
        datasets: [
          showByFilter === "TOTAL_USAGE" && {
            color: "primary",
            data: FormatData({
              rawData: data,
              returnDataType: (data) => {
                return data?.Total;
              },
              extractDate: (data) => {
                return data?.tdate;
              },
              from,
              to,
              granularity: gran,
            }),
          },
          showByFilter === "CREDITS_USED" && {
            color: "success",
            data: FormatData({
              rawData: data,
              returnDataType: (data) => {
                return data?.Credit;
              },
              extractDate: (data) => {
                return data?.tdate;
              },
              from,
              to,
              granularity: gran,
            }),
          },
        ],
      }}
    />
  );
}
