import { Icon } from "@iconify/react";
import { Box, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function BaseModal({ open, handleClose, title, children }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={style}
        className="w-[90%] lg:w-3/4 xl:w-1/2 overflow-auto max-h-[90%] relative"
      >
        <button
          className="absolute top-5 right-5"
          type="button"
          onClick={() => handleClose()}
        >
          <Icon icon="mdi:times" className="text-primary text-4xl" />
        </button>
        <Typography
          id="modal-modal-title"
          variant="h4"
          className="pb-10"
          component="h2"
        >
          {title}
        </Typography>
        {children}
      </Box>
    </Modal>
  );
}
