import { CompanyDetailsResponse } from "./types";

export default class CompanyApiClient {
  private http: any;
  private baseURL: string;

  constructor(http: any, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async getCompanyDetails({
    paygId,
  }: {
    paygId: string;
  }): Promise<{
    status: number;
    message: string;
    result: CompanyDetailsResponse;
  }> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getCompanyDetails",
      idValue: paygId,
    });
    return result.data;
  }
}
