import BillingInformation from "./BillingInformation";
import ContactInformation from "./ContactInformation";
import PaymentMethod from "./PaymentMethod";
import ApiClient from "api/v2/ApiClient";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";

export default function BillingCards() {
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { data: companyDetails, isLoading: isCompanyDetailsLoading } = useQuery(
    ["company-details", company],
    {
      queryFn: () =>
        ApiClient.company.getCompanyDetails({
          paygId: company?.PAYG_ID,
        }),
      select: (data) => {
        return data.result;
      },
      enabled: !!company,
    }
  );
  const { data: billingDetails, isLoading: isBillingDetailsLoading } = useQuery(
    ["billing-details", company],
    {
      queryFn: () =>
        ApiClient.payments.getBillingDetails({
          paygId: company?.PAYG_ID,
        }),
      select: (data) => {
        return data.result;
      },
    }
  );

  return (
    <div className="grid gap-4 sm:gap-8 grid-cols-1 md:grid-cols-2">
      <BillingInformation
        user={{
          personTitle: billingDetails?.["personTitle"],
          personFirstName: billingDetails?.["personFirstName"],
          personSurname: billingDetails?.["personSurname"],
          personEmail: billingDetails?.["personEmail"],
          personMobileNumber: billingDetails?.["personMobileNumber"],
          address1: billingDetails?.["address1"],
          address2: billingDetails?.["address2"],
          address3: billingDetails?.["address3"],
          addressTown: billingDetails?.["addressTown"],
          addressCounty: billingDetails?.["addressCounty"],
          addressPostcode: billingDetails?.["addressPostcode"],
          companyName: billingDetails?.["CompanyName"],
          addressPropertyName: billingDetails?.["addressPropertyName"],
          invoiceEmail: billingDetails?.["invoiceEmail"],
          vatReg: billingDetails?.["vatReg"],
        }}
      />
      {companyDetails && (
        <ContactInformation
          user={{
            firstName: companyDetails["personFirstName"] ?? "",
            lastName: companyDetails["personSurname"] ?? "",
            email: companyDetails["personEmail"] ?? "",
            company: companyDetails["CompanyName"] ?? "",
            phone: companyDetails["personMobileNumber"] ?? "",
            address1: companyDetails["address1"] ?? "",
            address2: companyDetails["address2"] ?? "",
            address3: companyDetails["address3"] ?? "",
            postCode: companyDetails["addressPostcode"] ?? "",
          }}
        />
      )}

      <PaymentMethod
        firstName={billingDetails?.["personFirstName"]}
        lastName={billingDetails?.["personSurname"]}
        cardExpiryMonth={billingDetails?.["cardExpiryMonth"]}
        cardExpiryYear={billingDetails?.["cardExpiryYear"]}
        cardLastFourDigits={billingDetails?.["cardLastFour"]}
      />
    </div>
  );
}
