export const convertToPennies = (
  number: string | number | undefined | null
) => {
  if (!number) return 0;
  if (typeof number === "string") return parseFloat(number) * 100;
  if (typeof number === "number") return number * 100;
  throw Error("Invalid number");
};

export const convertFromPennies = (
  number: number | undefined | string | null
) => {
  if (!number) return 0;
  if (typeof number === "string") return parseFloat(number) / 100;
  return number / 100;
};

export const formatCurrency = (
  value: number | string,
  currency: string = "GBP",
  inPence: boolean = true
): string => {
  if (typeof value === "string") {
    value = parseInt(value);
  }

  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
  }).format(inPence ? value / 100 : value);
};
