import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Register from "./pages/Auth/Register/Register";
import "./app.css";
import ProtectedRoute from "pages/routes/ProtectedRoute";
import Login from "pages/Auth/Login/Login";
import "react-toastify/dist/ReactToastify.css";
import AdminFunctions from "pages/AdminFunctions/AdminFunctions";
import Error from "pages/Error/Error";
import RoleProtectedRoute from "pages/routes/RoleProtectedRoute";
import { ROLES } from "utils/constants";
import VerifyEmail from "pages/Auth/VerifyEmail/VerifyEmail";
import ResetPassword from "pages/Auth/ResetPassword/ResetPassword";
import ResetPasswordConfirmation from "pages/Auth/ResetPassword/ResetPasswordConfirmation";
import PaymentDetails from "pages/PaymentDetails/PaymentDetails";
import ApiDetails from "pages/ApiDetails/ApiDetails";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { PAGES } from "utils/constants";
import { Navigate } from "react-router-dom";
import { Suspense } from "react";
import Loading from "pages/Loading/Loading";
import Invoice from "pages/Invoice/Invoice";
import { ErrorBoundary } from "react-error-boundary";
import UserManagement from "pages/AdminFunctions/UserManagement";

export default function App() {
  const { user, isLoggedIn } = useSelector(authState);
  return (
    <ErrorBoundary fallback={<Error />}>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/login" element={<Login />} />

          {isLoggedIn ? (
            <Route element={<ProtectedRoute />}>
              {user.pages.includes(PAGES["Overview"].name) && (
                <Route path="/overview" element={<Dashboard />} />
              )}
              {user.pages.includes(PAGES["ApiDetails"].name) && (
                <Route path="/api-details" element={<ApiDetails />} />
              )}
              {user.pages.includes(PAGES["Account"].name) && (
                <Route path="/account" element={<PaymentDetails />} />
              )}
              {user.pages.includes(PAGES["AdminFunctions"].name) && (
                <Route
                  element={<RoleProtectedRoute allowedRoles={[ROLES.ADMIN]} />}
                >
                  <Route
                    path="/admin-functions/user-details"
                    element={<AdminFunctions />}
                  />
                  <Route
                    path="/admin-functions/user-management"
                    element={<UserManagement />}
                  />
                </Route>
              )}
            </Route>
          ) : (
            <>
              <Route path="/register" element={<Register />} />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/reset-password/confirmation"
                element={<ResetPasswordConfirmation />}
              />
            </>
          )}
          <Route path="/account/invoices/:invoiceRef" element={<Invoice />} />
          <Route
            path="*"
            element={isLoggedIn ? <Error /> : <Navigate to="/login" />}
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
