import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import MDInput from "components/ui/MDInput";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UpdateCreditsRequest } from "api/v2/Admin/types";
import { BadErrorResponse } from "types/NetworkTypes";
import { ROLES } from "utils/constants";
import CurrencyField from "components/FormElements/CurrencyField";
import { convertFromPennies, convertToPennies } from "utils/currency";
import CurrencyInput from "react-currency-input-field";

type Props = {
  open: boolean;
  handleClose: () => void;
  credits: {
    price: string;
    quantity: number;
    ppc: string;
    type: "One" | "Two" | "Three";
  };
  pricingModel: "PAYG" | "Subscription";
};

type FormData = {
  ppc: string;
  price: string;
  quantity: number;
  password: string;
};

export default function UpdateCreditsModal({
  open,
  handleClose,
  credits,
  pricingModel,
}: Props) {
  const qc = useQueryClient();
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState);
  const { register, setValue, handleSubmit } = useForm<FormData>();
  const { mutate } = useMutation(
    (data: UpdateCreditsRequest) => ApiClient.admin.updateUserPriceCard(data),
    {
      onSuccess: () => {
        toast.success("Credits updated successfully");
        qc.invalidateQueries(["prices"]);
        handleClose();
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  useEffect(() => {
    setValue("price", credits?.price);
    setValue("quantity", credits?.quantity);
    setValue("ppc", credits.ppc);
  }, [open]);

  const formSubmit = async (data: {
    price: string;
    quantity: number;
    password: string;
  }) => {
    mutate({
      ...data,
      type: company?.Type as "User",
      card: credits?.type,
      paygId: company?.PAYG_ID ?? "",
      adminId: user?.payId,
      priceType: pricingModel,
    });
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={`Edit users credits price & quantity `}
    >
      <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-5">
        <p className="text-sm text-secondary"></p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
          <CurrencyField
            label="Discount"
            prefix="£"
            defaultValue={credits.ppc ?? 0}
            decimalsLimit={10}
            onValueChange={(value) => {
              setValue("price", value as string);
            }}
          />

          <MDInput
            // @ts-ignore
            type="text"
            className="col-span-1 "
            label="Quantity"
            {...register("quantity")}
          />
        </div>

        <div className="col-span-1 sm:col-span-2">
          <MDInput
            // @ts-ignore
            type="password"
            label="Password"
            {...register("password")}
          />
        </div>

        <button
          type="submit"
          className="bg-primary self-end py-3 px-8 w-fit rounded-lg text-white"
        >
          Update
        </button>
      </form>
      <div className="text-sm text-secondary pt-5 text-center">
        {company?.Type === ROLES.USER ? (
          <p>
            *This price card belongs to a{" "}
            <span className="font-bold">User</span>. Updating the price card
            will only affect this user.
          </p>
        ) : (
          <p>
            *This price card belongs to a{" "}
            <span className="font-bold">Operator</span>. Updating the price card
            will affect all the users under this operator.
          </p>
        )}
      </div>
    </BaseModal>
  );
}

UpdateCreditsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
