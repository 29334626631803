import * as z from "zod";

export function isValidPassword(password) {
  if (password.length < 6) {
    return false;
  }
  if (!/[A-Z]/.test(password)) {
    return false;
  }
  if (!/[@!#$%^&*()\-_=+[\]{};:'",.<>/?\\|`~]/.test(password)) {
    return false;
  }
  return true;
}

export const schema = z
  .object({
    type: z.string().nonempty({
      message: "Title is required",
    }),
    firstName: z.string().nonempty({
      message: "First name is required",
    }),
    surname: z.string().nonempty({
      message: "Last name is required",
    }),
    email: z
      .string()
      .email({ message: "Please enter a valid email address" })
      .nonempty({ message: "Please enter a valid email address" }),
    confirm_email: z
      .string()
      .email({ message: "Please enter a valid email address" })
      .nonempty({ message: "Please enter a valid email address" }),
    password: z
      .string()
      .min(6, { message: "Password must be at least 6 characters long" }),
    confirm_password: z
      .string()
      .min(6, { message: "Password must be at least 6 characters long" }),
    telephoneNumber: z.string().nonempty({
      message: "Please enter a valid telephone number",
    }),
    company: z.string().nonempty({
      message: "Please enter a valid company name",
    }),
    town: z.string().nonempty({
      message: "Please eneter a valid town/city name",
    }),
    title: z.string().nonempty({
      message: "Please select a title",
    }),
    propertyName: z.string(),
    addressLine1: z.string().nonempty({
      message: "Please enter a valid address",
    }),
    addressLine2: z.string(),
    addressLine3: z.string(),
    postcode: z.string().nonempty({
      message: "Please enter a valid postcode",
    }),
  })
  .refine((data) => data.password === data.confirm_password, {
    message: "Passwords don't match",
    path: ["confirm_password"],
  })
  .refine((data) => isValidPassword(data.password), {
    message:
      "Password must contain at least one uppercase letter and one special character",
    path: ["password"],
  })
  .refine((data) => data.email === data.confirm_email, {
    message: "Emails don't match",
    path: ["confirm_email"],
  });
