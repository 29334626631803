import { Switch, TextField } from "@mui/material";
import { EditScheduleManualPaymentRequest } from "api/v2/Admin/requests";
import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useSelector } from "react-redux";
import { UserAuthSlice } from "types/ReduxTypes";
import { authState } from "store/authSlice";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { ManualPayment } from "api/v2/Admin/types";
import Calendar from "components/Calendar/Calendar";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import CurrencyField from "components/FormElements/CurrencyField";
import { Icon } from "@iconify/react/dist/iconify.js";
import Select from "react-select";

import {
  convertFromPennies,
  convertToPennies,
  formatCurrency,
} from "utils/currency";
import Button from "components/Button/Button";
import { toast } from "react-toastify";
import { BadErrorResponse } from "types/NetworkTypes";

type Props = {
  data: {
    paythruDetails: Pick<
      ManualPayment,
      "paythruDetails"
    >["paythruDetails"]["result"];
    summaryDetails: Pick<ManualPayment, "summary">["summary"][number];
  };
  open: boolean;
  handleClose: () => void;
};

type FormData = {
  password: string;
  paymentStartDate: string;
  paymentEndDate: string;
  manualPaymentDate: string;
  manualPaymentAmount: number;
  paymentExceptionDate: string;
  status: "enable" | "disable" | null;
  vatRate: string;
  paymentFrequency: string;
};

const EditManualPaymentModal = ({ data, open, handleClose }: Props) => {
  const qc = useQueryClient();
  const currencyRef = useRef<HTMLInputElement>(null);
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit, watch, setValue, getValues, control } =
    useForm<FormData>();
  const { mutate } = useMutation(
    (_data: EditScheduleManualPaymentRequest) =>
      ApiClient.admin.editScheduleManualPayment(_data),
    {
      onSuccess: () => {
        toast.success("Successfully edited manual payment");
        qc.invalidateQueries([
          "charges",
          selectedUser,
          data.summaryDetails.scheduleRef,
        ]);
        handleClose();
      },
      onError: (err: BadErrorResponse) => {
        toast.error("Error - " + err.response.data.result);
      },
    }
  );

  const manualPayments = useFieldArray<any>({
    name: "manualPayments",
    control: control,
  });

  const paymentExceptions = useFieldArray<any>({
    name: "paymentExceptions",
    control: control,
  });

  const onSubmit = (_data: FormData) => {
    const payments = [
      ...manualPayments.fields.map((payment) => {
        return `${payment.date}:${payment.amount}`;
      }),
      ...data.paythruDetails.manualPayments.map((payment) => {
        return `${dayjs(payment.date).format("YYYY-MM-DD")}:${payment.amount}`;
      }),
    ].join(",");
    const exceptions = [
      ...paymentExceptions.fields.map((exception) => {
        return exception.date;
      }),
      ...data.paythruDetails.paymentExceptions.map((exception) => {
        return dayjs(exception).format("YYYY-MM-DD");
      }),
    ].join(",");

    mutate({
      adminId: user.payId ?? "",
      password: _data.password,
      paygId: selectedUser?.PAYG_ID,
      manualPayments: payments.length > 0 ? payments : "",
      paymentEndDate: dayjs(_data.paymentEndDate).format("YYYY-MM-DD"),
      paymentExceptions: exceptions.length > 0 ? exceptions : "",
      paymentFrequency: "month",
      paymentStartDate: dayjs(_data.paymentStartDate).format("YYYY-MM-DD"),
      scheduleRef: data.summaryDetails.scheduleRef,
      scheduleStatus: _data.status || "disable",
      vatRate: "",
    });
  };

  useEffect(() => {
    setValue("paymentStartDate", data.paythruDetails.startDate);
    setValue("paymentEndDate", data.paythruDetails.endDate);
    setValue("manualPaymentDate", dayjs().format("YYYY-MM-DD"));
    setValue("paymentExceptionDate", dayjs().format("YYYY-MM-DD"));
    setValue(
      "status",
      data.summaryDetails.scheduleStatus === "Enabled" ? "enable" : "disable"
    );
  }, []);

  const pickableExceptionDays = [
    ...data.paythruDetails.futureRuns.map((run) => {
      return dayjs.unix(parseInt(run.date)).format("YYYY-MM-DD");
    }),
    dayjs().format("YYYY-MM-DD"),
  ];

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Edit manual payment schedule"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="flex col-span-2 mb-5 items-center flex-col gap-3">
            <label
              htmlFor=""
              className="font-semibold text-2xl text-gray-800 text-md italic"
            >
              Status
            </label>
            <div className="flex font-semibold text-xl items-center gap-5">
              <span
                className={`${
                  watch("status") === "enable" ? "opacity-50" : ""
                }`}
              >
                Disabled
              </span>
              <Switch
                checked={watch("status") === "enable"}
                onChange={() => {
                  if (getValues("status") === "enable") {
                    setValue("status", "disable");
                  } else {
                    setValue("status", "enable");
                  }
                }}
              />
              <span
                className={`${
                  watch("status") === "disable" ? "opacity-50" : ""
                }`}
              >
                Enabled
              </span>
            </div>
          </div>

          <div className="flex flex-col">
            <label
              htmlFor=""
              className="pl-2 font-semibold text-gray-800 text-md italic"
            >
              Payment start date
            </label>
            <Calendar
              disabled={{
                before: dayjs().toDate(),
              }}
              selected={dayjs(watch("paymentStartDate")).toDate()}
              mode="single"
              onSelect={(date) => {
                if (dayjs(watch("paymentEndDate")).isBefore(dayjs(date))) {
                  setValue(
                    "paymentEndDate",
                    dayjs(date).add(1, "day").format("YYYY-MM-DD")
                  );
                }
                setValue("paymentStartDate", dayjs(date).format("YYYY-MM-DD"));
              }}
            />
          </div>

          <div className="flex flex-col">
            <label
              htmlFor=""
              className="pl-2 font-semibold text-gray-800 text-md italic"
            >
              Payment end date
            </label>
            <Calendar
              disabled={{
                before: dayjs(watch("paymentStartDate")).add(1, "day").toDate(),
              }}
              selected={dayjs(watch("paymentEndDate")).toDate()}
              mode="single"
              onSelect={(date) => {
                setValue("paymentEndDate", dayjs(date).format("YYYY-MM-DD"));
              }}
            />
          </div>
        </div>
        <hr className="my-2" />
        <label
          htmlFor=""
          className="text-gray-800 italic text-md font-semibold"
        >
          Manual payments
        </label>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="flex flex-col gap-3">
            <div className="flex gap-3">
              <CurrencyField
                ref={currencyRef}
                label="Amount"
                prefix="£"
                decimalsLimit={10}
                defaultValue={convertFromPennies(0)}
                onValueChange={(value) => {
                  setValue("manualPaymentAmount", convertToPennies(value));
                }}
              />
              <button
                type="button"
                className="w-full bg-primary text-white py-2 rounded-md"
                onClick={() => {
                  if (
                    dayjs(getValues("manualPaymentDate")).isBefore(
                      dayjs(getValues("paymentStartDate"))
                    )
                  ) {
                    toast.error(
                      "Payment can't be made before the schedule start date"
                    );
                    return;
                  }
                  manualPayments.append({
                    amount: getValues("manualPaymentAmount") ?? 0,
                    date: getValues("manualPaymentDate"),
                  });
                  setValue("manualPaymentDate", dayjs().format("YYYY-MM-DD"));
                  currencyRef.current?.focus();
                }}
              >
                Add payment
              </button>
            </div>
            <Calendar
              disabled={{
                before: dayjs().toDate(),
              }}
              selected={dayjs(watch("manualPaymentDate")).toDate()}
              mode="single"
              onSelect={(date) => {
                setValue("manualPaymentDate", dayjs(date).format("YYYY-MM-DD"));
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            {data.paythruDetails.manualPayments.map((payment, idx) => {
              return (
                <div
                  key={idx}
                  className="flex w-full items-center px-4 border-primary border rounded-lg py-2 justify-between gap-3"
                >
                  <p className="font-semibold flex justify-between items-center w-full">
                    <span>
                      {formatCurrency(payment.amount)}
                      {" - "} {dayjs(payment.date).format("DD/MM/YYYY")}
                    </span>
                    <Icon icon="mingcute:lock-fill" className="text-2xl" />
                  </p>
                </div>
              );
            })}
            {manualPayments.fields.map((exception, idx) => {
              return (
                <div
                  key={idx}
                  className="flex w-full items-center px-4 border-primary border rounded-lg py-2 justify-between gap-3"
                >
                  <p className="font-semibold">
                    {formatCurrency(exception.amount)}
                    {" - "} {dayjs(exception.date).format("DD/MM/YYYY")}
                  </p>
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => manualPayments.remove(idx)}
                  >
                    <Icon icon="mdi:bin" className="text-3xl" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <hr className="my-2" />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <div className="flex flex-col gap-3">
            <label
              htmlFor=""
              className="text-gray-800 italic text-md font-semibold"
            >
              Payment exceptions
            </label>
            <div className="flex gap-3">
              <button
                type="button"
                className="w-full bg-primary text-white py-2 rounded-md"
                onClick={() => {
                  paymentExceptions.append({
                    date: getValues("paymentExceptionDate"),
                  });
                  setValue(
                    "paymentExceptionDate",
                    dayjs().format("YYYY-MM-DD")
                  );
                }}
              >
                Add exception
              </button>
            </div>
            <Calendar
              disabled={(date) => {
                return !pickableExceptionDays.includes(
                  dayjs(date).format("YYYY-MM-DD")
                );
              }}
              selected={dayjs(watch("paymentExceptionDate")).toDate()}
              mode="single"
              onSelect={(date) => {
                setValue(
                  "paymentExceptionDate",
                  dayjs(date).format("YYYY-MM-DD")
                );
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            {data.paythruDetails.paymentExceptions.map((exception, idx) => {
              return (
                <div
                  key={idx}
                  className="flex w-full items-center px-4 border-primary border rounded-lg py-2 justify-between gap-3"
                >
                  <p className="font-semibold">
                    {dayjs(exception).format("DD/MM/YYYY")}
                  </p>
                  <Icon icon="mingcute:lock-fill" className="text-2xl" />
                </div>
              );
            })}
            {paymentExceptions.fields.map((exception, idx) => {
              return (
                <div
                  key={idx}
                  className="flex w-full items-center px-4 border-primary border rounded-lg py-2 justify-between gap-3"
                >
                  <p className="font-semibold">
                    {dayjs(exception.date).format("DD/MM/YYYY")}
                  </p>
                  <button
                    type="button"
                    className="text-red-500"
                    onClick={() => paymentExceptions.remove(idx)}
                  >
                    <Icon icon="mdi:bin" className="text-3xl" />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <hr className="my-2" />
        <TextField type="password" label="Password" {...register("password")} />
        <Button className="w-fit ml-auto" type="submit">
          Edit manual payment
        </Button>
      </form>
    </BaseModal>
  );
};

export default EditManualPaymentModal;
