import { AxiosInstance } from "axios";
import { GenericResponse } from "types/GenericResponse";
import { PostTagProduct, ProductApiDetail, TotalFiltersRequest } from "./types";

export default class StatsApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async listProducts(): Promise<GenericResponse<PostTagProduct[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getProducts",
    });
    return result.data;
  }

  async getTotalFilters({
    paygId,
    startDate,
    endDate,
    granularity,
    activityType = 0,
    scope,
  }: TotalFiltersRequest): Promise<GenericResponse> {
    const result = await this.http.get(
      `${this.baseURL}/payg_accounts.php?cmd=getTotalsFilter&startDate=${startDate}&endDate=${endDate}&granularity=${granularity}&activityType=${activityType}&idValue=${paygId}&scope=${scope}`
    );
    return result.data;
  }

  async getTotalCredits({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<number>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getCredits",
      idValue: paygId,
    });
    return result.data;
  }

  async getProductApiDocs({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<ProductApiDetail[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getModelApiStrings",
      idValue: paygId,
    });
    return result.data;
  }
}
