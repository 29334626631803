import { ComponentPropsWithoutRef, useState } from "react";
import BasePriceCard from "./BasePriceCard";
import PaymentModal from "components/Modals/Misc/PaymentModal";
import { PaymentModalType } from "./PriceCards";
import { NumberFormatter } from "utils/helpers";

type PaygCardsProps = ComponentPropsWithoutRef<"div"> & {
  data: {
    Volume1: number;
    Price1: string;
    Volume2: number;
    Price2: string;
    Volume3: number;
    Price3: string;
    pricePerCredit: string;
  };
  setUpdateCreditsModal: ({
    quantity,
    price,
    type,
    ppc,
  }: {
    quantity: number;
    price: string;
    type: "One" | "Two" | "Three";
    ppc: string;
  }) => void;
};

export default function PaygCards({
  setUpdateCreditsModal,
  data,
}: PaygCardsProps) {
  const [openPaymentModal, setOpenPaymentModal] =
    useState<PaymentModalType | null>(null);
  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8 lg:grid-cols-3 ">
        <BasePriceCard
          purchaseLabel="Purchase credits"
          onEdit={() => {
            setUpdateCreditsModal({
              quantity: data?.["Volume1"],
              price: data?.["Price1"],
              type: "One",
              ppc: data?.pricePerCredit,
            });
          }}
          price={data?.["Price1"]}
          onCreditsSelect={() =>
            setOpenPaymentModal({
              name: `Price-${data?.["Price1"]}-Quantity-${data?.["Volume1"]}`,
              price: data?.Price1,
              quantity: data?.["Volume1"],
              pricePerCredit: data?.pricePerCredit,
            })
          }
          benefits={[
            `${NumberFormatter(data["Volume1"])} credits`,
            "12 months expiry ",
            "Email Support",
          ]}
        />
        <BasePriceCard
          purchaseLabel="Purchase credits"
          onEdit={() => {
            setUpdateCreditsModal({
              quantity: data?.["Volume2"],
              price: data?.["Price2"],
              type: "Two",
              ppc: data?.pricePerCredit,
            });
          }}
          price={data?.["Price2"]}
          benefits={[
            `${NumberFormatter(data["Volume2"])} credits`,
            "12 months expiry ",
            "Email Support",
          ]}
          onCreditsSelect={() =>
            setOpenPaymentModal({
              name: `Price-${data?.["Price2"]}-Quantity-${data?.["Volume2"]}`,
              price: data?.["Price2"],
              quantity: data?.["Volume2"],
              pricePerCredit: data?.pricePerCredit,
            })
          }
        />
        <BasePriceCard
          purchaseLabel="Purchase credits"
          onEdit={() =>
            setUpdateCreditsModal({
              quantity: data?.["Volume3"],
              price: data?.["Price3"],
              type: "Three",
              ppc: data?.pricePerCredit,
            })
          }
          price={data?.["Price3"]}
          benefits={[
            `${NumberFormatter(data["Volume3"])} credits`,
            "12 months expiry ",
            "Email Support",
          ]}
          onCreditsSelect={() =>
            setOpenPaymentModal({
              name: `Price-${data?.["Price3"]}-Quantity-${data?.["Volume3"]}`,
              price: data?.["Price3"],
              quantity: data?.["Volume3"],
              pricePerCredit: data?.pricePerCredit,
            })
          }
        />
        <div className="lg:col-start-2 h-full">
          <BasePriceCard
            purchaseLabel="Purchase credits"
            price={null}
            benefits={["More Credits?"]}
          >
            <p>
              Get in contact with us at{" "}
              <a href="mailto:support@posttag.com" className="font-bold">
                support@posttag.com
              </a>
            </p>
          </BasePriceCard>
        </div>
      </div>
      {openPaymentModal && (
        <PaymentModal
          open={!!openPaymentModal}
          item={openPaymentModal}
          handleClose={() => setOpenPaymentModal(null)}
        />
      )}
    </>
  );
}
