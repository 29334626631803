import { View, Text } from "@react-pdf/renderer";
import { Fragment } from "react";
import { InvoiceStylesheet } from "./useStyles";
import { NumberFormatter } from "utils/helpers";

type Props = {
  styles: InvoiceStylesheet;
  items: {
    description: string;
    quantity: number;
    priceWithoutVAT: string;
    VAT: string;
    total: string;
  }[];
};

export default function InvoiceTableBody({ styles, items }: Props) {
  return (
    <>
      {items.map((item, idx) => {
        return (
          <Fragment key={idx}>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View
                style={{
                  ...styles.tbody,
                  fontWeight: "bold",
                }}
              >
                <Text>{item.description}</Text>
              </View>
              <View style={styles.tbody}>
                <Text>{NumberFormatter(item.quantity)}</Text>
              </View>
              <View style={styles.tbody}>
                <Text>{item.priceWithoutVAT}</Text>
              </View>
              <View style={styles.tbody}>
                <Text>{item.VAT}</Text>
              </View>
              <View style={styles.tbody}>
                <Text>{item.total}</Text>
              </View>
            </View>
          </Fragment>
        );
      })}
    </>
  );
}
