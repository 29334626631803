import { Card } from "@mui/material";
import React from "react";
import { checkUsabledata } from "./BillingInformation";

export default function PaymentMethod({
  firstName,
  lastName,
  cardLastFourDigits,
  cardExpiryMonth,
  cardExpiryYear,
}) {
  return (
    <Card className="p-4">
      <div className="flex justify-between items-center pb-8 ">
        <h2 className=" font-bold text-secondary text-lg">Payment Method</h2>

        {/* <button className="flex bg-black py-2 h-min px-2 max-w-[180px] rounded-xl font-bold w-full text-white gap-x-3 items-center">
          <Icon icon="uil:plus" />
          ADD NEW CARD
        </button> */}
      </div>
      {checkUsabledata([
        firstName,
        lastName,
        cardLastFourDigits,
        cardExpiryMonth,
        cardExpiryYear,
      ]) ? (
        <div className="bg-background-light flex flex-col gap-5 rounded-lg relative text-primary px-4 pt-6 pb-4 h-full">
          <h2 className="text-lg font-bold">
            {firstName} {lastName}
          </h2>
          <div className="flex flex-col gap-y-1">
            <h3 className="text-lg font-semibold">Card Details</h3>
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 pb-5">
              <p>Last 4 Digits</p>
              <p className="font-bold">**** **** {cardLastFourDigits}</p>
              <p>Expiry Date</p>
              <p className="font-bold">
                {cardExpiryMonth} / {cardExpiryYear}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center items-center">
          <h2 className="text-primary font-semibold text-xl">
            No Purchases Made
          </h2>
        </div>
      )}
    </Card>
  );
}
