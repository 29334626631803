import PropTypes from "prop-types";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import MDInput from "components/ui/MDInput";
import { useRef, useEffect, forwardRef, useImperativeHandle } from "react";

const MDDatePicker = forwardRef(({ from, to, ...rest }, ref) => {
  const fromInstance = useRef(null);
  const toInstance = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      fromRef: fromInstance.current,
      toRef: toInstance.current,
    };
  });

  useEffect(() => {
    if (fromInstance.current) {
      fromInstance.current.flatpickr.jumpToDate(from.options.defaultDate[1]);
    }
  }, []);

  return (
    <div className="flex gap-x-2 w-full">
      <div className="flex-col flex w-full">
        {!from.label && (
          <span className="text-secondary italic text-sm font-light">
            Start date
          </span>
        )}
        <Flatpickr
          ref={fromInstance}
          {...rest}
          onChange={(e) => from.onChange(e)}
          options={from.options}
          render={({ defaultValue }, ref) => (
            <MDInput
              {...from.input}
              sx={{ width: "100%" }}
              defaultValue={defaultValue}
              inputRef={ref}
            />
          )}
        />
      </div>
      <div className="flex flex-col w-full">
        {!to.label && (
          <span className="text-secondary italic text-sm font-light">
            End date
          </span>
        )}
        <Flatpickr
          ref={toInstance}
          onChange={(e) => to.onChange(e)}
          {...rest}
          options={to.options}
          render={({ defaultValue }, ref) => (
            <MDInput
              {...to.input}
              sx={{ width: "100%" }}
              defaultValue={defaultValue}
              inputRef={ref}
            />
          )}
        />
      </div>
    </div>
  );
});

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
  options: {},
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  options: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
