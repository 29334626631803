import { useState } from "react";
import BaseCard from "components/Cards/BaseCard";
import WarningModal from "./WarningModal";
import { useQuery } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import DeleteUserWarningModal from "./DeleteUserWarningModal";
import Button from "components/Button/Button";
import ConvertUserToSubscriptionUserModal from "./ConvertUserToSubscriptionUserModal";
import { ROLES } from "utils/constants";

export default function AccountSettings() {
  const [company, setCompany] = useAtom(selectedCompanyAtom);
  const { data: activeStatusData, isLoading: activeStatusLoading } = useQuery(
    ["active-status", company],
    {
      queryFn: () =>
        ApiClient.admin.getApiDetails({
          paygId: company.PAYG_ID,
        }),
      select: (data) => {
        if (data.status !== 200) return null;
        return data.result.Active;
      },
      enabled: !!company,
    }
  );
  const [warningModal, setWarningModal] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [
    convertUserToSubscriptionUserModal,
    setConvertUserToSubscriptionUserModal,
  ] = useState(false);

  return (
    <>
      <BaseCard title="Account Settings">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-3">
          <Button
            onClick={() => {
              setWarningModal(true);
            }}
            variant={"primary"}
          >
            {activeStatusData ? "Deactivate" : "Activate"} User
          </Button>
          <Button
            onClick={() => {
              setConvertUserToSubscriptionUserModal(true);
            }}
            variant={"primary"}
          >
            Update User Role
          </Button>
          <Button
            onClick={() => {
              setDeleteWarningModal(true);
            }}
            variant={"danger"}
          >
            Delete User
          </Button>
        </div>
      </BaseCard>
      <WarningModal
        active={activeStatusData}
        open={warningModal}
        handleClose={() => setWarningModal(false)}
      />
      <DeleteUserWarningModal
        open={deleteWarningModal}
        handleClose={() => setDeleteWarningModal(false)}
      />
      <ConvertUserToSubscriptionUserModal
        open={convertUserToSubscriptionUserModal}
        handleClose={() => setConvertUserToSubscriptionUserModal(false)}
      />
    </>
  );
}
