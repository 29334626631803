import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";
import { Link, LinkProps } from "react-router-dom";

type AnchorProps = React.ComponentPropsWithRef<"a">;
type ButtonProps = React.ComponentPropsWithRef<"button">;

const AAnchor = forwardRef<HTMLAnchorElement, AnchorProps>(
  ({ children, ...props }, ref) => (
    <a {...props} ref={ref}>
      {children}
    </a>
  )
);

AAnchor.displayName = "Action.Anchor";

const ALink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, ...props }, ref) => (
    <Link {...props} ref={ref}>
      {children}
    </Link>
  )
);

ALink.displayName = "Action.Link";

const AButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => (
    <button type="button" {...props} ref={ref}>
      {children}
    </button>
  )
);

AButton.displayName = "Action.Button";

type ActionProps = ComponentPropsWithRef<
  typeof AAnchor | typeof ALink | typeof AButton
>;

export default function Action({ children, ...props }: ActionProps) {
  if ("href" in props) {
    return <a {...(props as AnchorProps)}>{children}</a>;
  }
  if ("to" in props) {
    return <Link {...props}>{children}</Link>;
  }
  return (
    // @ts-expect-error This rule is not smart enough to understand that we are forwarding the ref
    <button type="button" {...props}>
      {children}
    </button>
  );
}
