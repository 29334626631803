import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

export default function Loading() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <BeatLoader />
    </div>
  );
}
