import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { BadErrorResponse } from "types/NetworkTypes";
import { TextField } from "@mui/material";
import Button from "components/Button/Button";
import Select from "react-select";

type Props = {
  open: boolean;
  handleClose: () => void;
};

type FormData = {
  password: string;
  role: string;
};

const RoleOptions = [
  {
    label: "Subscription User",
    value: "SubscriptionUser",
  },
  {
    label: "Credit User",
    value: "CreditUser",
  },
];

export default function ConvertUserToSubscriptionUserModal({
  open,
  handleClose,
}: Props) {
  const qc = useQueryClient();
  const [company] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit, reset, setValue, getValues, watch } =
    useForm<FormData>();
  const { mutate } = useMutation(
    (data: FormData) =>
      ApiClient.admin.convertUserType({
        adminId: user?.payId ?? "",
        password: data.password,
        paygId: company?.PAYG_ID ?? "",
        role: data.role,
      }),
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          toast.error("Error converting user");
          return;
        }
        toast.success("Successfully converted user");
        qc.invalidateQueries(["companies"], {
          exact: true,
        });
        handleClose();
        reset();
        window.location.reload();
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const formSubmit = async (data: FormData) => {
    mutate(data);
  };

  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={`You are about to convert this user`}
    >
      <form onSubmit={handleSubmit(formSubmit)} className="flex flex-col gap-5">
        <h2 className="text-lg font-semibold text-center text-secondary">
          Select the role you want to convert this user to and confirm your
          password
        </h2>
        <div className="flex lg:flex-row flex-col gap-5 items-center">
          <Select
            required
            onChange={(e) => setValue("role", e.value)}
            isSearchable={false}
            className="w-full z-20"
            options={RoleOptions}
          />
          <TextField
            {...register("password")}
            type="password"
            className="w-full"
            required
            label="Password"
          />
        </div>
        <div className="w-full flex items-center gap-5 flex-col lg:flex-row">
          <Button
            variant="primary"
            className="w-full"
            type="button"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="warning" className="w-full" type="submit">
            Update
          </Button>
        </div>
      </form>
    </BaseModal>
  );
}
