import axios from "axios";
import { toast } from "react-toastify";
import config from "../../config";
import { getStorage } from "utils/storage";
import { POSTTAG_ERRORS } from "utils/errors";
import AuthApiClient from "./Auth/AuthApiClient";
import AdminApiClient from "./Admin/AdminApiClient";
import StatsApiClient from "./Stats/StatsApiClient";
import CompanyApiClient from "./Company/CompanyApiClient";
import TaxiApiClient from "./Taxi/TaxiApiClient";
import PaymentsApiClient from "./Payments/PaymentsApiClient";
import AccountApiClient from "./Account/AccountApiClient";
import { GenericResponse } from "types/GenericResponse";
import { QueryCache } from "react-query";

const queryCache = new QueryCache();
const { baseAPI } = config;

export const http = axios.create({
  withCredentials: true,
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getStorage("access")}`;
    return config;
  },
  (error) => {
    toast.error(error.message);
    toast.clearWaitingQueue();
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.data?.status === 401 &&
      error?.response?.data?.message.includes(
        POSTTAG_ERRORS.ACCESS_TOKEN_EXPIRED
      )
    ) {
      try {
        const res: {
          data: GenericResponse<{
            execute_token: string;
            refresh_token: string;
          }>;
        } = await http.post(
          `${baseAPI}/PAYG/v1/public/payg_accounts.php`,
          {
            cmd: "refreshToken",
            token: localStorage.getItem("refresh"),
          },
          {
            headers: {
              "content-type": "application/x-www-form-urlencoded",
            },
          }
        );
        localStorage.setItem("access", res.data.result.execute_token);
        localStorage.setItem("refresh", res.data.result.refresh_token);
        error.config.headers.Authorization = `Bearer ${res.data.result.execute_token}`;
        queryCache.clear();
        return http.request(error.config);
      } catch (error) {
        localStorage.clear();
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);

export default class ApiClient {
  static auth = new AuthApiClient(http, baseAPI, "v1");
  static admin = new AdminApiClient(http, baseAPI, "v1");
  static stats = new StatsApiClient(http, baseAPI, "v1");
  static company = new CompanyApiClient(http, baseAPI, "v1");
  static taxi = new TaxiApiClient(http, baseAPI, "v1");
  static payments = new PaymentsApiClient(http, baseAPI, "v1");
  static account = new AccountApiClient(http, baseAPI, "v1");
}
