import { Autocomplete, TextField } from "@mui/material";
import ApiClient from "api/v2/ApiClient";
import Button from "components/Button/Button";
import BaseCard from "components/Cards/BaseCard";
import useFetchSelectableUsers from "hooks/useFetchSelectableUsers";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { BadErrorResponse } from "types/NetworkTypes";

const ManualEmailVerification = () => {
  const { users, isLoading } = useFetchSelectableUsers();
  const { watch, setValue } = useForm();
  const { mutate } = useMutation({
    mutationFn: () =>
      ApiClient.admin.manualEmailVerification({
        email: watch("email"),
      }),
    onSuccess: () => {
      toast.success("Email verified successfully!");
    },
    onError: (data: BadErrorResponse) => {
      toast.error(data.response.data.result);
    },
  });
  return (
    <BaseCard title="Manual Email Verification">
      <div className="w-full grid grid-cols-3 gap-5">
        {!isLoading && (
          <Autocomplete
            value={watch("email") || ""}
            options={users && users.length > 0 ? users.map((u) => u.Email) : []}
            onChange={(event, newValue) => {
              setValue("email", newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
        <Button className="w-1/3" onClick={async () => mutate()}>
          Verify
        </Button>
      </div>
    </BaseCard>
  );
};

export default ManualEmailVerification;
