import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { TextField } from "@mui/material";
import { ManualPaymentCharge } from "api/v2/Admin/types";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: {
    idValue: string;
    scheduleRef: string;
    manualPaymentCharge: ManualPaymentCharge & {
      isReady?: boolean;
    };
  };
};

type StringyNull = string | null;

type FormData = {
  paymentLabel: StringyNull;
  paymentPrice: StringyNull;
  paymentQuantity: StringyNull;
  paymentTotal: StringyNull;
  paymentFrequency: StringyNull;
  paymentStartDate: StringyNull;
  paymentEndDate: StringyNull;
  paymentStatus: StringyNull;
  paymentType: StringyNull;
  isActive: StringyNull;
  vatRate: StringyNull;
  paymentExceptions: StringyNull;
  manualPayments: StringyNull;
  scheduleStatus: StringyNull;
  password: string;
  paymentDiscount: StringyNull;
  vatAmount: StringyNull;
};

const DeleteChargeModal = ({ open, handleClose, data }: Props) => {
  const qc = useQueryClient();
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit } = useForm<FormData>();
  const { mutate } = useMutation({
    mutationKey: ["delete-manual-payment"],
    mutationFn: (_data: { password: string }) =>
      ApiClient.admin.deleteCharge({
        adminId: user?.payId ?? "",
        password: _data.password,
        ref: data.manualPaymentCharge.paymentId,
      }),
    onSuccess: () => {
      toast.success("Charge deleted successfully");
      qc.invalidateQueries(["charges", selectedUser, data.scheduleRef]);
      handleClose();
    },
    onError: (error: any) => {
      toast.error("There was an issue deleting the charge");
    },
  });

  const onSubmit = async (_data: FormData) => {
    mutate({
      password: _data.password,
    });
  };

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Delete payment charge"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <span className="text-center font-bold">
          To delete this payment charge please confirm your password
        </span>
        <TextField
          label="Confirm password"
          variant="outlined"
          type="password"
          required
          {...register("password")}
        />
        <div className="flex w-full gap-5">
          <Button
            type="button"
            className="w-full"
            variant="danger"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" variant="primary" className="w-full">
            Delete
          </Button>
        </div>
      </form>
    </BaseModal>
  );
};

export default DeleteChargeModal;
