import { Skeleton, Card } from "@mui/material";

export default function DashboardShimmer() {
  return (
    <div className="flex flex-col gap-8">
      <div className="flex gap-x-10">
        <Skeleton variant="rectangular" width={"100%"} height={118} />
        <Skeleton variant="rectangular" width={"100%"} height={118} />
        <Skeleton variant="rectangular" width={"100%"} height={118} />
      </div>
      <Skeleton variant="rectangular" width={"100%"} height={420} />;
      <Card>
        <div className="w-full h-[500px] grid grid-cols-1 md:grid-cols-2 items-start justify-start gap-4 lg:grid-cols-3">
          <Skeleton height={400} width={"100%"} />
          <Skeleton height={400} width={"100%"} />
          <Skeleton height={400} width={"100%"} />
        </div>
      </Card>
    </div>
  );
}
