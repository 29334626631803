import React from "react";
import BaseModal from "../BaseModal/BaseModal";
import Button from "components/Button/Button";

type Props = {
  open: boolean;
  handleClose: () => void;
  onResetPassword: () => void;
};

export default function PasswordResetWarningModal({
  open,
  handleClose,
  onResetPassword,
}: Props) {
  return (
    <BaseModal
      open={open}
      handleClose={handleClose}
      title="Are you sure you want to reset your password?"
    >
      <div className="flex flex-col gap-5">
        <p className="text-center text-secondary">
          Resetting your password will log you out of your account and send a
          password reset link to your email address.
        </p>
        <div className="flex flex-col sm:flex-row text-secondary justify-center gap-5">
          <Button
            className="w-full sm:w-fit"
            onClick={() => {
              onResetPassword();
              handleClose();
            }}
          >
            Reset Password
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}
