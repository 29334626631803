import { AxiosInstance } from "axios";
import { GenericResponse } from "types/GenericResponse";
import {
  ApiDetails,
  EditChargeRequest,
  EmailSent,
  ManageCreditsRequest,
  ManualPayment,
  ManualUserCreationRequest,
  UpdateCreditsRequest,
  UpdateOperatorSecretRequest,
  UpdateUserActiveStatusRequest,
  UserLog,
} from "./types";
import { EntityID } from "types/ReduxTypes";
import { EditScheduleManualPaymentRequest } from "./requests";

export default class AdminApiClient {
  private http: AxiosInstance;
  private baseURL: string;

  constructor(http: AxiosInstance, baseURL: string, version: string) {
    this.http = http;
    this.baseURL = `${baseURL}/PAYG/${version}/public`;
  }

  async getApiDetails({
    paygId,
  }: {
    paygId: string;
  }): Promise<GenericResponse<ApiDetails>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getApiDetails",
      idValue: paygId,
    });
    return result.data;
  }

  async updateUserPriceCard({
    price,
    quantity,
    paygId,
    type,
    card,
    password,
    adminId,
    priceType,
  }: UpdateCreditsRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "updatePrices",
      adminId: adminId,
      password,
      price: price,
      volume: quantity,
      card: card,
      idValue: paygId,
      type: type,
      priceType: priceType,
    });
    return result.data;
  }

  async manageCredits({
    adminId,
    password,
    paygId,
    credits,
    action,
    type,
  }: ManageCreditsRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "setCredits",
      adminId: adminId,
      password,
      idValue: paygId,
      credits: credits,
      action: action,
      type: type,
    });
    return result.data;
  }

  async updateUserActiveStatus({
    adminId,
    password,
    paygId,
    active,
    type,
  }: UpdateUserActiveStatusRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "updateActive",
      adminId: adminId,
      password,
      idValue: paygId,
      active: active,
      type: type,
    });
    return result.data;
  }

  async updateOperatorSecret({
    adminId,
    password,
    paygId,
    secret,
  }: UpdateOperatorSecretRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "updateOperatorSecret",
      adminId: adminId,
      password,
      idValue: paygId,
      key: secret,
    });
    return result.data;
  }

  async listChargeSummary({
    adminId,
    paygId,
    scheduleRef,
  }: {
    adminId: EntityID;
    paygId: EntityID;
    scheduleRef: string;
  }): Promise<GenericResponse<ManualPayment>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php?`, {
      cmd: "getChargeSummary",
      adminid: adminId,
      idValue: paygId,
      scheduleRef,
    });
    return result.data;
  }

  async deleteUser({
    adminId,
    password,
    paygId,
  }: {
    adminId: EntityID;
    password: string;
    paygId: EntityID;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "deleteUser",
      adminId: adminId,
      password,
      idValue: paygId,
    });
    return result.data;
  }

  async createManualPayment({
    adminId,
    password,
    idValue,
    scheduleRef,
  }: {
    adminId: EntityID;
    password: string;
    idValue: EntityID;
    scheduleRef: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "createManualPayment",
      adminId: adminId,
      password,
      idValue,
      scheduleRef,
    });
    return result.data;
  }

  async createManualPaymentSchedule({
    adminId,
    paygId,
    password,
  }: {
    adminId: EntityID;
    paygId: EntityID;
    password: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "createManualPaymentSchedule",
      adminId,
      idValue: paygId,
      password,
    });
    return result.data;
  }

  async editCharge({
    adminId,
    password,
    idValue,
    isActive,
    manualPayments,
    paymentDiscount,
    paymentEndDate,
    paymentExceptions,
    paymentFrequency,
    paymentLabel,
    paymentPrice,
    paymentQuantity,
    paymentStartDate,
    paymentStatus,
    paymentType,
    ref,
    scheduleRef,
    scheduleStatus,
    value,
    vatRate,
  }: EditChargeRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "editCharge",
      adminId,
      password,
      idValue,
      scheduleRef,
      ref,
      paymentLabel,
      paymentPrice,
      paymentQuantity,
      value,
      paymentFrequency,
      paymentStartDate,
      paymentEndDate,
      paymentStatus,
      paymentType,
      isActive,
      vatRate,
      paymentExceptions,
      manualPayments,
      scheduleStatus,
      paymentDiscount,
    });
    return result.data;
  }

  async deleteManualPaymentSchedule({
    adminId,
    password,
    idValue,
  }: {
    adminId: string;
    password: string;
    idValue: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "deleteManualPaymentSchedule",
      adminId,
      password,
      idValue,
    });
    return result.data;
  }

  async deleteCharge({
    adminId,
    password,
    ref,
  }: {
    adminId: string;
    password: string;
    ref: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "deleteCharge",
      adminId,
      password,
      ref,
    });
    return result.data;
  }

  async confirmCharges({
    adminId,
    password,
    scheduleRef,
  }: {
    adminId: string;
    password: string;
    scheduleRef: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "confirmCharges",
      adminId,
      password,
      scheduleRef,
    });
    return result.data;
  }

  async listLogs({
    startDate,
    endDate,
    paygId,
    size,
  }: {
    startDate: string;
    endDate: string;
    paygId: string;
    size: number;
  }): Promise<GenericResponse<UserLog[]>> {
    const result = await this.http.get(`${this.baseURL}/payg_accounts.php`, {
      params: {
        cmd: "getLogs",
        startDate,
        endDate,
        idValue: paygId,
        size,
      },
    });
    return result.data;
  }

  async listManualPayments({ paygId }: { paygId: EntityID }): Promise<
    GenericResponse<{
      PAYG_ID: string;
      scheduleRef: string;
    }>
  > {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getManualPayments",
      idValue: paygId,
    });
    return result.data;
  }

  async manualPasswordReset({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "manualPasswordReset",
      idValue: email,
      password,
    });
    return result.data;
  }

  async manualEmailVerification({ email }: { email: string }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "manualEmailVerification",
      idValue: email,
    });
    return result.data;
  }

  async manualUserCreation({
    adminId,
    password,
    clientId,
    type,
    operatorUserType,
    companyName,
    personEmail,
    userPassword,
    addressPropertyName,
    addressOne,
    addressTwo,
    addressThree,
    addressTown,
    addressCounty,
    addressPostcode,
    personFirstName,
    personSurname,
    personMobileNumber,
    personTitle,
  }: ManualUserCreationRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "adminSignUp",
      adminId,
      password,
      termsConditions: 1,
      clientId,
      type,
      operatorUserType,
      companyName,
      personEmail,
      userPassword,
      addressPropertyName,
      address1: addressOne,
      address2: addressTwo,
      address3: addressThree,
      addressTown,
      addressCounty,
      addressPostcode,
      personFirstName,
      personSurname,
      personMobileNumber,
      personTitle,
      memorableWord: "",
    });
    return result.data;
  }

  async listEmailsSent({
    email,
    startDate,
    endDate,
  }: {
    email: string;
    startDate: string;
    endDate: string;
  }): Promise<GenericResponse<EmailSent[]>> {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "getEmailSent",
      idValue: email,
      startDate,
      endDate,
    });
    return result.data;
  }

  async convertUserType({
    adminId,
    password,
    paygId,
    role,
  }: {
    adminId: string;
    password: string;
    paygId: string;
    role: string;
  }) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "convertUserType",
      adminId,
      password,
      idValue: paygId,
      targetType: role,
    });
    return result.data;
  }

  async editScheduleManualPayment({
    adminId,
    password,
    paygId,
    scheduleRef,
    paymentStartDate,
    paymentEndDate,
    paymentFrequency,
    vatRate,
    paymentExceptions,
    manualPayments,
    scheduleStatus,
  }: EditScheduleManualPaymentRequest) {
    const result = await this.http.post(`${this.baseURL}/payg_accounts.php`, {
      cmd: "editScheduleManualPayment",
      adminId,
      password,
      idValue: paygId,
      scheduleRef,
      paymentStartDate,
      paymentEndDate,
      paymentFrequency: "month",
      vatRate,
      paymentExceptions,
      manualPayments,
      scheduleStatus,
    });
    return result.data;
  }
}
