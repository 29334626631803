type Props = {
  label: string;
  value: string | number | React.ReactNode;
};

export default function InfoRow({ label, value }: Props) {
  return (
    <div className="flex flex-col sm:flex-row">
      <label className="font-semibold w-full text-primary" htmlFor="">
        {label}
      </label>
      <p className="w-full">{value}</p>
    </div>
  );
}
