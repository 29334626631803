import dayjs from "dayjs";

var localeData = require("dayjs/plugin/localeData");
var customParseFormat = require("dayjs/plugin/customParseFormat");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);
dayjs.extend(customParseFormat);
dayjs.extend(localeData);

const NumberFormatter = (number) => {
  if (typeof number === "string") number = parseFloat(number);
  return Intl.NumberFormat("eb-GB").format(number);
};

const FormatAxisDate = ({ startDate, endDate, timeframe, format }) => {
  let startingDate = dayjs(startDate);
  let endingDate = dayjs(endDate);

  let times = [];
  while (dayjs(startingDate).isSameOrBefore(endingDate)) {
    times.push(format(startingDate));
    startingDate = timeframe(startingDate);
  }
  return times;
};

const FormatAxis = (start, end, type) => {
  if (type === "hourly")
    return FormatAxisDate({
      startDate: start,
      endDate: end,
      timeframe: (date) => date.add(1, "hour"),
      format: (date) => date.format("DD-MM-YY HH:mm"),
    });
  if (type === "daily")
    return FormatAxisDate({
      startDate: start,
      endDate: end,
      timeframe: (date) => date.add(1, "day"),
      format: (date) => date.format("DD-MM-YY"),
    });
  if (type === "weekly")
    return FormatAxisDate({
      startDate: start,
      endDate: end,
      timeframe: (date) => date.add(1, "week"),
      format: (date) => date.format("DD-MM-YY"),
    });
  if (type === "monthly")
    return FormatAxisDate({
      startDate: start,
      endDate: end,
      timeframe: (date) => date.add(1, "month"),
      format: (date) => date.format("MMMM-YYYY"),
    });
};

const FormatMonthlyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let monthlyData = new Map();
  FormatAxis(from, to, granularity).forEach((month) => {
    monthlyData.set(dayjs(month, "MMMM-YYYY").format("YYYY-M"), 0);
  });

  rawData?.forEach((val) => {
    if (monthlyData.has(dayjs(extractDate(val)).format("YYYY-M"))) {
      monthlyData.set(
        dayjs(extractDate(val)).format("YYYY-M"),
        returnDataType(val)
      );
    }
  });
  return [...monthlyData.values()];
};

const FormatWeeklyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let weeklyData = new Map();
  FormatAxis(from, to, granularity).forEach((week) => {
    weeklyData.set(dayjs(week, "DD-MM-YY").format("DD-MM-YY"), 0);
  });

  rawData?.forEach((val) => {
    if (weeklyData.has(dayjs(extractDate(val)).format("DD-MM-YY"))) {
      weeklyData.set(
        dayjs(extractDate(val)).format("DD-MM-YY"),
        returnDataType(val)
      );
    }
  });
  return [...weeklyData.values()];
};

const FormatDailyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let dailyData = new Map();
  FormatAxis(from, to, granularity).forEach((day) => {
    dailyData.set(dayjs(day, "DD-MM-YY").format("YYYY-MM-DD"), 0);
  });

  rawData.forEach((val) => {
    if (dailyData.has(dayjs(extractDate(val)).format("YYYY-MM-DD"))) {
      dailyData.set(
        dayjs(extractDate(val)).format("YYYY-MM-DD"),
        returnDataType(val)
      );
    }
  });

  return [...dailyData.values()];
};

const FormatHourlyData = ({
  rawData,
  returnDataType,
  extractDate,
  from,
  to,
  granularity,
}) => {
  let dailyData = new Map();
  FormatAxis(from, to, granularity).forEach((day) => {
    dailyData.set(dayjs(day, "DD-MM-YY HH:mm").format("YYYY-MM-DD HH:mm"), 0);
  });

  rawData.forEach((val) => {
    if (dailyData.has(dayjs(extractDate(val)).format("YYYY-MM-DD HH:mm"))) {
      dailyData.set(
        dayjs(extractDate(val)).format("YYYY-MM-DD HH:mm"),
        returnDataType(val)
      );
    }
  });

  return [...dailyData.values()];
};

const FormatData = ({
  rawData,
  returnDataType,
  extractDate,
  granularity,
  from,
  to,
}) => {
  if (granularity === "hourly") {
    return FormatHourlyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
  }
  if (granularity === "daily")
    return FormatDailyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
  if (granularity === "weekly")
    return FormatWeeklyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
  if (granularity === "monthly")
    return FormatMonthlyData({
      rawData,
      returnDataType,
      extractDate,
      from,
      to,
      granularity,
    });
};

const getDateOfWeek = (w, y) => {
  let d = 1 + (w - 1) * 7;
  return new Date(y, 0, d);
};

const FormatGranularity = (granularity) => {
  if (granularity === "hourly") return "Hour";
  if (granularity === "daily") return "Day";
  if (granularity === "weekly") return "Week";
  if (granularity === "monthly") return "Month";
};

const FormatClientID = (clientId) => {
  if (clientId === "all") return "";
  if (clientId === "dev") return "Dev";
  if (clientId === "main") return "Main";
};

export {
  NumberFormatter,
  FormatMonthlyData,
  getDateOfWeek,
  FormatAxis,
  FormatGranularity,
  FormatData,
  FormatClientID,
};
