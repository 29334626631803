import { Icon } from "@iconify/react";
import { Card } from "@mui/material";
import UpdateBillingInfoModal from "components/Modals/Update/UpdateBillingInfoModal";
import React, { useEffect } from "react";
import AddPaymentDetailsModal from "./AddPaymentDetailsModal";
import { authState } from "store/authSlice";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import Button from "components/Button/Button";
import ApiClient from "api/v2/ApiClient";
import { useQuery } from "react-query";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useLocation } from "react-router-dom";

export const checkUsabledata = (data: any) => {
  return data.some((item: any) => !!item);
};

type Props = {
  user: {
    personTitle: string;
    personFirstName: string;
    personSurname: string;
    personEmail: string;
    personMobileNumber: string;
    address1: string;
    address2: string;
    address3: string;
    addressTown: string;
    addressCounty: string;
    addressPostcode: string;
    companyName: string;
    addressPropertyName: string;
    vatReg: string;
    invoiceEmail: string;
    email: string;
  };
};

export default function BillingInformation({ user }: Props) {
  const location = useLocation();
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user: authUser } = useSelector(authState) as UserAuthSlice;
  const [openAddPaymentDetailsModal, setOpenAddPaymentDetailsModal] =
    React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);

  const { data: companyDetails } = useQuery(["company-details"], {
    queryFn: () =>
      ApiClient.company.getCompanyDetails({
        paygId: authUser?.payId ?? "",
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result;
    },
    refetchOnMount: true,
  });

  useEffect(() => {
    if (location.search.includes("add-payment-details")) {
      setOpenAddPaymentDetailsModal(true);
    }
  }, [location.search]);

  return (
    <>
      <Card className="p-4">
        <h1 className="font-bold text-secondary pb-8 text-lg">
          Billing Information
        </h1>
        {checkUsabledata([
          user?.email,
          user?.address1,
          user?.address2,
          user?.address3,
          user?.addressTown,
          user?.addressCounty,
          user?.addressPostcode,
        ]) ? (
          <div className="bg-background-light flex flex-col gap-5 rounded-lg relative text-primary px-4 pt-6 pb-4 h-full">
            <h3 className="text-lg font-bold">Billing Contact</h3>
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 pb-5">
              <p>First Name</p>
              <p className="font-bold">{user.personFirstName}</p>
              <p>Surname</p>
              <p className="font-bold">{user.personSurname}</p>
              <p>Email address</p>
              <p className="font-bold">{user.personEmail}</p>
              <p>Invoice Email</p>
              <p className="font-bold">{user.invoiceEmail}</p>
              <p>Address 1</p>
              <p className="font-bold">{user.address1}</p>
              <p>Address 2</p>
              <p className="font-bold">{user.address2}</p>
              <p>Address 3</p>
              <p className="font-bold">{user.address3}</p>
              <p>Town</p>
              <p className="font-bold">{user.addressTown}</p>
              <p>Post Code</p>
              <p className="font-bold">{user.addressPostcode}</p>
            </div>

            {(user.companyName || user.addressPropertyName) && (
              <>
                <h3 className="text-lg font-bold">Company Information</h3>
                <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 pb-5">
                  <p>Company Name</p>
                  <p className="font-bold">{user.companyName}</p>
                  <p>Property Address</p>
                  <p className="font-bold">{user.addressPropertyName}</p>
                  <p>Vat Reg</p>
                  <p className="font-bold">{user.vatReg}</p>
                </div>
              </>
            )}

            <button
              onClick={() => setOpenUpdateModal(true)}
              className="absolute top-3 right-3 flex items-center gap-x-1"
            >
              <Icon icon="material-symbols:edit" />
              <span className="font-bold">EDIT</span>
            </button>
          </div>
        ) : companyDetails && companyDetails?.RegistrationStatus !== 3 ? (
          <div className="w-full flex items-center h-full justify-center">
            <Button onClick={() => setOpenAddPaymentDetailsModal(true)}>
              Add payment details
            </Button>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center">
            <h2 className="text-primary font-semibold text-xl">
              No Purchases Made
            </h2>
          </div>
        )}
      </Card>
      <UpdateBillingInfoModal
        open={openUpdateModal}
        handleClose={() => setOpenUpdateModal(false)}
        user={{
          personTitle: user?.personTitle ?? "",
          personFirstName: user?.personFirstName ?? "",
          personSurname: user?.personSurname ?? "",
          personEmail: user?.personEmail ?? "",
          personMobileNumber: user?.personMobileNumber ?? "",
          address1: user?.address1 ?? "",
          address2: user?.address2 ?? "",
          address3: user?.address3 ?? "",
          addressTown: user?.addressTown ?? "",
          addressCounty: user?.addressCounty ?? "",
          addressPostcode: user?.addressPostcode ?? "",
          companyName: user?.companyName ?? "",
          addressPropertyName: user?.addressPropertyName ?? "",
          invoiceEmail: user?.invoiceEmail ?? "",
          vatReg: user?.vatReg ?? "",
        }}
      />
      <AddPaymentDetailsModal
        open={openAddPaymentDetailsModal}
        handleClose={() => setOpenAddPaymentDetailsModal(false)}
      />
    </>
  );
}
