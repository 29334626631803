import ApiClient from "api/v2/ApiClient";
import Button from "components/Button/Button";
import BaseCard from "components/Cards/BaseCard";
import { useMutation, useQueryClient } from "react-query";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useAtom } from "jotai";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BadErrorResponse } from "types/NetworkTypes";

type FormData = {
  password: string;
};

const CreateManualPaymentCard = () => {
  const qc = useQueryClient();
  const { register, handleSubmit } = useForm<FormData>();
  const { user } = useSelector(authState) as UserAuthSlice;
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { mutate } = useMutation(
    (data: FormData) =>
      ApiClient.admin.createManualPaymentSchedule({
        adminId: user.payId,
        password: data.password,
        paygId: selectedUser?.PAYG_ID,
      }),
    {
      onSuccess: (data) => {
        toast.success("Manual payments schedule created successfully");
        qc.invalidateQueries(["manual-payments", selectedUser], {
          exact: true,
        });
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  const onSubmit = async (data: FormData) => {
    mutate({
      password: data.password,
    });
  };

  return (
    <BaseCard title="Manual Payments Schedule" className="flex gap-10 flex-col">
      <h2 className="text-center text-secondary font-semibold">
        No manual payments schedule found
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center gap-5"
      >
        <TextField
          label="Password"
          required
          className="w-full max-w-[400px]"
          type="password"
          {...register("password")}
        />
        <Button type="submit" className="flex w-fit mx-auto items-center">
          Create manual payments schedule
        </Button>
      </form>
    </BaseCard>
  );
};

export default CreateManualPaymentCard;
