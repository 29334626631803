const INDEXED_MONTHS = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
} as const;

const COLORS = [
  "chartColorA",
  "chartColorB",
  "chartColorC",
  "chartColorD",
  "chartColorE",
  "chartColorF",
  "chartColorG",
  "chartColorH",
  "chartColorI",
  "chartColorJ",
  "chartColorK",
  "chartColorL",
  "chartColorM",
  "chartColorN",
  "chartColorO",
  "chartColorP",
  "chartColorQ",
  "chartColorR",
  "chartColorS",
  "chartColorT",
] as const;

const PRODUCTORDERING = [
  "PostTag Prime",
  "Lookup and Locate",
  "Address Finder",
  "Reverse Geocode",
  "Partial Address Search",
  "Locations",
  "Autocorrect",
  "Posttag Index Lookup",
  "SET Overrides",
  "GET Overrides",
  "Static Images",
  "Postcode Center (GTP108)",
  "Postcode Center (GTP125)",
] as const;

const ROLES = {
  ADMIN: "Admin",
  USER: "User",
  OPERATOR: "Operator",
  SUBSCRIPTION_USER: "SubscriptionUser",
  PLUGIN_USER: "PluginUser",
  CREDIT_USER: "CreditUser",
} as const;

const PAGES = {
  Overview: {
    name: "Overview",
    path: "/overview",
  },
  ApiDetails: {
    name: "ApiDetails",
    path: "/api-details",
  },
  Account: {
    name: "Account",
    path: "/account",
  },
  AdminFunctions: {
    name: "AdminFunctions",
    path: "/admin-functions",
  },
} as const;

const TRANSACTION_STATUS = {
  APPROVED: "Approved",
  PENDING: "Pending",
  REJECTED: "Rejected",
};

export {
  ROLES,
  INDEXED_MONTHS,
  COLORS,
  PRODUCTORDERING,
  PAGES,
  TRANSACTION_STATUS,
};
