import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import { UserLog } from "api/v2/Admin/types";
import ApiClient from "api/v2/ApiClient";
import Button from "components/Button/Button";
import BaseCard from "components/Cards/BaseCard";
import DatePicker from "components/DatePicker/DatePicker";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { SelectedCompanyAtomType } from "types/AtomTypes";

const LogsCard = () => {
  const [logs, setLogs] = useState<UserLog[]>([]);
  const { register, watch, setValue, getValues } = useForm();
  const [selectedUser] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );

  const { data, refetch } = useQuery({
    queryKey: ["logs", selectedUser?.PAYG_ID],
    queryFn: () =>
      ApiClient.admin.listLogs({
        startDate:
          watch("startDate") ??
          dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        endDate: watch("endDate") ?? dayjs().format("YYYY-MM-DD"),
        paygId: selectedUser?.PAYG_ID ?? "",
        size: getValues("size") ?? 100,
      }),
    select: (data) => {
      if (data.status !== 200) return [];
      return data.result.sort((a: UserLog, b: UserLog) => {
        return dayjs(b.DateStamp).unix() - dayjs(a.DateStamp).unix();
      });
    },
    enabled: !!selectedUser,
  });

  useEffect(() => {
    setValue("startDate", dayjs().subtract(1, "month").format("YYYY-MM-DD"));
    setValue("endDate", dayjs().format("YYYY-MM-DD"));
    setValue("size", 100);
  }, []);

  useEffect(() => {
    if (!data) return;
    setLogs(data);
  }, [data]);

  return (
    <BaseCard title="User Logs" className="max-h-[500px] flex flex-col gap-5 ">
      <div className="lg:w-3/4 flex items-center gap-5">
        <TextField
          label="Size"
          className="w-full self-end"
          {...register("size")}
        />
        <DatePicker
          outsideLabel="Start Date"
          selected={dayjs(watch("startDate")).toDate()}
          innerText={watch("startDate")}
          mode="single"
          onSelect={(date) => {
            setValue("startDate", dayjs(date).format("YYYY-MM-DD"));
          }}
        ></DatePicker>
        <DatePicker
          outsideLabel="End Date"
          selected={dayjs(watch("endDate")).toDate()}
          innerText={watch("endDate")}
          mode="single"
          onSelect={(date) => {
            setValue("endDate", dayjs(date).format("YYYY-MM-DD"));
          }}
        ></DatePicker>
        <Button
          onClick={() => {
            refetch();
          }}
          className="self-end"
        >
          Search
        </Button>
      </div>
      <div className="overflow-y-auto">
        {logs && logs.length > 0 ? (
          logs?.map((log, idx) => {
            return <LogRow userLog={log} key={idx} />;
          })
        ) : (
          <div>
            <h2 className="text-center font-semibold text-secondary">
              No logs found for the selected date range.
            </h2>
          </div>
        )}
      </div>
    </BaseCard>
  );
};

const LogRow = ({ userLog }: { userLog: UserLog }) => {
  return (
    <Accordion className="!shadow-none !border-primary !border-b !w-full">
      <AccordionSummary className="!w-full !flex !justify-between">
        <span className="font-bold w-full">{userLog.Result}</span>
        <span className="w-full text-right">
          {dayjs(userLog.DateStamp).format("YYYY-MM-DD HH:mm:ss")}
        </span>
      </AccordionSummary>
      <AccordionDetails>
        <LogRowInfo label="API" value={userLog.API} />
        <LogRowInfo label="Query" value={userLog.TheQuery} />
        <LogRowInfo label="Found Item" value={userLog.FoundItem} />
        <LogRowInfo label="Accuracy" value={userLog.Accuracy} />
        <LogRowInfo label="Source" value={userLog.Source} />
      </AccordionDetails>
    </Accordion>
  );
};

const LogRowInfo = ({
  label,
  value,
}: {
  label: string;
  value: string | number | null;
}) => {
  return (
    <div className="flex gap-5">
      <label className="font-semibold" htmlFor="">
        {label}
      </label>
      <span className="text-secondary">{value || "N/A"}</span>
    </div>
  );
};

export default LogsCard;
