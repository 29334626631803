export const transactionTableColumns = [
  {
    Header: "Reference",
    accessor: "ref",
  },
  {
    Header: "Purchase Type",
    accessor: "purchaseType",
  },
  {
    Header: "Credits",
    accessor: "credits",
  },
  {
    Header: "Total Exc VAT",
    accessor: "totalExcVat",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Status",
    accessor: "transactionStatus",
  },
  {
    Header: "Date",
    accessor: "TransactionTime",
  },
  {
    Header: "Invoice",
    accessor: "invoice",
  },
];

export default function useTransactionFormatter({}) {
  return <div>useTransactionFormatter</div>;
}
