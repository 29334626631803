import { HTMLAttributes, forwardRef } from "react";
import PropTypes from "prop-types";
import MDInputRoot from "components/ui/MDInput/MDInputRoot";
import { Icon } from "@iconify/react";

const MDInput = forwardRef(
  ({ error, success, disabled, icon, onIconClick, ...rest }, ref) => {
    return (
      <div className="flex flex-col">
        <MDInputRoot
          {...rest}
          ref={ref}
          ownerState={{ error, success, disabled }}
        />
        {icon && (
          <button type="button" onClick={(e) => onIconClick(e)}>
            <Icon
              icon={icon}
              className="absolute right-5 top-2 text-2xl cursor-pointer"
            />
          </button>
        )}
        <span className="text-red-500 text-xs">{error}</span>
      </div>
    );
  }
);

MDInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

MDInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDInput;
