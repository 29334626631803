import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { EmailSent } from "api/v2/Admin/types";
import ApiClient from "api/v2/ApiClient";
import BaseCard from "components/Cards/BaseCard";
import DatePicker from "components/DatePicker/DatePicker";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import EmailSentTable from "./EmailSentTable";
import { emailSentColumns } from "./useEmailSentTableDef";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { useAtom } from "jotai";

const EmailsSent = () => {
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { setValue, watch } = useForm();
  const { data: emailsSentData } = useQuery({
    queryKey: ["emails-sent", watch("startDate"), watch("endDate")],
    queryFn: () =>
      ApiClient.admin.listEmailsSent({
        email: selectedUser?.Email || "",
        startDate:
          watch("startDate") ||
          dayjs().subtract(1, "month").format("YYYY-MM-DD"),
        endDate: watch("endDate") || dayjs().format("YYYY-MM-DD"),
      }),
    select: (data) => {
      if (data.status !== 200) return [];
      return data.result.map((d) => {
        return {
          to: d?.Message?.Headers.To.flat().join(", "),
          event: d.Event,
          loggedAt: d.LoggedAt,
        };
      });
    },
  });

  useEffect(() => {
    setValue("startDate", dayjs().subtract(1, "month").format("YYYY-MM-DD"));
    setValue("endDate", dayjs().format("YYYY-MM-DD"));
  }, []);

  return (
    <BaseCard
      title="Emails Sent"
      className="max-h-[500px] flex flex-col gap-5 "
    >
      <div className="lg:w-1/2 w-full flex items-center gap-5">
        <DatePicker
          outsideLabel="Start Date"
          defaultMonth={dayjs(watch("startDate")).toDate()}
          selected={dayjs(watch("startDate")).toDate()}
          innerText={watch("startDate")}
          mode="single"
          onSelect={(date) => {
            setValue("startDate", dayjs(date).format("YYYY-MM-DD"));
          }}
        ></DatePicker>
        <DatePicker
          outsideLabel="End Date"
          selected={dayjs(watch("endDate")).toDate()}
          innerText={watch("endDate")}
          mode="single"
          onSelect={(date) => {
            setValue("endDate", dayjs(date).format("YYYY-MM-DD"));
          }}
        ></DatePicker>
      </div>
      <div className="overflow-y-auto">
        {emailsSentData && emailsSentData.length > 0 ? (
          <EmailSentTable data={emailsSentData} columns={emailSentColumns} />
        ) : (
          <div>
            <h2 className="text-center font-semibold text-secondary">
              No emails found for the selected date range.
            </h2>
          </div>
        )}
      </div>
    </BaseCard>
  );
};

export default EmailsSent;
