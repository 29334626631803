import Button from "components/Button/Button";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  open: boolean;
  handleClose: () => void;
  notice: string;
};

export default function NoticePopup({ open, handleClose, notice }: Props) {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      <div className="fixed !z-50 inset-0 min-h-screen w-full flex items-center justify-center">
        <div className="bg-white text-center flex gap-5 flex-col lg:max-w-[50vw] max-w-full mx-5 opacity-100 rounded-lg p-10">
          <h1 className="font-bold text-3xl">
            Required action to activate your account
          </h1>
          <p className="text-lg">{notice}</p>
          <div className="flex gap-5">
            <Button variant="danger" className="w-full" onClick={handleClose}>
              Exit
            </Button>
            <Button
              className="w-full"
              onClick={() =>
                navigate(`/account${location.search}&add-payment-details`)
              }
            >
              Add payment method
            </Button>
          </div>
        </div>
      </div>
      <div className="fixed z-10 inset-0 min-h-screen w-full bg-black opacity-70 flex items-center justify-center"></div>
    </>
  );
}
