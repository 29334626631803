import AuthLayout from "layouts/AuthLayout";
import Card from "@mui/material/Card";
import bgImage from "assets/images/doors.png";
import React from "react";
import { useMutation } from "react-query";
import Header from "../components/Header";
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import MDInput from "components/ui/MDInput";
import MDButton from "components/ui/MDButton";
import { registrationInfoAtom } from "../Register/Register";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import ApiClient from "api/v2/ApiClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { ResetPasswordConfirmationSchema } from "./schemas";
import Footer from "../components/Footer";

export default function ResetPasswordConfirmation() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ResetPasswordConfirmationSchema),
    defaultValues: {
      email: registrationInfo,
    },
  });
  const { mutate: passwordResetMutation } = useMutation(
    (data) => ApiClient.auth.passwordReset(data),
    {
      onSuccess: () => {
        toast.success("Successfully reset password");
        navigate("/login");
      },
      onError: (data) => {
        toast.error(data.response.data.message);
      },
    }
  );

  const onFormSubmit = async (data) => {
    passwordResetMutation({
      email: searchParams.get("email") ?? registrationInfo ?? data.email,
      code: searchParams.get("token"),
      password: data.password,
    });
  };

  return (
    <AuthLayout image={bgImage}>
      <Card className="text-black max-w-[500px] w-full">
        <form action="" onSubmit={handleSubmit(onFormSubmit)}>
          <Header title={"Reset Password"} />
          <MDBox pt={4} pb={3} px={3}>
            <h1 className="text-center">
              <MDTypography variant="h4" fontWeight="medium" mt={1}>
                Enter new password
              </MDTypography>
            </h1>
            <MDBox mb={2} mt={5} className="flex flex-col gap-3">
              <MDInput
                {...register("password")}
                type="password"
                label="New Password"
                error={errors?.password?.message}
                fullWidth
              />
              <MDInput
                {...register("confirmPassword")}
                type="password"
                label="Confirm New Password"
                error={errors?.confirmPassword?.message}
                fullWidth
              />
            </MDBox>
            <MDBox mt={1} mb={1} className="flex flex-col gap-3">
              <MDButton
                variant="gradient"
                color="primary"
                type="submit"
                fullWidth
              >
                Reset Password
              </MDButton>
            </MDBox>
            <Footer />
          </MDBox>
        </form>
      </Card>
    </AuthLayout>
  );
}
