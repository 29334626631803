import { useMutation, useQueryClient } from "react-query";
import { useFieldArray, useForm } from "react-hook-form";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import {
  PaymentScheduleEditableFields,
  ScheduleDetails,
  UpdatePaymentScheduleRequest,
} from "api/v2/Payments/types";
import { BadErrorResponse } from "types/NetworkTypes";
import ApiClient from "api/v2/ApiClient";
import { toast } from "react-toastify";
import { InputLabel, TextField } from "@mui/material";
import Button from "components/Button/Button";
import CurrencyInput from "react-currency-input-field";
import { ScheduledPayment } from "api/v2/Payments/types";
import { useEffect } from "react";
import Calendar from "components/Calendar/Calendar";
import dayjs from "dayjs";
import { authState } from "store/authSlice";
import { useSelector } from "react-redux";
import { UserAuthSlice } from "types/ReduxTypes";
import { convertFromPennies, convertToPennies } from "utils/currency";
import { Icon } from "@iconify/react/dist/iconify.js";

type Props = {
  onFormSubmit: () => void;
  subscription: {
    base: ScheduledPayment;
    lookupDetails: ScheduleDetails | null;
  };
};

type FormData = {
  threshold: number;
  credit: string;
  maximumRuns: string;
  startDate: Date;
  endDate: Date;
  paymentAmount: string;
  manualPayments: {
    date: string;
    amount: number;
  }[];
  paymentExceptions: string[];
  password: string;
};

export default function ScheduleEditForm({
  onFormSubmit,
  subscription,
}: Props) {
  const { user } = useSelector(authState) as UserAuthSlice;
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const qc = useQueryClient();
  const { register, handleSubmit, setValue, watch, getValues, control, reset } =
    useForm();
  const { mutate } = useMutation(
    (data: UpdatePaymentScheduleRequest) =>
      ApiClient.payments.updatePaymentSchedule(data),
    {
      onSuccess: (data) => {
        qc.invalidateQueries(["scheduled-payments", company?.PAYG_ID], {
          exact: true,
        });
        qc.invalidateQueries(
          ["past-payments", company?.PAYG_ID, subscription?.base.scheduleRef],
          {
            exact: true,
          }
        );
        qc.invalidateQueries(
          ["lookup-payments", company?.PAYG_ID, subscription?.base.scheduleRef],
          {
            exact: true,
          }
        );
        toast.success("Schedule updated successfully");
        onFormSubmit();
        reset();
      },
      onError: (data: BadErrorResponse) => {
        toast.error(data.response.data.message);
      },
    }
  );

  const mutateValue = (
    key: typeof PaymentScheduleEditableFields[number],
    value: any
  ) => {
    return mutate({
      paygId: company?.PAYG_ID ?? "",
      scheduleRef: subscription.base.scheduleRef,
      key: key,
      adminId: user?.payId ?? "",
      value: value,
      password: getValues("password"),
    });
  };

  const onUpdateFormSubmit = async (data: FormData) => {
    const promises = [];
    if (data.credit !== subscription.base.credit) {
      promises.push(mutateValue("credit", data.credit));
    }
    if (data.threshold !== subscription.lookupDetails?.threshold) {
      promises.push(mutateValue("threshold", data.threshold));
    }
    if (data.maximumRuns != subscription.lookupDetails?.maximumRuns) {
      promises.push(mutateValue("maximumRuns", data.maximumRuns));
    }
    if (
      !dayjs(data.startDate).isSame(
        dayjs(subscription.lookupDetails?.startDate)
      )
    ) {
      promises.push(
        mutateValue("startDate", dayjs(data.startDate).format("YYYY-MM-DD"))
      );
    }
    if (
      !dayjs(data.endDate).isSame(dayjs(subscription.lookupDetails?.endDate))
    ) {
      promises.push(
        mutateValue("endDate", dayjs(data.endDate).format("YYYY-MM-DD"))
      );
    }
    if (
      convertToPennies(data.paymentAmount) !==
      parseInt(subscription.base.paymentAmount)
    ) {
      promises.push(
        mutateValue("paymentAmount", convertToPennies(data.paymentAmount))
      );
    }

    if (data.manualPayments.length > 0) {
      const manualPayments = data.manualPayments
        .map((d) => {
          return `${dayjs(d.date).format("YYYY-MM-DD")}:${convertToPennies(
            d.amount
          )}`;
        })
        .join(",");
      promises.push(mutateValue("manualPayments", manualPayments));
    }

    if (data.paymentExceptions.length > 0) {
      const paymentExceptions = data.paymentExceptions
        .map((d) => dayjs(d).format("YYYY-MM-DD"))
        .join(",");
      promises.push(mutateValue("paymentExceptions", paymentExceptions));
    }
    Promise.all(promises);
  };

  const manualPayments = useFieldArray({
    control: control,
    name: "manualPayments",
  });

  const paymentExceptions = useFieldArray({
    control: control,
    name: "paymentExceptions",
  });

  useEffect(() => {
    setValue("threshold", subscription.lookupDetails?.threshold ?? null);
    setValue("credit", subscription.base.credit ?? 0);
    setValue("maximumRuns", subscription.lookupDetails?.maximumRuns ?? null);
    setValue(
      "startDate",
      subscription.lookupDetails?.startDate ?? dayjs().toDate()
    );
    setValue(
      "endDate",
      subscription.lookupDetails?.endDate ?? dayjs().toDate()
    );
    setValue("paymentAmount", subscription.base.paymentAmount / 100);
    setValue(
      "manualPayments",
      subscription.lookupDetails?.manualPayments.map((d) => {
        return {
          date: d.date,
          amount: convertFromPennies(d.amount),
        };
      }) || []
    );
    setValue(
      "paymentExceptions",
      subscription.lookupDetails?.paymentExceptions.map((d) =>
        dayjs(d).toDate()
      ) || []
    );
  }, [subscription]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <h2 className="text-primary text-xl font-semibold">
          Edit subscription details
        </h2>
        <form
          className="flex flex-col gap-5"
          onSubmit={handleSubmit(onUpdateFormSubmit)}
        >
          <div className="w-full grid grid-cols-1 gap-10 gap-x-5 lg:grid-cols-2">
            <div className="flex gap-1 flex-col">
              <InputLabel>Credit Amount</InputLabel>
              <CurrencyInput
                decimalsLimit={0}
                allowDecimals={false}
                disableGroupSeparators
                value={watch("credit") || 0}
                className="py-3 px-2 border border-secondary border-opacity-30 rounded-lg text-sm"
                {...register("credit")}
              />
            </div>

            <div className="flex gap-1 flex-col">
              <InputLabel>Threshold</InputLabel>
              <CurrencyInput
                decimalsLimit={0}
                allowDecimals={false}
                disableGroupSeparators
                value={watch("threshold") || 0}
                className="py-3 px-2 border border-secondary border-opacity-30 rounded-lg text-sm"
                {...register("threshold")}
              />
            </div>

            <div className="flex gap-1 flex-col">
              <InputLabel>Payment Amount</InputLabel>
              <CurrencyInput
                placeholder="Payment amount"
                className="py-3 px-2 border border-secondary border-opacity-30 rounded-lg text-sm"
                decimalsLimit={2}
                value={watch("paymentAmount") || 0}
                prefix="£"
                onValueChange={(value, name, values) =>
                  setValue("paymentAmount", value)
                }
              />
            </div>

            <div className="flex gap-1 flex-col">
              <InputLabel>Maximum Runs</InputLabel>
              <CurrencyInput
                decimalsLimit={0}
                allowDecimals={false}
                disableGroupSeparators
                value={watch("maximumRuns") || 0}
                className="py-3 px-2 border border-secondary border-opacity-30 rounded-lg text-sm"
                {...register("maximumRuns")}
              />
            </div>

            {dayjs().isBefore(dayjs(subscription.lookupDetails?.startDate)) ? (
              <div className="flex gap-1 flex-col">
                <InputLabel>Start Date</InputLabel>
                <Calendar
                  disabled={{
                    before: dayjs(
                      subscription.lookupDetails?.startDate
                    ).toDate(),
                  }}
                  mode="single"
                  onSelect={(date) => setValue("startDate", date)}
                  selected={dayjs(watch("startDate")).toDate() || new Date()}
                />
              </div>
            ) : null}

            <div className="flex gap-1 flex-col">
              <InputLabel>End Date</InputLabel>
              <Calendar
                disabled={{
                  before: dayjs(getValues("startDate")).toDate(),
                }}
                mode="single"
                onSelect={(date) => setValue("endDate", date)}
                selected={dayjs(watch("endDate")).toDate() || new Date()}
              />
            </div>

            <div className="flex gap-1 flex-col">
              <InputLabel>Payment Exceptions</InputLabel>
              <Calendar
                mode="multiple"
                disabled={{
                  before: dayjs().toDate(),
                }}
                onSelect={(date) => setValue("paymentExceptions", date)}
                selected={watch("paymentExceptions")}
              />
            </div>

            <div className="flex gap-1  flex-col">
              <InputLabel>Manual Payments</InputLabel>
              <Calendar
                disabled={{
                  before: dayjs().toDate(),
                }}
                mode="multiple"
                onSelect={(date) => {
                  if (
                    manualPayments.fields.find(
                      (field) =>
                        field.date ===
                        dayjs(date[date?.length - 1]).format("YYYY-MM-DD")
                    )
                  )
                    return;
                  manualPayments.append({
                    date: dayjs(date[date?.length - 1]).format("YYYY-MM-DD"),
                    amount: 0,
                  });
                }}
                selected={manualPayments.fields.map((field) =>
                  dayjs(field.date).toDate()
                )}
              />
            </div>

            <div className="flex flex-col overflow-y-auto max-h-[370px] gap-5">
              {manualPayments.fields.map((field, index) => {
                return (
                  <ManualPaymentInput
                    key={field.id}
                    date={field.date}
                    defaultValue={field.amount}
                    onDelete={() => manualPayments.remove(index)}
                    onPriceChange={(amount) => {
                      setValue(`manualPayments[${index}].amount`, amount);
                    }}
                  />
                );
              })}
            </div>

            <div className="flex gap-1 col-span-2 flex-col">
              <InputLabel>Current Password</InputLabel>
              <TextField {...register("password")} type="password" />
            </div>
          </div>

          <Button type="submit" className="ml-auto w-fit">
            Update
          </Button>
        </form>
      </div>
    </>
  );
}

const ManualPaymentInput = ({
  date,
  onPriceChange,
  onDelete,
  defaultValue = 0,
}: {
  date: Date;
  onPriceChange: (price: number) => void;
  onDelete: () => void;
  defaultValue?: number;
}) => {
  const { setValue, register } = useForm();

  return (
    <div className="w-full flex items-center justify-between">
      <div className="flex gap-1 flex-col">
        <p>{dayjs(date).format("DD/MM/YYYY")}</p>
        <CurrencyInput
          placeholder="Payment amount"
          className="py-3 px-2 border border-secondary border-opacity-30 rounded-lg text-sm"
          decimalsLimit={2}
          defaultValue={defaultValue}
          {...register("amount")}
          prefix="£"
          onValueChange={(value, name, values) => onPriceChange(value)}
        />
      </div>

      <button onClick={onDelete}>
        <Icon icon="mdi:times" className="text-3xl text-red-500" />
      </button>
    </div>
  );
};
