import ApiClient from "api/v2/ApiClient";
import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useAtom } from "jotai";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { authState } from "store/authSlice";
import { SelectedCompanyAtomType } from "types/AtomTypes";
import { UserAuthSlice } from "types/ReduxTypes";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "components/Button/Button";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  handleClose: () => void;
  data: {
    idValue: string;
    scheduleRef: string;
  };
};

type FormData = {
  password: string;
};

const CreateManualPaymentModal = ({ open, handleClose, data }: Props) => {
  const qc = useQueryClient();
  const [selectedUser, setSelectedUser] =
    useAtom<SelectedCompanyAtomType | null>(selectedCompanyAtom);
  const { user } = useSelector(authState) as UserAuthSlice;
  const { register, handleSubmit } = useForm<FormData>();
  const { mutate } = useMutation({
    mutationKey: ["create-manual-payment"],
    mutationFn: (_data: FormData) =>
      ApiClient.admin.createManualPayment({
        adminId: user.payId ?? "",
        idValue: data.idValue,
        scheduleRef: data.scheduleRef,
        password: _data.password,
      }),
    onSuccess: () => {
      toast.success("New manual payment charge created successfully!");
      qc.invalidateQueries(["charges", selectedUser, data.scheduleRef]);
      handleClose();
    },
    onError: () => {
      toast.error("There was an issue creating a manual payment charge.");
    },
  });

  const onSubmit = async (data: FormData) => {
    mutate(data);
  };

  return (
    <BaseModal
      handleClose={handleClose}
      open={open}
      title="Create new manual payment"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5">
        <div className="flex flex-col w-full gap-5 self-end">
          <TextField
            label="Confirm password"
            variant="outlined"
            type="password"
            {...register("password")}
          />
          <div className="flex w-full gap-5">
            <Button
              type="button"
              className="w-full"
              variant="danger"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button type="submit" variant="primary" className="w-full">
              Create Manual Payment
            </Button>
          </div>
        </div>
      </form>
    </BaseModal>
  );
};

export default CreateManualPaymentModal;
