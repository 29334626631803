import BaseModal from "components/Modals/BaseModal/BaseModal";
import { useSelector } from "react-redux";
import { authState } from "store/authSlice";
import { ROLES } from "utils/constants";
import { ScheduleDetails, ScheduledPayment } from "api/v2/Payments/types";
import ScheduleStatusForm from "./ScheduleStatusForm";
import ScheduleEditForm from "./ScheduleEditForm";

type Props = {
  open: boolean;
  handleClose: () => void;
  subscription: {
    base: ScheduledPayment;
    lookupDetails: ScheduleDetails | null;
  };
};

export default function UpdateScheduledPaymentsModal({
  open,
  handleClose,
  subscription,
}: Props) {
  const { user } = useSelector(authState);

  return (
    <BaseModal
      title={`Update subscription - ${subscription.base.scheduleRef}`}
      open={open}
      handleClose={handleClose}
    >
      <section className="flex flex-col gap-5">
        {user.type === ROLES.ADMIN && (
          <ScheduleEditForm
            subscription={subscription}
            onFormSubmit={handleClose}
          />
        )}
        <ScheduleStatusForm
          onFormSubmit={handleClose}
          subscription={subscription.base}
        />
      </section>
    </BaseModal>
  );
}
