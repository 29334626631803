import { Icon } from "@iconify/react";
import { Card } from "@mui/material";
import random from "randomstring";
import React from "react";
import { toast } from "react-toastify";
import MDInput from "components/ui/MDInput";
import { useForm } from "react-hook-form";
import WarningModal from "./WarningModal";
import { useState } from "react";
import BaseCard from "components/Cards/BaseCard";

export default function KeyGenerator() {
  const [warningModal, setWarningModal] = useState(false);
  const [showKey, setShowKey] = React.useState(false);
  const { register, setValue, getValues, watch } = useForm();

  return (
    <>
      <BaseCard title="Change Operator Key">
        <div className="flex pb-5 lg:flex-row flex-col items-start justify-between gap-5 lg:gap-0 lg:items-center">
          <p className="text-secondary text-xl lg:text-base">
            Create / Generate New Operator Key{" "}
          </p>
          <div className="flex flex-col items-start lg:flex-row lg:justify-between gap-5 lg:items-center">
            <button
              onClick={() => {
                setValue("key", random.generate(10));
              }}
              className="lg:self-center lg:mx-auto font-bold px-10 py-3 rounded-lg bg-secondary-light"
            >
              Generate
            </button>
            <MDInput
              type={showKey ? "text" : "password"}
              className=" w-[300px]"
              {...register("key")}
            />
          </div>
          <div className="flex items-center">
            <button onClick={() => setShowKey(!showKey)}>
              <Icon
                icon={showKey ? "mdi:eye-outline" : "mdi:eye-off-outline"}
                width="32px"
                className="mx-2"
              />
            </button>
            <button
              onClick={() => {
                navigator.clipboard.writeText(getValues("key"));
                toast.success("Copied to clipboard");
              }}
            >
              <Icon
                icon="material-symbols:file-copy-outline"
                width="32px"
                className="mx-2"
              />
            </button>
          </div>
        </div>
        {watch("key") && (
          <button
            onClick={() => {
              setWarningModal(true);
            }}
            className="bg-primary text-white rounded-lg py-3 w-fit px-8"
          >
            Save Changes
          </button>
        )}
      </BaseCard>
      <WarningModal
        generatedKey={watch("key")}
        open={warningModal}
        handleClose={() => {
          setWarningModal(false);
        }}
      />
    </>
  );
}
