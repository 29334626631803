import { useState } from "react";
import { useQuery } from "react-query";
import ApiClient from "api/v2/ApiClient";
import { selectedCompanyAtom } from "pages/Dashboard/components/FilterBar/FilterBar";
import { useAtom } from "jotai";
import UpdateCreditsModal from "components/Modals/Update/UpdateCreditsModal";
import PaygCards from "./PaygCards";
import SubscriptionCards from "./SubscriptionCards";
import { SelectedCompanyAtomType } from "types/AtomTypes";

export type PaymentModalType = {
  name: string;
  price: string;
  quantity: number;
  pricePerCredit: string;
};

type UpdateCreditsModalType = {
  quantity: number;
  price: string;
  type: "One" | "Two" | "Three";
  ppc: string;
};

export default function PriceCards() {
  const [selectedScheme, setSelectedScheme] = useState<"Subscription" | "PAYG">(
    "PAYG"
  );

  const [updateCreditsModal, setUpdateCreditsModal] =
    useState<UpdateCreditsModalType | null>(null);
  const [company, setCompany] = useAtom<SelectedCompanyAtomType | null>(
    selectedCompanyAtom
  );
  const { data, isLoading } = useQuery(["prices", company, selectedScheme], {
    queryFn: () =>
      ApiClient.payments.getPrices({
        paygId: company ? company.PAYG_ID : "",
        type: selectedScheme,
      }),
    select: (data) => {
      if (data.status !== 200) return null;
      return data.result;
    },
    enabled: !!company,
  });

  if (!data || isLoading) return null;

  return (
    <div
      id="purchase-credits"
      className="flex flex-col p-5 py-10 shadow-2xl rounded-xl bg-white gap-10"
    >
      <div className="flex justify-center font-semibold">
        <button
          onClick={() => setSelectedScheme("PAYG")}
          className={`${
            selectedScheme === "PAYG" && "bg-primary text-white"
          } border-primary border rounded-tl-lg rounded-bl-lg px-5 py-3`}
        >
          One off payment
        </button>
        <button
          onClick={() => setSelectedScheme("Subscription")}
          className={`${
            selectedScheme === "Subscription" && "bg-primary text-white"
          } border-primary border rounded-tr-lg rounded-br-lg px-5 py-3`}
        >
          Subscription plans
        </button>
      </div>
      {selectedScheme === "PAYG" && data && (
        <PaygCards
          setUpdateCreditsModal={(e) => setUpdateCreditsModal(e)}
          data={{
            Price1: data.Price1,
            Price2: data.Price2,
            Price3: data.Price3,
            Volume1: data.Volume1,
            Volume2: data.Volume2,
            Volume3: data.Volume3,
            pricePerCredit: data.ppc,
          }}
        />
      )}

      {selectedScheme === "Subscription" && data && (
        <SubscriptionCards
          setUpdateCreditsModal={(e) => setUpdateCreditsModal(e)}
          data={{
            Price1: data.Price1,
            Price2: data.Price2,
            Price3: data.Price3,
            Volume1: data.Volume1,
            Volume2: data.Volume2,
            Volume3: data.Volume3,
            pricePerCredit: data.ppc,
          }}
        />
      )}

      {updateCreditsModal && (
        <UpdateCreditsModal
          open={!!updateCreditsModal}
          handleClose={() => setUpdateCreditsModal(null)}
          pricingModel={selectedScheme === "PAYG" ? "PAYG" : "Subscription"}
          credits={{
            quantity: updateCreditsModal?.quantity,
            price: updateCreditsModal?.price,
            type: updateCreditsModal?.type,
            ppc: updateCreditsModal?.ppc,
          }}
        />
      )}
    </div>
  );
}
