import DataTable from "examples/Tables/DataTable";
import { transactionTableColumns } from "./useTransactionFormatter";
type TransactionRow = {
  credits: number;
  TotalWithoutVAT: number;
  TotalVAT: number;
  TotalWithVAT: number;
  transactionStatus: string;
  TransactionTime: string;
  ref: string;
};

type Props = {
  transactionRowsData: TransactionRow[];
  filters: {
    startDate: string;
    endDate: string;
    status: string;
  };
};

export default function TransactionsTable({ transactionRowsData }: Props) {
  return (
    <DataTable
      entriesPerPage={{ defaultValue: 25, entries: [5, 10, 15, 20] }}
      table={{
        columns: transactionTableColumns,
        rows: transactionRowsData ?? [],
      }}
    />
  );
}
