import AuthLayout from "layouts/AuthLayout";
import Card from "@mui/material/Card";
import bgImage from "assets/images/doors.png";
import { useMutation } from "react-query";
import Header from "../components/Header";
import MDBox from "components/ui/MDBox";
import MDTypography from "components/ui/MDTypography";
import MDInput from "components/ui/MDInput";
import MDButton from "components/ui/MDButton";
import { registrationInfoAtom } from "../Register/Register";
import { useAtom } from "jotai";
import { useForm } from "react-hook-form";
import ApiClient from "api/v2/ApiClient";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Footer";

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [registrationInfo, setRegistrationInfo] = useAtom(registrationInfoAtom);
  const { register, getValues } = useForm({
    defaultValues: {
      email: registrationInfo,
    },
  });
  const { mutate: verifyEmailMutation } = useMutation(
    (data) => ApiClient.auth.verifyEmailCode(data),
    {
      onSuccess: () => {
        toast.success("Successfully verified email");
        navigate("/login");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  const { mutate: sendEmailVerificationMutation } = useMutation(
    (data) => ApiClient.auth.sendEmailVerification(data),
    {
      onSuccess: () => {
        toast.success("Successfully resent email verification");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );

  return (
    <AuthLayout image={bgImage}>
      <Card className="text-black max-w-[500px] w-full">
        <Header title={"Verify Email"} />
        <MDBox pt={4} pb={3} px={3}>
          <h1 className="text-center">
            <MDTypography variant="h4" fontWeight="medium" mt={1}>
              Verification Email Sent
            </MDTypography>
          </h1>
          <h2 className="text-center">
            <MDTypography variant="h6" fontWeight="medium" mt={1}>
              Please check your email for a verification code.
            </MDTypography>
          </h2>
          <MDBox mb={2} mt={5} className="flex flex-col gap-3">
            <MDInput
              {...register("email")}
              type="text"
              label="Email"
              fullWidth
            />
            <MDInput
              type="text"
              {...register("verificationCode")}
              label="Verification Code"
              fullWidth
            />
          </MDBox>
          <MDBox mt={1} mb={1} className="flex flex-col gap-3">
            <MDButton
              onClick={() =>
                sendEmailVerificationMutation({ email: getValues("email") })
              }
              variant="gradient"
              color="primary"
              fullWidth
            >
              Resend Code
            </MDButton>
            <MDButton
              onClick={() =>
                verifyEmailMutation({
                  email: getValues("email"),
                  code: getValues("verificationCode"),
                })
              }
              variant="gradient"
              color="primary"
              fullWidth
            >
              Verify
            </MDButton>
          </MDBox>
          <Footer />
        </MDBox>
      </Card>
    </AuthLayout>
  );
}
