import { TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ApiClient from "api/v2/ApiClient";
import { useForm } from "react-hook-form";
import useClickOutside from "hooks/useClickOutside";
import Button from "components/Button/Button";
import { BadErrorResponse } from "types/NetworkTypes";

type Props = {
  onAddressLookup: (address: PostCodeIdxResponse) => void;
  error?: string;
};

type PostCodeIdxResponse = {
  "Address Line 1": string;
  "Address Line 2": string;
  "Address Line 3": string;
  "Organisation/Department": string;
  Postcode: string;
  "Town/City": string;
};

export default function PostcodeLookup({ onAddressLookup }: Props) {
  const postcodeLookupRef = useRef<HTMLDivElement>();
  useClickOutside(postcodeLookupRef, () => setAddresses([]));

  const [addresses, setAddresses] = useState<
    { Idx: string; Address: string }[]
  >([]);
  const { register, getValues } = useForm();

  const { mutate: postcodeLookupMutation } = useMutation(
    (data: string) =>
      ApiClient.auth.addressLookUp({
        input: data,
      }),
    {
      onSuccess: (data) => {
        if (data.status !== 200) {
          toast.warn("No address found for this postcode");
          setAddresses([]);
          return;
        }
        setAddresses(data.result);
      },
      onError: (err: BadErrorResponse) => {
        const { data } = err.response;
        if (data.status === 400) {
          toast.error("No address found for this postcode");
          return;
        }
        toast.error("Invalid postcode");
      },
    }
  );

  const { mutate: addressLookupMutation } = useMutation(
    (data: string) =>
      ApiClient.auth.addressLookUp({
        input: data,
      }),
    {
      onSuccess: (data: any) => {
        const address = data?.data;
        if (!address) {
          toast.error("No address found for this postcode");
          return;
        }
        onAddressLookup(address);
        setAddresses([]);
      },
      onError: (err: BadErrorResponse) => {
        toast.error(err.response.data.message);
      },
    }
  );

  return (
    <div className="col-span-2 relative">
      <div className="flex gap-2">
        <TextField
          className="w-full"
          label="Postcode Lookup"
          {...register("addressLookup")}
        ></TextField>
        <Button
          onClick={() => {
            postcodeLookupMutation(getValues("addressLookup") ?? "");
          }}
        >
          Search
        </Button>
      </div>

      {addresses && addresses.length > 0 && (
        <div
          // @ts-ignore
          ref={postcodeLookupRef}
          className="w-full flex flex-col gap-1 overflow-hidden absolute z-10 top-15 max-h-[200px] overflow-y-auto bg-white shadow-2xl border"
        >
          {addresses.map((address) => {
            return (
              <button
                type="button"
                onClick={() => {
                  addressLookupMutation(address.Idx ?? "");
                }}
                key={address.Idx}
                className="px-5 flex items-start text-left py-2"
              >
                <span>{address["Address"]}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
