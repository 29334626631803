import { Icon } from "@iconify/react/dist/iconify.js";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ManualPaymentCharge } from "api/v2/Admin/types";
import { Authorization } from "components/Authorization";
import InfoRow from "components/InfoRow/InfoRow";
import { ROLES } from "utils/constants";
import { formatCurrency } from "utils/currency";

type Props = {
  title: string;
  data: (ManualPaymentCharge & { isReady?: boolean })[];
  onEdit?: (charge: ManualPaymentCharge) => void;
  onDelete?: (charge: ManualPaymentCharge) => void;
};

const ManualPaymentSection = ({ title, data, onEdit, onDelete }: Props) => {
  if (data.length === 0) return null;
  return (
    <div className="flex flex-col">
      <h2 className="text-xl text-secondary">{title}</h2>
      {data.map((charge, idx) => {
        return (
          <Accordion
            key={idx}
            className="!shadow-none !border-primary !border-b"
          >
            <AccordionSummary>
              <h3 className="font-semibold text-lg flex justify-between w-full">
                {charge.paymentLabel || "N/A"}
                <div className="flex gap-2 text-primary text-3xl items-center">
                  {onEdit && (
                    <button
                      className="text-xl"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onEdit(charge);
                      }}
                    >
                      <Icon className="text-2xl" icon="lucide:edit" />
                    </button>
                  )}
                  {onDelete && (
                    <button
                      className="text-xl"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(charge);
                      }}
                    >
                      <Icon className="text-2xl" icon="mdi:bin" />
                    </button>
                  )}
                </div>
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full grid grid-cols-1 gap-2 ">
                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow
                    label="Price"
                    value={formatCurrency(charge.paymentPrice ?? 0)}
                  />
                </Authorization>

                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow label="Quantity" value={charge.paymentQuantity} />
                </Authorization>

                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow
                    label="Payment Amount"
                    value={formatCurrency(charge.paymentTotal ?? 0)}
                  />
                </Authorization>

                <Authorization allowedRoles={[ROLES.SUBSCRIPTION_USER]}>
                  <InfoRow
                    label="Payment Amount"
                    value={formatCurrency(charge.discountedTotal ?? 0)}
                  />
                </Authorization>

                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow
                    label="Discount"
                    value={formatCurrency(charge.paymentDiscount ?? 0)}
                  />
                </Authorization>

                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow
                    label="Discounted Total"
                    value={formatCurrency(charge.discountedTotal ?? 0)}
                  />
                </Authorization>

                <InfoRow
                  label="VAT amount"
                  value={formatCurrency(charge.vatAmount ?? 0)}
                />
                <Authorization allowedRoles={[ROLES.ADMIN]}>
                  <InfoRow
                    label="VAT rate"
                    value={
                      charge.vatRate ? `${parseFloat(charge.vatRate)}%` : "N/A"
                    }
                  />
                </Authorization>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

export default ManualPaymentSection;
